import { createAction } from '@'
import { $searchRequest, $searchInfo } from '../stores'
import Fetch from './Fetch'
import _ from 'lodash'

export default createAction('PullHotels', async () => {
    const searchRequest = $searchRequest.getState()
    const searchInfo = $searchInfo.getState()

    const { request_id: requestId, data: hotels } = await Fetch({
        url: searchInfo.url,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-key': searchInfo.email,
            'x-access-token': searchInfo.token
        },
        data: searchRequest
    })

    const availableHotels = _(hotels)
        .filter(h => h.goquo_id)
        .groupBy('goquo_id')
        .map(gr => _.minBy(gr, h => _.sumBy(h.rooms, 'price_from')))
        .value()

    return {
        requestId,
        availableHotels
    }
})
