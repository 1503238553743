import React from 'react'
import { Tag } from 'antd'
import { format, parseISO } from 'date-fns'

import Booking from '../../components/booking'

function statusCell(status) {
    switch (status) {
        case 'Confirmed':
        case 'Received':
            return <Tag color="green">{status}</Tag>
        case 'Pending':
            return <Tag color="orange">{status}</Tag>
        case 'Cancelled':
            return <Tag color="red">{status}</Tag>
        case 'Failed':
            return <Tag color="red">{status}</Tag>
        default:
            return <Tag color="gray">{status}</Tag>
    }
}

function amountCell(cell, row) {
    return <span>{row.currency + (row.amount || 0.0).toFixed(2)}</span>
}

function fmtDate(cell) {
    return <span>{format(parseISO(cell), 'yyyy-MM-dd')}</span>
}

const columns = [
    {
        key: 'client_ref',
        dataIndex: 'client_ref',
        title: 'Client Reference',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        render: statusCell,
        className: 'text-center'
    },
    {
        key: 'booking_date',
        dataIndex: 'booking_date',
        title: 'Booking Date'
    },
    {
        key: 'booking_id',
        dataIndex: 'booking_id',
        title: 'Booking Id'
    },
    {
        key: 'confirmation_number',
        dataIndex: 'confirmation_number',
        title: 'Confirmation Number'
    },
    {
        key: 'vehicle_name',
        dataIndex: 'vehicle_name',
        title: 'Vehicle Name'
    },
    {
        key: 'pick_up_date_time',
        dataIndex: 'pick_up_date_time',
        title: 'Pick-up Date',
        render: fmtDate
    },
    {
        key: 'drop_off_date_time',
        dataIndex: 'drop_off_date_time',
        title: 'Drop-off Date',
        render: fmtDate
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: 'Amount',
        render: amountCell,
        className: 'text-right'
    }
]

class CarBooking extends React.Component {
    render() {
        return <Booking product="car" columns={columns} />
    }
}

export default CarBooking
