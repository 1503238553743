import React, { useMemo } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Table, Card, Button, Radio, Row, Col } from 'antd'
import container from './container'

const ComparisonTable = props => {
    const {
        filter,
        handleTypeChange,
        dataSource,
        expandedRowKeys,
        expandAll,
        collapseAll,
        handleExpand,
        downloadLink
    } = props

    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Cabin Class',
                dataIndex: 'cabin_class',
                key: 'cabin_class'
            },
            {
                title: 'Booking Class',
                dataIndex: 'booking_class',
                key: 'booking_class'
            },
            {
                title: 'Currency',
                dataIndex: 'currency',
                key: 'currency'
            },
            {
                title: 'Base Fare',
                dataIndex: 'base_fare',
                key: 'base_fare'
            },
            {
                title: 'Taxes',
                dataIndex: 'tax_fare',
                key: 'tax_fare'
            },
            {
                title: 'Total Fare',
                dataIndex: 'total_fare',
                key: 'total_fare'
            },
            {
                title: 'Outbound',
                dataIndex: 'outbound',
                key: 'outbound'
            },
            {
                title: 'Inbound',
                dataIndex: 'inbound',
                key: 'inbound'
            }
        ]
    }, [])

    return (
        <Card
            size={'small'}
            title={'Result'}
            extra={
                <Row type={'flex'} gutter={16} align={'middle'}>
                    <Col>
                        <Radio.Group onChange={handleTypeChange} value={filter && filter.type}>
                            <Radio value={'all'}>All</Radio>
                            {/*<Radio value={'one'}>One supplier</Radio>*/}
                            <Radio value={'many'}>Comparable only</Radio>
                        </Radio.Group>
                    </Col>
                    <Col>
                        <Button type={'default'} onClick={expandAll}>
                            Expand all
                        </Button>
                    </Col>
                    <Col>
                        <Button type={'default'} onClick={collapseAll}>
                            Collapse all
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            disabled={!downloadLink}
                            type={'primary'}
                            icon={<DownloadOutlined />}
                            href={downloadLink}
                            target={'blank'}
                            download={`results-${Date.now()}.zip`}>
                            Download CSV (.zip)
                        </Button>
                    </Col>
                </Row>
            }>
            <Table
                size={'small'}
                bordered
                columns={columns}
                dataSource={dataSource || []}
                expandedRowKeys={expandedRowKeys || []}
                onExpand={handleExpand}
                rowKey={'key'}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ['10', '50', '100', '500', '1000']
                }}
            />
        </Card>
    )
}

export default container(ComparisonTable)
