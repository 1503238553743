import React from 'react'
import container from './container'
import { SearchOutlined } from '@ant-design/icons'
import { Card, Input, Button, Select, Spin, Form, Row, Col } from 'antd'

import suppliers from '../../../suppliers'

const HotelMappingForm = props => {
    const { $props, $loading, $fieldProps } = props
    return (
        <Spin spinning={$loading}>
            <Form colon={false} {...$props}>
                <Card
                    title="Hotel Mapping"
                    size="small"
                    extra={
                        <Button htmlType={'submit'} type="primary">
                            <SearchOutlined /> Find
                        </Button>
                    }>
                    <div className="d-md-flex align-items-center">
                        <Card.Meta description="Check hotel mapping between GoQuo and suppliers." />
                    </div>
                    <br />
                    <Row gutter={16}>
                        <Col xs={24} md={4}>
                            <Form.Item name={'supplier'} wrapperCol={{ flex: 1 }} {...$fieldProps.supplier}>
                                <Select showSearch style={{ width: '100%' }}>
                                    {suppliers.map(supplier => (
                                        <Select.Option value={supplier} key={supplier}>
                                            {supplier}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={20}>
                            <Form.Item name={'hotel_ids'} wrapperCol={{ flex: 1 }} {...$fieldProps.hotel_ids}>
                                <Input placeholder="Input multiple hotel id here. Separated by ';'" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Spin>
    )
}

export default container(HotelMappingForm)
