import { createAction } from '@'
import axios from 'axios'
import { getAuth, loginErrorHandle } from '../../@misc'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('ToggleUserStatus', async params => {
    const auth = getAuth()

    try {
        await axios({
            url: `${ENDPOINT}/users/${params.id}`,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', ...auth },
            data: {
                del_flag: params.del_flag !== 1
            }
        })
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }

    if (params.onSuccess) {
        await params.onSuccess()
    }
})
