import styled from 'styled-components'

export const Root = styled.div`
    .ant-col.ant-form-item-label {
        width: 120px;
    }

    .ant-col.ant-form-item-control {
        width: 200px;
        margin-bottom: 10px;
    }
`
