import { createAction } from '@'
import axios from 'axios'
import { $regions } from '../stores'
import qs from 'querystring'

const ENDPOINT = process.env.REACT_APP_OFFER_ENDPOINT

export default createAction('GetRegionMapping', async params => {
    try {
        $regions.setState(() => [])
        const res = await axios({
            url: `${ENDPOINT}/region-mapping?${qs.stringify(params)}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })

        $regions.setState(() => (res && res.data.mappings) || [])
    } catch (e) {
        console.error(e)
    }
})
