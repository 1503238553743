import { createAction } from '@'
import { getAuth } from '@misc'
import axios from 'axios'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('CreateAlert', async values => {
    const auth = getAuth()
    const emailId = values.account.split('|')
    let { data } = await axios({
        url: `${ENDPOINT}/alert-settings`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...auth },
        data: {
            project: values.project,
            email: emailId[0],
            user_id: emailId[1]
        }
    })

    return data
})
