export default function apiErrorHandler(error) {
    console.log(error)
    const { response } = error
    if (!response) {
        return error.message
    }

    const { data: errorDetail } = response
    if (errorDetail) {
        const errorCode =
            (errorDetail.thirdPartyResponse && errorDetail.thirdPartyResponse.statusCode) || errorDetail.code

        switch (errorCode) {
            case 401:
                alert('Your session has expired. Please logout and re-login!')
                localStorage.clear()
                window.location.href = `${window.location.origin}/#/login`
                return 'Your session has expired. Please logout and re-login!'
            default:
                return `${error.message}:\n${errorDetail.message}`
        }
    } else {
        return error.message
    }
}
