import { compose, hoc, useStore } from '@'
import { $regions } from '../../stores'

const container = compose(
    hoc(props => {
        const [regions] = useStore([$regions])

        return {
            regions,
            ...props
        }
    })
)

export default container
