import React from 'react'
import UserForm from '../UserForm'
import UserTable from '../UserTable'
import container from './container'

const UserPage = () => {
    return (
        <div>
            <UserForm />
            <UserTable />
        </div>
    )
}

export default container(UserPage)
