import styled from 'styled-components'
import bgUrl from './images/bg.png'

export const Root = styled.div`
  
    background: #79c1cc url("${bgUrl}") center center no-repeat;
    background-size: cover;
    
    .container {
        display: block;
        width: 620px;
        margin: 0 auto;
        padding: 150px 0;
    }
    
    .search-text {
      > * {
        display: inline-block;
      }
    }
    
    .ant-select {
      display: block;
    }

    .ant-select-selection {
        overflow: hidden;
    }

    .ant-row-flex {
        margin: 8px 0;
    }
    
    .ant-form-item {
      width: 100%;
      > div {
        width: 100%;
        > div {
          width: 100%;
        }
      }
    }
`
export const Room = styled.div`
    .ant-form-item-children {
        display: flex;
        align-items: center;
    }
    .ant-form-item-label {
        width: 80px;
    }
`
