import { compose, hoc, useStore } from '@'
import { $amenitiesUnmap, $amenitiesType, $amenitiesProvider, $amenitiesGoQuo } from '../../stores'
import SaveUnmapAmenities from '../../actions/SaveAmenitiesUnmap'
import { notification } from 'antd'
import apiErrorHandler from '../../../api-error-handler'

const container = compose(
    hoc(props => {
        const [amenitiesGoQuo, amenitiesUnmap, amenitiesType, amenitiesProvider] = useStore([
            $amenitiesGoQuo,
            $amenitiesUnmap,
            $amenitiesType,
            $amenitiesProvider
        ])

        const saveAmenities = async param => {
            SaveUnmapAmenities(param).catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
        }

        return { saveAmenities, amenitiesGoQuo, amenitiesUnmap, amenitiesType, amenitiesProvider, ...props }
    })
)

export default container
