import React from 'react'
import { Spin, Input, InputNumber, Form, DatePicker, Checkbox, Select, Button, Card, Row, Col } from 'antd'
import { Root } from './styled'
import container from './container'

const InputForm = props => {
    const { users, cabinList, $props, isRoundTrip, $loading, $fieldProps, handleReset } = props
    return (
        <Spin spinning={$loading}>
            <Form layout={'inline'} {...$props}>
                <Card
                    style={{ flex: 1 }}
                    size={'small'}
                    title={'Flight Price Comparison'}
                    extra={
                        <Row type={'flex'} gutter={8}>
                            <Col>
                                <Button htmlType={'button'} onClick={handleReset} type={'danger'}>
                                    Reset
                                </Button>
                            </Col>
                            <Col>
                                <Button htmlType={'submit'} type={'primary'}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    }>
                    <Root>
                        <div>
                            <Form.Item name={'userId'} label={'User'} {...$fieldProps.userId}>
                                <Select
                                    loading={!users}
                                    disabled={!users}
                                    style={{ width: '100%' }}
                                    placeholder={'Please select an user'}
                                    showSearch
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {(users || []).map(user => (
                                        <Select.Option key={user.id} value={user.id}>
                                            {`${user.name}`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <Row>
                            <Form.Item
                                name={'departure_airport_code'}
                                label={'From'}
                                {...$fieldProps.departure_airport_code}>
                                <Input placeholder={'departure_airport_code'} />
                            </Form.Item>
                            <Form.Item name={'arrival_airport_code'} label={'To'} {...$fieldProps.arrival_airport_code}>
                                <Input placeholder={'arrival_airport_code'} />
                            </Form.Item>
                            <Form.Item name={'is_round_trip'} label={'Is Round Trip'} {...$fieldProps.is_round_trip}>
                                <Checkbox />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item name={'departure_date'} label={'Departure Date'} {...$fieldProps.departure_date}>
                                <DatePicker style={{ width: 200 }} placeholder={'departure_date'} />
                            </Form.Item>

                            {isRoundTrip ? (
                                <Form.Item name={'return_date'} label={'Return Date'} {...$fieldProps.return_date}>
                                    <DatePicker style={{ width: 200 }} placeholder={'return_date'} />
                                </Form.Item>
                            ) : null}
                        </Row>
                        <Row>
                            <Form.Item name={'adults'} label={'Adults'} {...$fieldProps.adults}>
                                <InputNumber style={{ width: 200 }} min={1} placeholder={'adults'} />
                            </Form.Item>
                            <Form.Item name={'children'} label={'Children'} {...$fieldProps.children}>
                                <InputNumber style={{ width: 200 }} min={0} placeholder={'children'} />
                            </Form.Item>
                            <Form.Item name={'infants'} label={'Infants'} {...$fieldProps.infants}>
                                <InputNumber style={{ width: 200 }} min={0} placeholder={'infants'} />
                            </Form.Item>
                        </Row>
                        <div>
                            <Form.Item name={'cabin_class'} label={'Cabin Class'} {...$fieldProps.cabin_class}>
                                <Select placeholder={'cabin_class'} style={{ width: 200 }}>
                                    {cabinList.map(item => (
                                        <Select.Option key={item} value={item}>
                                            {item}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item name={'currency'} label={'Currency'} {...$fieldProps.currency}>
                                <Input placeholder={'currency'} />
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item name={'wait_time'} label={'Wait Time'} {...$fieldProps.wait_time}>
                                <InputNumber style={{ width: 200 }} min={1000} step={1000} placeholder={'wait_time'} />
                            </Form.Item>
                        </div>
                    </Root>
                </Card>
            </Form>
        </Spin>
    )
}

export default container(InputForm)
