import React from 'react'
import container from './container'
import MappingSearch from '../MappingSearch'

const MappingPage = () => {
    return (
        <div>
            <MappingSearch />
        </div>
    )
}

export default container(MappingPage)
