import React, { useMemo } from 'react'
import { UserAddOutlined, EditOutlined } from '@ant-design/icons'
import { Table, Card, Spin, Button, Switch, Row, Col } from 'antd'
import UserChangeStatus from '../UserChangeStatus'
import container from './container'

const UserTable = props => {
    const { powerMap, users, roles, refresh, handleOpenCreateUser, handleOpenEditUser } = props

    const columns = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: 'Role',
                dataIndex: 'role_id',
                key: 'role_id',
                render: roleId => {
                    if (!roles) {
                        return <Spin spinning />
                    }
                    const role = roles.find(g => g.id === roleId)
                    return (role && role.description) || ''
                }
            },
            {
                title: 'External',
                key: 'external',
                render: item => {
                    return <Switch checked={item.external === 1} />
                },
                align: 'center'
            },
            {
                title: 'Status',
                key: 'del_flag',
                render: item => {
                    return (
                        <UserChangeStatus item={item} onSuccess={refresh}>
                            <Switch disabled={!powerMap || !powerMap['edit']} checked={item.del_flag === 0} />
                        </UserChangeStatus>
                    )
                },
                align: 'center'
            },
            powerMap && powerMap['edit']
                ? {
                      title: 'Action',
                      key: 'action',
                      render: item => {
                          return (
                              <Button
                                  type={'danger'}
                                  ghost
                                  icon={<EditOutlined />}
                                  onClick={() => {
                                      handleOpenEditUser(item)
                                  }}
                              />
                          )
                      }
                  }
                : null
        ].filter(i => i)
    }, [roles])

    return (
        <Card size={'small'}>
            <Row>
                <Col>
                    {powerMap && powerMap['create'] ? (
                        <Button type="primary" icon={<UserAddOutlined />} onClick={handleOpenCreateUser}>
                            Create
                        </Button>
                    ) : null}
                </Col>
            </Row>
            <br />
            {powerMap && powerMap['view'] ? (
                <Table
                    loading={!users}
                    size={'small'}
                    bordered
                    columns={columns}
                    dataSource={users || []}
                    rowKey={'id'}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000']
                    }}
                />
            ) : null}
        </Card>
    )
}

export default container(UserTable)
