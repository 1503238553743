import React, { useState } from 'react'
import container from './container'
import { Card, Button, Input, Spin, Form, Row, Col, Space, Modal, Table } from 'antd'
import { HistoryOutlined, SearchOutlined } from '@ant-design/icons'

const CompareHotelForm = props => {
    const { $props, $loading, $fieldProps, getLogs, logsRemoveDuplicate } = props

    const [flagShowLog, setFlagShowLog] = useState(false)

    const showLog = () => {
        setFlagShowLog(true)
        getLogs()
    }

    const closeLog = () => {
        setFlagShowLog(false)
    }

    const logTableColumn = [
        {
            key: 'user_id',
            dataIndex: 'user_id',
            title: 'User'
        },
        {
            key: 'hotel_id_retained',
            dataIndex: 'hotel_id_retained',
            title: 'Hotel ID Retained'
        },
        {
            key: 'hotel_id_removed',
            dataIndex: 'hotel_id_removed',
            title: 'Hotel ID Removed'
        },
        {
            key: 'created_at',
            dataIndex: 'created_at',
            title: 'Created At'
        }
    ]

    const expandTableColumn = [
        {
            key: 'input',
            dataIndex: 'input',
            title: 'Input Request',
            render: cell => <pre>{JSON.stringify(JSON.parse(cell), null, 1)}</pre>
        },
        {
            key: 'new_mappings',
            dataIndex: 'new_mappings',
            title: 'New Mappings',
            render: cell => <pre>{JSON.stringify(JSON.parse(cell), null, 1)}</pre>
        },
        {
            key: 'result',
            dataIndex: 'result',
            title: 'Result',
            render: cell => <pre>{JSON.stringify(JSON.parse(cell), null, 2)}</pre>
        }
    ]

    return (
        <div>
            <Spin spinning={$loading}>
                <Form colon={false} {...$props}>
                    <Card
                        title="Enter GoQuo Hotel IDs"
                        size="small"
                        extra={
                            <Space wrap>
                                <Button onClick={() => showLog()}>
                                    <HistoryOutlined /> Logs
                                </Button>
                                <Button htmlType={'submit'} type="primary">
                                    <SearchOutlined /> Find
                                </Button>
                            </Space>
                        }>
                        <div className="d-md-flex align-items-center">
                            <Card.Meta description="To check duplicate hotels mapping between GoQuo and suppliers." />
                        </div>
                        <br />
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="List of GoQuo hotel ids: "
                                    name={'ids'}
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 10 }}
                                    {...$fieldProps.ids}>
                                    <Input placeholder="Type in hotel ids, split by ;" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="Name Similarity Percentage: "
                                    name={'percent'}
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 10 }}
                                    {...$fieldProps.percent}>
                                    <Input placeholder={'Percentage of duplicate (%)'} />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Distance: "
                                    name={'distance'}
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 8 }}
                                    {...$fieldProps.distance}>
                                    <Input placeholder={'Distance between 2 hotels (meters)'} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </Spin>
            <Modal
                visible={flagShowLog}
                style={{ minWidth: '840px' }}
                title={'Logs Remove Duplicate Mapping'}
                onClick={closeLog}
                onCancel={() => setFlagShowLog(false)}
                footer={null}>
                <Table
                    rowKey="log_id"
                    bordered
                    dataSource={logsRemoveDuplicate}
                    columns={logTableColumn}
                    pagination={{ size: 'large', simple: true }}
                    expandedRowRender={row => (
                        <Table
                            rowKey="log_id"
                            bordered
                            dataSource={[row]}
                            columns={expandTableColumn}
                            pagination={false}
                        />
                    )}
                />
            </Modal>
        </div>
    )
}

export default container(CompareHotelForm)
