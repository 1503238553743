import styled from 'styled-components'

export const Root = styled.div`
    .ant-row.ant-form-item {
        display: block;
    }

    .ant-form-item-label {
        width: 230px;
    }

    .ant-col.ant-form-item-control {
        margin-bottom: 10px;
    }

    .ant-row.width-auto .ant-col.ant-form-item-control {
        width: auto;
    }
`
