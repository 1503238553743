const imm = (state, updates) => {
    if (!state) return updates
    const map = Array.isArray(state) ? state.slice() : Object.assign({}, state)
    for (let key in updates) {
        const value = updates[key]
        if (typeof value === 'function') {
            map[key] = value(map[key])
        } else {
            map[key] = imm(map[key], value)
        }
    }
    return map
}

export default imm
