import { compose, hoc } from '@'
import { useForm, useFormLoading } from '@form'
import GetRegionMapping from '../../actions/GetRegionMapping'

const $fieldProps = {
    type: { rules: [{ required: true, message: 'Please select a type' }] },
    value: { rules: [{ required: true, message: 'Please enter destination_code or region_id' }] }
}

const container = compose(
    hoc(props => {
        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                GetRegionMapping({
                    [values.type]: values.value
                })
            }

            return {
                name: 'RegionMappingForm',
                onSubmit
            }
        }, [])

        const $loading = useFormLoading($form.name)

        return {
            $props,
            $loading,
            $fieldProps,
            ...props
        }
    })
)

export default container
