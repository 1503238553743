import { createAction } from '@'

const delimiter = ','

const genRow = (column, item) => {
    return Object.entries(column)
        .reduce((arr, [key]) => {
            arr.push(`"${[item[key]]}"`)
            return arr
        }, [])
        .join(delimiter)
}

export default createAction('ExportFile', dataSource => {
    const column = {
        name: 'Name',
        cabin_class: 'Cabin Class',
        booking_class: 'Booking Class',
        currency: 'Currency',
        base_fare: 'Base Fare',
        tax_fare: 'Taxes',
        total_fare: 'Total Fare',
        outbound: 'Outbound',
        inbound: 'Inbound'
    }

    const data = [
        Object.entries(column)
            .map(([, value]) => `"${value}"`)
            .join(delimiter)
    ]

    dataSource.forEach(item => {
        data.push(genRow(column, item))
        if (item.children) {
            item.children.forEach(child => {
                data.push(genRow(column, { ...child, name: `  ${child.name}` }))
            })
        }
    })

    return data.join('\n')
})
