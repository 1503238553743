import { compose, hoc, useStore } from '@'
import { useCallback } from 'react'
import GetViatorDestinationCode from '../../actions/GetViatorDestinationCode'
import { $codeSuggestions } from '../../stores'

const container = compose(
    hoc(props => {
        const [codeSuggestions] = useStore([$codeSuggestions])

        const handleSearchChange = useCallback(value => {
            GetViatorDestinationCode(value).catch(console.error)
        }, [])

        const handleSearch = useCallback(value => {
            console.log(value)
        }, [])

        return {
            codeSuggestions,
            handleSearchChange,
            handleSearch,
            ...props
        }
    })
)

export default container
