import { useEffect, useMemo } from 'react'
import { compose, hoc, useStore } from '@'
import { useForm, useFormLoading, useFormValues } from '@form'
import { withModalV4, useModal } from '@modal'
import GetRoles from '../../actions/GetRoles'
import CreateUser from '../../actions/CreateUser'
import EditUser from '../../actions/EditUser'
import { $roles } from '../../stores'

const $fieldProps = isEditMode => {
    const base = {
        name: { rules: [{ required: true, message: 'Name is required' }] },
        email: {
            rules: [
                { required: true, message: 'Email is required' },
                {
                    type: 'email',
                    message: 'It is not valid email!'
                }
            ]
        },
        password: { rules: [{ required: true, message: 'Password is required' }] },
        role: { rules: [{ required: true, message: 'Role is required' }] },
        external: { valuePropName: 'checked' }
    }
    if (isEditMode) {
        delete base.password
    }
    return base
}

const container = compose(
    hoc(props => {
        const { $modal, isEditMode } = props

        const [roles] = useStore([$roles])

        useEffect(() => {
            GetRoles().catch(console.error)
        }, [])

        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                if (values.id) {
                    await EditUser(values)
                } else {
                    await CreateUser(values)
                }
                $modal.close()
            }

            return {
                name: 'UserForm',
                onSubmit,
                props: { colon: false, layout: 'vertical', wrapperCol: { flex: 1 } }
            }
        }, [$modal])

        const $loading = useFormLoading($form.name)

        $modal.setForm($form)
        $modal.setLoading($loading)

        return {
            roles,
            $form,
            $props,
            $loading,
            $fieldProps: useMemo(() => $fieldProps(isEditMode), [isEditMode]),
            ...props
        }
    }),
    withModalV4(props => {
        const $values = useFormValues('UserForm')

        const isEditMode = useMemo(() => {
            return !!($values && $values.id)
        }, [$values])

        const [$props, $modal] = useModal(
            () => ({
                name: 'UserForm',
                props: {
                    title: isEditMode ? 'Edit User' : 'Create User',
                    bodyStyle: { paddingTop: 0, paddingBottom: 0 },
                    destroyOnClose: true,
                    okText: isEditMode ? 'Update' : 'Create'
                }
            }),
            [isEditMode]
        )

        return {
            $modal,
            $props,
            isEditMode,
            ...props
        }
    })
)

export default container
