import React from 'react'
import 'antd/dist/antd.css'
import { Button, Card, Steps, Descriptions, Result } from 'antd'
import SearchForm from '../SearchForm'
import AvailabilityPage from '../AvailabilityPage'
import HotelDetailsPage from '../HotelDetailsPage'
import BookingPage from '../BookingPage'
import container from './container'

const HotelRebook = props => {
    const { step, book, bookRequest, handleBack, handleSearch, handleRebook } = props
    return book ? (
        <div style={{ background: '#fff' }}>
            {book.success ? (
                <Result
                    status={'success'}
                    title={'Booking Success'}
                    subTitle={
                        <div>
                            <br />
                            <Descriptions column={1}>
                                <Descriptions.Item label={'Status'}>{book.data.status}</Descriptions.Item>
                                <Descriptions.Item label={'Booking ID'}>{book.data.booking_id}</Descriptions.Item>
                                <Descriptions.Item label={'Client Ref'}>
                                    {bookRequest && bookRequest.client_ref}
                                </Descriptions.Item>
                            </Descriptions>
                            <br />
                            <Card size={'small'} title={'Detail'}>
                                {(book.data.rooms || []).map((room, idx) => (
                                    <Descriptions key={idx} column={room.confirmation_number ? 4 : 3}>
                                        <Descriptions.Item label={'Room No'}>{room.idx}</Descriptions.Item>
                                        <Descriptions.Item label={'Status'}>{room.status}</Descriptions.Item>
                                        <Descriptions.Item label={'Booking ID'}>{room.booking_id}</Descriptions.Item>
                                        {room.confirmation_number ? (
                                            <Descriptions.Item label={'Confirmation Number'}>
                                                {room.confirmation_number}
                                            </Descriptions.Item>
                                        ) : null}
                                    </Descriptions>
                                ))}
                            </Card>
                        </div>
                    }
                    extra={[
                        <Button key={'search'} onClick={handleSearch} type={'primary'}>
                            Search Another
                        </Button>
                    ]}
                />
            ) : (
                <Result
                    status={'error'}
                    title={'Booking Failed'}
                    subTitle={JSON.stringify(book)}
                    extra={[
                        <Button key={'rebook'} onClick={handleRebook} type={'primary'}>
                            Rebook
                        </Button>
                    ]}
                />
            )}
        </div>
    ) : (
        <div>
            {step === undefined ? (
                <SearchForm />
            ) : (
                <Card
                    title={'Make your booking'}
                    size={'small'}
                    extra={
                        <Button type={'primary'} ghost onClick={handleBack}>
                            Back
                        </Button>
                    }>
                    <br />
                    <Steps current={step} size={'small'}>
                        <Steps.Step title={'Select Hotel'} key={'select-hotel'} />
                        <Steps.Step title={'Select Room'} key={'select-room'} />
                        <Steps.Step title={'Confirm Booking'} key={'book'} />
                    </Steps>
                    <br />
                    <div>{step === 0 ? <AvailabilityPage /> : null}</div>
                    <div>{step === 1 ? <HotelDetailsPage /> : null}</div>
                    <div>{step === 2 ? <BookingPage /> : null}</div>
                </Card>
            )}
        </div>
    )
}

export default container(HotelRebook)
