import Dashboard from '../layouts/dashboard.jsx'
import { LoginPage } from '@auth'

const indexRoutes = [
    {
        path: '/login',
        name: 'Auth',
        icon: 'mdi mdi-account-key',
        component: LoginPage
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
    }
]

export default indexRoutes
