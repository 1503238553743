import { useCallback, useState } from 'react'
import { compose, hoc, useStore } from '@'
import Login from '../../actions/Login'
import { $googleStatus } from '../../stores'

const loadScript = async (id, src) => {
    return new Promise(resolve => {
        const init = (d, s, cb) => {
            const fjs = d.getElementsByTagName(s)[0]
            const js = d.createElement(s)
            js.id = id
            js.src = src
            if (fjs && fjs.parentNode) {
                fjs.parentNode.insertBefore(js, fjs)
            } else {
                d.head.appendChild(js)
            }
            js.onload = cb
        }
        init(document, 'script', resolve)
    })
}

loadScript('google-login', 'https://apis.google.com/js/client:platform.js')
    .then(async () => {
        await new Promise(resolve => {
            window.gapi.load('auth2', () => {
                resolve()
            })
        })
        await window.gapi.auth2.init({
            client_id: process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID
        })
        $googleStatus.setState(() => 'ready')
    })
    .catch(err => {
        console.error(err)
    })

const container = compose(
    hoc(props => {
        const [googleStatus] = useStore([$googleStatus])
        const [loading, setLoading] = useState(false)

        const handleLogin = useCallback(event => {
            event && event.preventDefault()
            setLoading(true)
            Promise.resolve()
                .then(() => {
                    if (!window.gapi.auth2) {
                        return new Promise(resolve => {
                            window.gapi.load('auth2', () => {
                                resolve()
                            })
                        })
                    }
                })
                .then(() => {
                    const auth = window.gapi.auth2.getAuthInstance()
                    return auth.grantOfflineAccess({
                        prompt: 'select_account'
                    })
                })
                .then(res => {
                    return Login({
                        token: res.code
                    }).catch(err => {
                        console.error(err)
                    })
                })
                .catch(err => {
                    console.info('Google sign in error:', err)
                    // redirect login handle
                })
                .finally(() => {
                    setLoading(false)
                })
        }, [])

        return {
            handleClick: handleLogin,
            disabled: !googleStatus,
            loading,
            ...props
        }
    })
)

export default container
