import React from 'react'
import { Input, Select, Switch, Form, Spin } from 'antd'
import container from './container'

const UserForm = props => {
    const { roles, $form, $props, $loading, $fieldProps, isEditMode } = props

    return (
        <Spin spinning={$loading}>
            <Form {...$props} colon={false} layout={'vertical'} wrapperCol={{ flex: 1 }}>
                {$form.Item({ name: 'id' })}
                {$form.Item({ name: 'onSuccess' })}
                <Form.Item name={'name'} label={'Name'} {...$fieldProps.name}>
                    <Input placeholder={'name'} />
                </Form.Item>
                <Form.Item name={'email'} label={'Email'} {...$fieldProps.email}>
                    <Input disabled={isEditMode} placeholder={'example@email.com'} />
                </Form.Item>
                <Form.Item name={'password'} label={'Password'} {...$fieldProps.password}>
                    <Input placeholder={'password'} type={'password'} />
                </Form.Item>
                <Form.Item name={'role'} label={'Role'} {...$fieldProps.role}>
                    <Select placeholder={'Please select a role'}>
                        {(roles || []).map(item => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.description}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={'external'} label={'External'} {...$fieldProps.external}>
                    <Switch />
                </Form.Item>
            </Form>
        </Spin>
    )
}

export default container(UserForm)
