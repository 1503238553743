import { compose, hoc, useStore } from '@'
import { useForm, useFormLoading, useFormValues } from '@form'
import Book from '../../actions/Book'
import { $prebook } from '../../stores'

const $fieldProps = {
    request_id: { rules: [{ required: true, message: 'Request ID is required' }] },
    client_ref: { rules: [{ required: true, message: 'Client Ref is required' }] },
    title: { rules: [{ required: true, message: 'Please select a title!' }] },
    first_name: { rules: [{ required: true, message: 'First name is required!' }] },
    last_name: { rules: [{ required: true, message: 'Last name is required!' }] },
    age: { rules: [{ required: true, message: 'Please select an age!' }] },
    nationality: { rules: [{ required: true, message: 'Please select a nationality!' }] },
    email: { rules: [{ required: true, message: 'Email is required!' }] },
    mobile: { rules: [{ required: true, message: 'Mobile is required!' }] },
    city: { rules: [{ required: true, message: 'City is required!' }] },
    address: { rules: [{ required: true, message: 'Address is required!' }] },
    postal_code: { rules: [{ required: true, message: 'Postal Code is required!' }] }
}

const container = compose(
    hoc(props => {
        const [prebook] = useStore([$prebook])

        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                await Book(values)
            }
            return {
                name: 'BookingPage',
                onSubmit
            }
        }, [])

        const $loading = useFormLoading($form.name)

        const $values = useFormValues($form.name)

        return {
            $props,
            $form,
            $loading,
            $values,
            $fieldProps,
            prebook,
            ...props
        }
    })
)

export default container
