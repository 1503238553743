import React from 'react'
import { GoogleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import container from './container'

const GoogleLogin = props => {
    const { disabled, handleClick } = props
    return (
        <Button disabled={disabled} onClick={handleClick} type="primary" icon={<GoogleOutlined />} block>
            Sign in with Google
        </Button>
    )
}

export default container(GoogleLogin)
