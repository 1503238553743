import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import container from './container'

const CodeSearch = props => {
    const { codeSuggestions, handleSearchChange, handleSearch } = props
    return (
        <Select
            suffixIcon={<SearchOutlined />}
            style={{ width: 500 }}
            placeholder={'Destination Search'}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={true}
            filterOption={false}
            onSearch={handleSearchChange}
            onChange={handleSearch}
            notFoundContent={null}>
            {(codeSuggestions || []).map(item => (
                <Select.Option key={item.value} value={item.value} style={{ whiteSpace: 'normal' }}>
                    {item.text}
                </Select.Option>
            ))}
        </Select>
    )
}

export default container(CodeSearch)
