import React from 'react'
import container from './container'
import { Card } from 'antd'
import AirportSearch from '../AirportSearch'
import CodeSearch from '../CodeSearch'

const MappingSearch = () => {
    return (
        <div>
            <Card size={'small'} title={'Tour Mapping'}>
                <AirportSearch />
                <CodeSearch />
            </Card>
        </div>
    )
}

export default container(MappingSearch)
