import React from 'react'
import RoleForm from '../RoleForm'
import RoleTable from '../RoleTable'
import container from './container'

const RolePage = () => {
    return (
        <div>
            <RoleForm />
            <RoleTable />
        </div>
    )
}

export default container(RolePage)
