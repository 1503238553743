import React from 'react'

import { Card, Button, Table, Avatar, Row, Descriptions, Tag, PageHeader, Typography } from 'antd'
import _ from 'lodash'
import { format, parse, isBefore } from 'date-fns'
import axios from 'axios'

import Booking from '../../components/booking'

const { Paragraph } = Typography
const airlineLogo = require.context('../../assets/airlines/logo', true)

const dateFormat = 'yyyy-MM-dd HH:mm'
const bookingFmt = 'yyyy-MM-dd HH:mm'
const goquoFmt = "yyyy-MM-dd'T'HH:mm"

const ENDPOINT = process.env.REACT_APP_API_URL

function statusCell(status) {
    switch (status) {
        case 'Issued':
            return <Tag color="green">{status}</Tag>
        case 'Pending':
        case 'PendingForTicketing':
            return <Tag color="orange">{status}</Tag>
        case 'Cancelled':
            return <Tag color="gray">{status}</Tag>
        case 'TicketingFailed':
        case 'Failed':
            return <Tag color="red">{status}</Tag>
        default:
            return <Tag color="gray">{status}</Tag>
    }
}

function passengerCell(_cell, row) {
    return <span>{`${row.first_name} ${row.last_name}`}</span>
}

function routeCell(_cell, row) {
    return <span>{`${row.departure_airport} - ${row.arrival_airport}`}</span>
}

function amountCell(_cell, row) {
    return <span>{row.currency_code + (row.amount || 0.0).toFixed(2)}</span>
}

const columns = [
    {
        key: 'booking_reference',
        dataIndex: 'booking_reference',
        title: 'Client Reference',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
        openLink: true
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        render: statusCell,
        className: 'text-center'
    },
    {
        key: 'pnr',
        dataIndex: 'pnr',
        title: 'PNR'
    },
    {
        key: 'airline_code',
        dataIndex: 'airline_code',
        title: 'Airline'
    },
    {
        key: 'supplier_code',
        dataIndex: 'supplier_code',
        title: 'Supplier'
    },
    {
        key: 'passenger',
        dataIndex: 'passenger',
        title: 'Passenger',
        render: passengerCell
    },
    {
        key: 'route',
        dataIndex: 'route',
        title: 'Route',
        render: routeCell
    },
    {
        key: 'booking_date',
        dataIndex: 'booking_date',
        title: 'Booking Date'
    },
    {
        key: 'departure_date',
        dataIndex: 'departure_date',
        title: 'Departure'
    },
    {
        key: 'arrival_date',
        dataIndex: 'arrival_date',
        title: 'Return'
    },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: 'Amount',
        render: amountCell,
        className: 'text-right'
    }
]

class FlightBooking extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            airports: []
        }
    }

    render() {
        return (
            <Booking
                product="flight"
                columns={columns}
                DetailForm={FlightForm}
                statuses={['Issued', 'Pending', 'Cancelled', 'Failed', 'Created']}
                attTabId="booking_reference"
            />
        )
    }
}

class FlightForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            airports: {}
        }
    }

    UNSAFE_componentWillReceiveProps({ booking, details }) {
        if (!_.isEmpty(details) && !_.isEmpty(booking)) {
            const departure = details.segments.find(
                seg => seg.departure_airport.iata_code === booking.departure_airport
            )
            const arrival = details.segments.find(seg => seg.departure_airport.iata_code === booking.arrival_airport)

            if (departure) {
                booking.departure_info = departure.departure_airport
            }
            if (arrival) {
                booking.arrival_info = arrival.departure_airport
            }

            this.mixPassengers(details)
        }
    }

    mixPassengers(pnr) {
        const baggages = _.groupBy(pnr.baggages || [], 'flight_no')
        const seats = _.groupBy(pnr.seats || [], 'flight_no')
        const meals = _.groupBy(pnr.meals || [], 'flight_no')

        const passengers = []
        pnr.segments.forEach(segment => {
            const baggage = _.keyBy(baggages[segment.flight_no], 'pax_id')
            const meal = _.keyBy(meals[segment.flight_no], 'pax_id')
            const seat = _.keyBy(seats[segment.flight_no], 'pax_id')

            pnr.paxes.forEach(pax => {
                pax.baggage = baggage[pax.pax_id] ? baggage[pax.pax_id].code : ''
                pax.meal = meal[pax.pax_id] ? meal[pax.pax_id].name || meal[pax.pax_id].code : ''
                pax.seat = seat[pax.pax_id] ? seat[pax.pax_id].seat_number : ''

                passengers.push({ ...pax, ...segment })
            })
        })

        this.setState({ passengers })
    }

    groupSegment = segments => {
        const { booking } = this.props
        const isOneWay = segments.length === 1 || !booking.is_roundtrip || !booking.arrival_date

        let outbound = segments
        let inbound = []
        if (!isOneWay) {
            const arrivalDate = format(parse(booking.arrival_date, bookingFmt, new Date()), 'yyyy-MM-dd')
            const splitIndex = _.findIndex(segments, s => s.departure_date_time.startsWith(arrivalDate))

            inbound = segments.slice(splitIndex)
            outbound = segments.slice(0, splitIndex)
        }

        const departFrom = outbound.length ? _.head(outbound).departure_airport.city : ''
        const departTo = outbound.length ? _.last(outbound).arrival_airport.city : ''

        const returnFrom = inbound.length ? _.head(inbound).departure_airport.city : ''
        const returnTo = inbound.length ? _.last(inbound).arrival_airport.city : ''

        return {
            outbound,
            inbound,
            departFrom,
            departTo,
            returnFrom,
            returnTo
        }
    }

    airportDescription = (cell, row, dateTimeKey) => {
        const title = cell.iata_code + ' - ' + cell.city
        const description = (
            <>
                {cell.name}
                <br />
                {format(parse(row[dateTimeKey], goquoFmt, new Date()), 'yyyy-MM-dd HH:mmaaa')}
            </>
        )

        return <Card.Meta title={title} description={description}></Card.Meta>
    }

    resendEticket = async (url, booking) => {
        const headers = JSON.parse(localStorage.getItem('headers'))
        const { data } = await axios({
            url: `${ENDPOINT}/proxy`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...headers },
            data: {
                url,
                method: 'POST',
                body: booking,
                json: true
            }
        })

        console.log('data', data)
    }

    render() {
        const { booking = {} } = this.props
        const { segments = [], currency, total_fare } = this.props.details || {}
        const { outbound, inbound, departFrom, departTo, returnFrom, returnTo } = this.groupSegment(segments)

        const amount =
            booking.currency_code && booking.amount ? booking.currency_code + booking.amount : currency + total_fare

        const isCancellable =
            isBefore(new Date(), parse(booking.departure_date, dateFormat, new Date())) &&
            ['Issued', 'PendingForTicketing', 'Pending'].includes(booking.status)

        const segmentCols = [
            {
                key: 'flight_no',
                dataIndex: 'flight_no',
                title: 'Flight No.',
                width: 150,
                className: 'text-center',
                render: cell => {
                    let src = `http://www.gstatic.com/flights/airline_logos/70px/${cell.slice(0, 2)}.png`

                    return (
                        <>
                            <Avatar src={src} style={{ marginRight: 5 }}>
                                {cell.slice(0, 2)}
                            </Avatar>
                            {cell}
                        </>
                    )
                }
            },
            {
                key: 'departure_airport',
                dataIndex: 'departure_airport',
                title: 'Departure',
                width: 350,
                render: (cell, row) => this.airportDescription(cell, row, 'departure_date_time')
            },
            {
                key: 'duration',
                dataIndex: 'duration',
                title: 'Duration',
                width: 100,
                className: 'text-center',
                render: cell => (cell ? `${cell} min` : '')
            },
            {
                key: 'arrival_airport',
                dataIndex: 'arrival_airport',
                title: 'Arrival',
                width: 350,
                render: (cell, row) => this.airportDescription(cell, row, 'arrival_date_time')
            },
            {
                key: 'aircraft_type',
                dataIndex: 'aircraft_type',
                title: 'Aircarft'
            },
            {
                key: 'cabin_class',
                dataIndex: 'cabin_class',
                title: 'Cabin'
            }
        ]

        const airlineCode = booking.flight_number.slice(0, 2)
        const airlineImage = () => {
            let img = ''
            try {
                img = airlineLogo(`./${airlineCode}.png`)
            } catch (error) {
                // ignore
            }
            return img
        }

        return (
            <>
                <PageHeader
                    title={`Confirmation: ${booking.pnr}`}
                    tags={statusCell(booking.status)}
                    extra={[
                        <Button
                            key="eticket_callback"
                            type="primary"
                            onClick={() => this.resendEticket(booking.eticket_push_url, this.props.details)}
                            disabled={!booking.eticket_push_url}>
                            Re-send Ticket
                        </Button>,
                        <Button
                            key="cancel_button"
                            type="danger"
                            onClick={() => this.props.toggleCancelBookingModal(booking)}
                            disabled={!isCancellable}>
                            Cancel
                        </Button>
                    ]}>
                    <Row type="flex">
                        <div className="main" style={{ flex: 1 }}>
                            <Paragraph>
                                We recommend that you make a note of the booking confirmation (PNR) or print this to
                                carry with you.
                            </Paragraph>
                            <Descriptions size="small" column={2}>
                                <Descriptions.item label="Booking Reference">
                                    {booking.booking_reference}
                                </Descriptions.item>
                                <Descriptions.item label="Supplier Reference">
                                    {booking.tracking_id || booking.pnr}
                                </Descriptions.item>
                                <Descriptions.item label="Booking Date">{booking.booking_date}</Descriptions.item>
                                <Descriptions.Item label="Total Amount">
                                    <span style={{ color: 'red' }}>{amount}</span>
                                </Descriptions.Item>
                                <Descriptions.item label="Departure">{booking.departure_airport}</Descriptions.item>
                                <Descriptions.item label="Arrival">{booking.arrival_airport}</Descriptions.item>
                            </Descriptions>
                        </div>
                        <div
                            className="extra"
                            style={{
                                marginLeft: 48,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <img src={airlineImage()} alt={airlineCode} style={{ maxWidth: 250 }} />
                        </div>
                    </Row>
                </PageHeader>
                <br />
                <Card title="Flight Details" size="small">
                    <Table
                        rowKey="id"
                        size="middle"
                        title={() => (
                            <strong>
                                Departure: {departFrom} - {departTo}
                            </strong>
                        )}
                        dataSource={outbound}
                        columns={segmentCols}
                        pagination={false}
                    />
                    <br />
                    {Array.isArray(inbound) && inbound.length ? (
                        <Table
                            rowKey="id"
                            size="middle"
                            title={() => (
                                <strong>
                                    Return: {returnFrom} - {returnTo}
                                </strong>
                            )}
                            dataSource={inbound}
                            columns={segmentCols}
                            pagination={false}
                        />
                    ) : null}
                </Card>
                <br />
                <Card title="Passenger Details" size="small">
                    <Card.Meta description="This symbol indicates that the airline has confirmed your request. If your request is not confirmed within 24 hours, please contact us directly." />
                    <br />
                    {generatePaxes(this.state.passengers)}
                </Card>
            </>
        )
    }
}

function generatePaxes(paxes) {
    const gPaxes = _(paxes)
        .groupBy('pax_id')
        .map(grp => {
            const pax = grp[0]
            pax.ancillaries = grp
            return pax
        })
        .value()

    const columns = [
        {
            key: 'title',
            dataIndex: 'title',
            title: 'Title'
        },
        {
            key: 'first_name',
            dataIndex: 'first_name',
            title: 'First Name'
        },
        {
            key: 'last_name',
            dataIndex: 'last_name',
            title: 'Last Name'
        },
        {
            key: 'eticket_no',
            dataIndex: 'eticket_no',
            title: 'E-Ticket'
        },
        {
            key: 'emd_no',
            dataIndex: 'emd_no',
            title: 'EMD'
        }
    ]

    const nestedCols = [
        {
            key: 'flight_no',
            dataIndex: 'flight_no',
            title: 'Flight No'
        },
        {
            key: 'meal',
            dataIndex: 'meal',
            title: 'Meal'
        },
        {
            key: 'seat',
            dataIndex: 'seat',
            title: 'Seat'
        },
        {
            key: 'baggage',
            dataIndex: 'baggage',
            title: 'Baggage'
        }
    ]

    return (
        <Table
            rowKey="pax_id"
            dataSource={gPaxes}
            columns={columns}
            pagination={false}
            expandedRowRender={row => {
                return (
                    <Table
                        // rowKey=""
                        size="middle"
                        dataSource={row.ancillaries}
                        columns={nestedCols}
                        pagination={false}
                    />
                )
            }}
        />
    )
}

export default FlightBooking
