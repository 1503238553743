import { useCallback, useMemo } from 'react'
import { compose, hoc, useStore } from '@'
import { $step, $book, $searchRequest, $bookRequest } from '../../stores'

const container = compose(
    hoc(props => {
        const [step, book, searchRequest, bookRequest] = useStore([
            $step,
            $book,
            $searchRequest,
            $searchRequest,
            $bookRequest
        ])

        const isSearchByHotel = useMemo(() => {
            return searchRequest && searchRequest.ids && searchRequest.ids.length === 1
        }, [searchRequest])

        const handleBack = useCallback(() => {
            $step.setState(s => {
                if (isSearchByHotel && s === 1) {
                    return undefined
                }
                return s === 0 ? undefined : s - 1
            })
        }, [isSearchByHotel])

        const handleSearch = useCallback(() => {
            $step.setState(() => undefined)
            $book.setState(() => undefined)
        }, [])

        const handleRebook = useCallback(() => {
            $book.setState(() => undefined)
        }, [])

        return {
            step,
            book,
            bookRequest,
            handleBack,
            handleSearch,
            handleRebook,
            ...props
        }
    })
)

export default container
