import { createStore } from '@'

export const $users = createStore('hotelRebook:users')
export const $suppliers = createStore('hotelRebook:suppliers')
export const $location = createStore('hotelRebook:location')
export const $searchRequest = createStore('hotelRebook:searchRequest')
export const $searchInfo = createStore('hotelRebook:searchInfo')
export const $hotels = createStore('hotelRebook:hotels')
export const $roomMap = createStore('hotelRebook:roomMap')
export const $hotelMap = createStore('hotelRebook:hotelMap')
export const $step = createStore('hotelRebook:step')
export const $selectedHotel = createStore('hotelRebook:selectedHotel')
export const $prebook = createStore('hotelRebook:prebook')
export const $bookRequest = createStore('hotelRebook:bookRequest')
export const $book = createStore('hotelRebook:book')
export const $externalAccount = createStore('hotelRebook:externalAccount')
