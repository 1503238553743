import React, { useState, useEffect, useRef } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Card, Input, Switch, notification } from 'antd'
import axios from 'axios'
import _ from 'lodash'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'

import FormDialog from '../../components/FormDialog'
import AirlineList from '../../components/AirlineList'

const PRODUCT = 'flight'

const columns = [
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name'
    },
    {
        key: 'code',
        dataIndex: 'code',
        title: 'code'
    }
]

function FlightAirline({ form, location, history }) {
    const apiUrl = `${appConfig.apiUrl}/${PRODUCT}`

    const [airlines, setAirlines] = useState([])
    const [currentAirline, setCurrentAirline] = useState({})
    const airlineForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const getAirlines = () => {
        return axios({
            method: 'get',
            url: `${apiUrl}/airlines`,
            headers
        })
            .then(({ data }) => {
                setAirlines(data)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    useEffect(() => {
        getAirlines()
    }, [])

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, vals) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }
                const values = _.cloneDeep(vals)
                const options = {
                    url: `${apiUrl}/airlines`,
                    headers,
                    data: values
                }

                switch (airlineForm.current.formState) {
                    case 'CREATE':
                        options.method = 'post'
                        break
                    case 'UPDATE': {
                        options.method = 'put'
                        options.url += `/${currentAirline.id}`
                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return Promise.resolve('Unknow action')
                }

                if (Object.prototype.hasOwnProperty.call(options.data, 'del_flag')) {
                    options.data.del_flag = Number(!options.data.del_flag)
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description:
                                (airlineForm.current.formState === 'CREATE' ? 'Created' : 'Updated') +
                                ' airline: ' +
                                values.name
                        })
                        getAirlines()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
    }

    const { getFieldDecorator } = form

    const setFormState = (state, values = {}) => {
        const formFields = ['name', 'code', 'del_flag', 'use_custom_config']
        const { custom_config } = values
        if (custom_config) {
            const { use_custom_config } = custom_config
            const fields = _.pick(values, formFields)
            if (typeof use_custom_config === 'boolean') {
                fields.use_custom_config = use_custom_config
            }
            form.setFieldsValue(fields)
        }

        if (['CREATE', 'HIDE'].includes(state)) {
            setCurrentAirline({
                del_flag: false
            })
            form.resetFields()
        } else {
            const fields = _.pick(values, formFields)
            fields.del_flag = !fields.del_flag
            form.setFieldsValue(fields)
        }

        airlineForm.current.setFormState(state)
        setCurrentAirline(values)
    }

    return (
        <div>
            <Card>
                <AirlineList
                    airlines={airlines}
                    product={PRODUCT}
                    columns={columns}
                    location={location}
                    history={history}
                    setFormState={setFormState}
                />
                <FormDialog
                    style={{ minWidth: '540px' }}
                    closeHandler={() => setFormState('HIDE')}
                    saveHandler={onSave}
                    ref={airlineForm}>
                    <Form {...formItemLayout}>
                        <Form.Item label="Name" hasFeedback>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please enter airline name!'
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Code" hasFeedback>
                            {getFieldDecorator('code', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please enter airline code!'
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Active">
                            {getFieldDecorator('del_flag', {
                                initialValue: !currentAirline.del_flag,
                                valuePropName: 'checked'
                            })(<Switch />)}
                        </Form.Item>
                    </Form>
                </FormDialog>
            </Card>
        </div>
    )
}

export default Form.create({ name: 'flight-airline' })(FlightAirline)
