import { compose, hoc, useStore } from '@'
import { $hotelsDuplicate } from '../../stores'
import RemoveDuplicateHotels from '../../actions/RemoveHotelsDuplicate'
import { notification } from 'antd'
import apiErrorHandler from '../../../api-error-handler'

const container = compose(
    hoc(props => {
        const [hotelsDuplicate] = useStore([$hotelsDuplicate])

        const removeDuplicate = async param => {
            try {
                const result = await RemoveDuplicateHotels(param)
                let notiDesc = ''
                result.forEach(element => {
                    notiDesc += `${element.message}`
                })
                notification.success({
                    message: `Success!!!`,
                    description: notiDesc
                })
                return result
            } catch (error) {
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
                return Promise.reject(error.message)
            }
        }

        return {
            removeDuplicate,
            hotelsDuplicate,
            ...props
        }
    })
)

export default container
