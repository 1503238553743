import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { EditOutlined, BranchesOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Switch, Row, Col, Input } from 'antd'

import { Can, Say, check } from './can'
import LogList from './LogList'
import { filterTable } from '@/search'

function AirlineList({ airlines, columns, product, setFormState }) {
    const [flagShowLog, setFlagShowLog] = useState(false)
    const [logs] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [initialAirlines, setInitialAirlines] = useState([])
    const [AirlineList, setAirlineList] = useState([])

    useEffect(() => {
        setInitialAirlines(airlines)
        setAirlineList(airlines)
    }, [airlines])

    const onClickedEdit = airline => {
        setFormState('UPDATE', airline)
    }

    const actionCell = (cell, row) => {
        return (
            <div>
                {['hotel', 'flight', 'tour'].includes(product) ? (
                    <Can resource={`${product}-airline`} perform={'edit'}>
                        <Say yes>
                            <Button ghost type="primary" icon={<EditOutlined />} onClick={() => onClickedEdit(row)} />
                        </Say>
                    </Can>
                ) : (
                    ''
                )}
            </div>
        )
    }

    const closeLog = () => {
        setFlagShowLog(false)
        setModalTitle('')
    }

    const onSearch = e => {
        const searchInput = e.target.value
        const airlineList = filterTable(searchInput, initialAirlines, columns)
        setAirlineList(airlineList)
    }

    const tableColumns = [...columns]

    if (check(`${product}-airline`, 'edit')) {
        tableColumns.push(
            {
                key: 'del_flag',
                dataIndex: 'del_flag',
                title: 'Status',
                className: 'text-center',
                render: cell => <Switch disabled checked={!cell} />
            },
            {
                key: 'dummy',
                dataIndex: 'dummy',
                title: 'Action',
                className: 'text-center',
                render: actionCell
            }
        )
    }

    return (
        <div>
            <Route
                exact
                path={`/${product}/airlines`}
                render={() => {
                    return (
                        <div>
                            <Row>
                                <Col md={16}>
                                    <Can resource={`${product}-airline`} perform={'create'}>
                                        <Say yes>
                                            <Button
                                                type="primary"
                                                icon={<BranchesOutlined />}
                                                onClick={() => setFormState('CREATE')}>
                                                Create
                                            </Button>
                                        </Say>
                                    </Can>
                                </Col>
                                <Col md={8}>
                                    <Input placeholder="Search" onChange={onSearch} />
                                </Col>
                            </Row>
                            <br />
                            <Table dataSource={AirlineList} columns={tableColumns} bordered rowKey="id" />
                        </div>
                    )
                }}
            />

            {/* log modal */}
            <Modal
                visible={flagShowLog}
                onClick={closeLog}
                style={{ minWidth: '840px' }}
                onCancel={() => setFlagShowLog(false)}
                title={modalTitle || ''}
                footer={null}>
                <LogList data={logs} />
            </Modal>
        </div>
    )
}

export default AirlineList
