import axios from 'axios'
import { createAction } from '@'
import { getAuth } from '@misc'
import { $hotelsDuplicate } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetHotelsDuplicate', async params => {
    $hotelsDuplicate.setState(() => [])

    const auth = getAuth()

    const hotelsDuplicateLst = await axios({
        url: `${ENDPOINT}/hotel/mappings/find-duplicate`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...auth },
        data: {
            ids: params.ids,
            percent: params.percent,
            distance: params.distance
        }
    })

    const hotelsDuplicateArr = Object.values(hotelsDuplicateLst.data).map((item, index) => {
        if (item.similarHotels) {
            item.similarHotels.forEach(hotel => {
                hotel.similarity = (hotel.similarity * 100).toFixed(2)
                hotel.distance = hotel.distance.toFixed(2)
            })
        }

        return {
            key: index + 1,
            hotel: item.hotel,
            similar: item.similarHotels.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance)),
            span: item.similarHotels.length
        }
    })

    const newObjs = hotelsDuplicateArr.flatMap(({ key, hotel, similar, span }) => {
        if (similar.length === 0) {
            return { key, hotel, similar: {}, span: 1 }
        }

        return similar.map((similarHotel, index) => ({
            key,
            hotel,
            similar: similar.length === 0 ? { key, hotel, similar: {}, span: 1 } : similarHotel,
            span: index === 0 ? span : 0
        }))
    })

    if (newObjs.length > 0) $hotelsDuplicate.setState(() => newObjs)
})
