import React from 'react'
import axios from 'axios'
import { Table, Card, Select, notification } from 'antd'
import { parseISO } from 'date-fns'
import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'

const { Meta } = Card
const headers = JSON.parse(localStorage.getItem('headers') || '{}')

const tableColumn = [
    {
        key: 'supplier_code',
        dataIndex: 'supplier_code',
        title: 'Supplier'
    },
    {
        key: 'booking_date',
        dataIndex: 'booking_date',
        title: 'Last Booking Date',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => parseISO(a.booking_date) - parseISO(b.booking_date) // we just need to sort by date, so parseISO is ok
    }
]

const airlineTableColumn = [
    {
        key: 'airline_code',
        dataIndex: 'airline_code',
        title: 'Airline'
    },
    {
        key: 'booking_date',
        dataIndex: 'booking_date',
        title: 'Last Booking Date',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => parseISO(a.booking_date) - parseISO(b.booking_date)
    }
]

class BookingRacing extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headers: null,
            lastSupplierBooking: [],
            dataTable: [],
            selectOpt: null
        }
    }

    componentDidMount() {
        this.getData()
    }

    UNSAFE_componentWillReceiveProps({ product }) {
        if (product !== this.props.product) {
            setTimeout(() => this.getData(), 0)
        }
    }

    getData() {
        axios({
            method: 'get',
            url: `${appConfig.apiUrl}/${this.props.product}/bookings/last`,
            headers
        })
            .then(({ data }) => {
                data.forEach((obj, idx) => (obj.id = idx))
                this.setState({ lastSupplierBooking: data })
                this.props.product !== 'flight'
                    ? this.setState({ dataTable: data })
                    : setTimeout(() => this.onChange(this.state.selectOpt), 0)
            })
            .catch(error => {
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
                this.setState({
                    lastSupplierBooking: [],
                    dataTable: []
                })
            })
    }

    onChange = selectOpt => {
        const data = this.state.lastSupplierBooking
        let dataTable
        switch (selectOpt) {
            case 'ota':
                dataTable = data.filter(b => !b.airline_code)
                break
            case 'airline':
                dataTable = data.filter(b => !!b.airline_code)
                break
            default:
                dataTable = data
                break
        }
        this.setState({
            dataTable,
            selectOpt
        })
    }

    render() {
        const { selectOpt, dataTable } = this.state
        const { product } = this.props

        return (
            <div>
                <Card
                    title="Booking Racing"
                    size="small"
                    extra={
                        this.props.product === 'flight' ? (
                            <Select defaultValue="airline" onChange={this.onChange} style={{ minWidth: '100%' }}>
                                <Select.Option value="airline">Airline</Select.Option>
                                <Select.Option value="ota">OTA</Select.Option>
                            </Select>
                        ) : (
                            ''
                        )
                    }>
                    <Meta description="Suppliers have no booking for a long time" />
                    <br />
                    <Table
                        rowKey="id"
                        bordered
                        dataSource={dataTable}
                        columns={product === 'flight' && selectOpt === 'airline' ? airlineTableColumn : tableColumn}
                        pagination={{
                            defaultPageSize: 5,
                            simple: true
                        }}
                    />
                </Card>
            </div>
        )
    }
}

export default BookingRacing
