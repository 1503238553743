import { compose, hoc, useStore } from '@'
import { $amenitiesUnmap, $amenitiesGoQuo } from '../../stores'
import { useForm, useFormLoading } from '@form'
import CreateNewAmenitiesUnmap from '../../actions/CreateNewAmenity'
import GetAmenitiesGoQuo from '../../actions/GetAmenitiesGoQuo'
import { notification } from 'antd'
import apiErrorHandler from '../../../api-error-handler'

const $fieldProps = {
    createType: { rules: [{ required: true, message: 'Please select amenities type' }] },
    createName: { rules: [{ required: true, message: 'Please type proper amenity name' }] }
}

const container = compose(
    hoc(props => {
        const [amenitiesGoQuo, amenitiesUnmap] = useStore([$amenitiesGoQuo, $amenitiesUnmap])
        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                CreateNewAmenitiesUnmap({
                    type: values.createType,
                    name: values.createName
                }).catch(error =>
                    notification.error({
                        message: 'Error!!!',
                        description: apiErrorHandler(error)
                    })
                )
                GetAmenitiesGoQuo({
                    type: values.createType
                }).catch(error =>
                    notification.error({
                        message: 'Error!!!',
                        description: apiErrorHandler(error)
                    })
                )
            }

            return {
                name: 'NewAmenities',
                onSubmit
            }
        }, [])

        const $loading = useFormLoading($form.name)
        return {
            amenitiesGoQuo,
            amenitiesUnmap,
            $props,
            $loading,
            $fieldProps,
            ...props
        }
    })
)

export default container
