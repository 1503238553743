import { useCallback, useEffect, useMemo } from 'react'
import { compose, hoc, useStore } from '@'
import moment from 'moment'
import { useForm, SetFormValues, useFormLoading, useFormValues } from '@form'
import SearchHotels from '../../actions/SearchHotels'
import GetLocation from '../../actions/GetLocation'
import GetUsers from '../../actions/GetUsers'
import { $location, $users } from '../../stores'

const initValue = {
    where: undefined,
    for: [
        moment().add(1, 'month'),
        moment()
            .add(1, 'month')
            .add(3, 'day')
    ],
    rooms: [{ adults: 1 }],
    nationality: undefined
}

const $fieldProps = {
    userId: { rules: [{ required: true, message: 'Please choose an user' }] },
    where: { rules: [{ required: true, message: 'Where are you going?' }] },
    for: { rules: [{ required: true, message: 'For how long?' }] },
    nationality: { rules: [{ required: true, message: 'What is your nationality?' }] }
}

const container = compose(
    hoc(props => {
        const [users, location] = useStore([$users, $location])

        useEffect(() => {
            if (users) {
                return
            }
            GetUsers()
        }, [users])

        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                await SearchHotels(values)
            }

            return {
                onSubmit,
                name: 'SearchForm'
            }
        }, [])

        const $loading = useFormLoading($form.name)

        const $values = useFormValues($form.name)

        useEffect(() => {
            SetFormValues('SearchForm', initValue)
        }, [])

        const suggestions = useMemo(() => {
            return location || []
        }, [location])

        const handleSuggestion = useCallback(value => {
            GetLocation(value).catch(console.error)
        }, [])

        const handleAddRoom = useCallback(() => {
            SetFormValues('SearchForm', s => ({
                ...s,
                rooms: [...((s && s.rooms) || []), { adults: 1 }]
            }))
        }, [])

        const handleRemoveRoom = useCallback(room => {
            SetFormValues('SearchForm', s => ({
                ...s,
                rooms: ((s && s.rooms) || []).filter(i => i !== room)
            }))
        }, [])

        const handleAddChild = useCallback(rid => {
            SetFormValues('SearchForm', s => ({
                ...s,
                rooms: ((s && s.rooms) || []).reduce((arr, room, idx) => {
                    if (idx === rid) {
                        arr.push({ ...room, children: [...((room && room.children) || []), { age: 10 }] })
                    } else {
                        arr.push(room)
                    }
                    return arr
                }, [])
            }))
        }, [])

        const handleRemoveChild = useCallback((rid, child) => {
            SetFormValues('SearchForm', s => ({
                ...s,
                rooms: ((s && s.rooms) || []).reduce((arr, room, idx) => {
                    if (idx === rid) {
                        arr.push({ ...room, children: ((room && room.children) || []).filter(i => i !== child) })
                    } else {
                        arr.push(room)
                    }
                    return arr
                }, [])
            }))
        }, [])

        const handleAddInfant = useCallback(rid => {
            SetFormValues('SearchForm', s => ({
                ...s,
                rooms: ((s && s.rooms) || []).reduce((arr, room, idx) => {
                    if (idx === rid) {
                        arr.push({ ...room, infants: [...((room && room.infants) || []), { age: 10 }] })
                    } else {
                        arr.push(room)
                    }
                    return arr
                }, [])
            }))
        }, [])

        const handleRemoveInfant = useCallback((rid, infant) => {
            SetFormValues('SearchForm', s => ({
                ...s,
                rooms: ((s && s.rooms) || []).reduce((arr, room, idx) => {
                    if (idx === rid) {
                        arr.push({ ...room, infants: ((room && room.infants) || []).filter(i => i !== infant) })
                    } else {
                        arr.push(room)
                    }
                    return arr
                }, [])
            }))
        }, [])

        return {
            $props,
            $form,
            $loading,
            $values,
            $fieldProps,
            users,
            suggestions,
            handleSuggestion,
            handleAddRoom,
            handleRemoveRoom,
            handleAddChild,
            handleRemoveChild,
            handleAddInfant,
            handleRemoveInfant,
            ...props
        }
    })
)

export default container
