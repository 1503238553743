import React, { useMemo } from 'react'
import { Card, Table, Pagination, Row, Col, Switch, Button, Modal } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Root } from './styled'
import container from './container'
import UpdateActiveAlert from '../../actions/UpdateActiveAlert'
import DeleteAlert from '../../actions/DeleteAlert'
const { confirm } = Modal

const AlertSettingList = props => {
    const openConfirmationModal = (record, value) => {
        confirm({
            title: 'Are you sure delete this setting?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be reverted',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                onRecordDeleted(record, value)
            },
            onCancel() {}
        })
    }
    const { alertSettings } = props

    function onActiveChanged(record, value) {
        UpdateActiveAlert(record, value)
    }
    function onRecordDeleted(record, value) {
        DeleteAlert(record, value)
    }

    const columns = useMemo(() => {
        return [
            {
                title: 'Project',
                dataIndex: 'project',
                key: 'project'
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email'
            },
            {
                title: '',
                dataIndex: 'isActive',
                key: 'isActive',
                render(text, record) {
                    return <Switch checked={record.isActive} onChange={value => onActiveChanged(record, value)} />
                }
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                render(text, record) {
                    return (
                        <Button
                            icon={<DeleteOutlined />}
                            danger
                            onClick={value => openConfirmationModal(record, value)}></Button>
                    )
                }
            }
        ]
    }, [])

    return (
        <Root>
            <Card size={'small'} title={'Alert Settings List'}>
                <Table
                    bordered
                    size="small"
                    rowKey="id"
                    columns={columns}
                    pagination={false}
                    dataSource={alertSettings}
                />
                <br />
                <Row type="flex" justify="end">
                    <Col>
                        <Pagination pageSizeOptions={['5', '10', '25', '50', '100']} showSizeChanger showQuickJumper />
                    </Col>
                </Row>
            </Card>
        </Root>
    )
}

export default container(AlertSettingList)
