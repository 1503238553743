import { createAction } from '@'
import { $accounts } from '../stores'
import axios from 'axios'
import { getAuth } from '../../@misc'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetAccounts', async selectedProject => {
    $accounts.setState(() => undefined)
    const auth = getAuth()
    if (selectedProject === undefined) {
        return
    }
    let res
    switch (selectedProject) {
        default:
            res = await axios({
                url: `${ENDPOINT}/${selectedProject}/users`,
                method: 'GET',
                headers: { 'Content-Type': 'application/json', ...auth }
            })
    }

    const accounts = res.data.map(function(d) {
        return {
            label: `${d.email}`,
            value: `${d.email}|${d.id}`,
            id: d.email
        }
    })

    $accounts.setState(() => accounts)
})
