import { createAction } from '@'
import { getAuth, loginErrorHandle } from '@misc'
import axios from 'axios'
import { $resourcePermissions } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetResourcePermissions', async () => {
    try {
        const auth = getAuth()

        const res = await axios({
            url: `${ENDPOINT}/resource-permissions`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...auth }
        })

        $resourcePermissions.setState(() => (res && res.data) || [])
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }
})
