import axios from 'axios'
import { createAction } from '@'
import { getAuth, loginErrorHandle } from '../../@misc'
import { $codeSuggestions } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetViatorDestinationCode', async value => {
    const auth = getAuth()

    $codeSuggestions.setState(() => undefined)

    window.__mappingCodeSuggestion = value

    try {
        const res = await axios({
            url: `${ENDPOINT}/proxy`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...auth },
            data: {
                url: `https://www.viator.com/orion/ajax/typeahead?text=${value}&destId=&callingServlet=homepage`,
                method: 'GET'
            }
        })

        if (value !== window.__mappingCodeSuggestion) {
            return
        }

        const div = document.createElement('div')
        div.innerHTML = res.data.body.trim()

        const results = [...div.querySelectorAll('.destination-results .typeahead-result ')].map(item => {
            const code = item.getAttribute('data-destinationid')
            return {
                text: `${item.innerText} - (${code})`,
                value: code
            }
        })

        $codeSuggestions.setState(() => results)
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }
})
