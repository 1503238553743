import React from 'react'
import container from './container'
import { Card, Button, Select, Spin, Form, Row, Col, Input } from 'antd'
import { PlusSquareOutlined } from '@ant-design/icons'

const amenitiesTypes = ['hotel', 'room']

const CreateNewAmenitiesMappingForm = props => {
    const { $props, $loading, $fieldProps } = props
    return (
        <Spin spinning={$loading}>
            <Form colon={false} {...$props}>
                <Card
                    title="Create New Amenity"
                    size="small"
                    extra={
                        <Button htmlType={'submit'} type="primary">
                            <PlusSquareOutlined /> Create
                        </Button>
                    }>
                    <div className="d-md-flex align-items-center">
                        <Card.Meta description="Create a new GoQuo amenities." />
                    </div>
                    <br />
                    <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <Form.Item name={'createType'} wrapperCol={{ flex: 1 }} {...$fieldProps.createType}>
                                <Select placeholder="Select amenities type" showSearch style={{ width: '100%' }}>
                                    {amenitiesTypes.map(type => (
                                        <Select.Option value={type} key={type}>
                                            {type}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={16}>
                            <Form.Item name={'createName'} wrapperCol={{ flex: 1 }} {...$fieldProps.createName}>
                                <Input placeholder={'Type new amenity'} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Spin>
    )
}

export default container(CreateNewAmenitiesMappingForm)
