import { createAction } from '@'
import Fetch from './Fetch'
import { SetFormValues } from '@form'
import { $searchInfo, $selectedHotel, $prebook, $searchRequest } from '../stores'

const ENDPOINT = process.env.REACT_APP_OFFER_ENDPOINT

const holder = {
    title: 'Mr',
    firstName: 'GoQuo',
    lastName: 'Reservations',
    email: 'reservations@goquo.com',
    mobile: '0060122226091',
    address: 'Medan Syed Putra Utara',
    city: 'Kuala Lumpur',
    country: 'Malaysia',
    country_code: 'MY',
    postal: '59200'
}

export default createAction('Book', async values => {
    $prebook.setState(() => undefined)
    const searchInfo = $searchInfo.getState()
    if (!searchInfo) {
        return
    }

    const selectedHotel = $selectedHotel.getState()

    const res = await Fetch({
        url: `${ENDPOINT}/prebook`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-key': searchInfo.email,
            'x-access-token': searchInfo.token
        },
        data: {
            request_id: searchInfo.requestId,
            hotel_id: selectedHotel.goquo_id,
            rooms: values.rooms.map(i => JSON.parse(i.room))
        }
    })

    const searchRequest = $searchRequest.getState()

    SetFormValues('BookingPage', () => ({
        request_id: res.request_id,
        client_ref: '',
        rooms: searchRequest.rooms.map(room => {
            const renderList = []
            ;[...Array(room.adults).keys()].forEach(() => {
                renderList.push({
                    type: 'adult'
                })
            })
            ;(room.children || []).forEach(child => {
                renderList.push({
                    age: child.age,
                    type: 'child'
                })
            })
            ;(room.infants || []).forEach(infant => {
                renderList.push({
                    age: infant.age,
                    type: 'infant'
                })
            })

            return {
                idx: room.idx,
                paxes: renderList.map(i => ({
                    title: '',
                    first_name: '',
                    last_name: '',
                    age: i.age,
                    type: i.type,
                    nationality: searchRequest.nationality
                }))
            }
        }),
        holder: {
            title: holder.title,
            first_name: holder.firstName,
            last_name: holder.lastName,
            email: holder.email,
            mobile: holder.mobile,
            city: holder.city,
            address: holder.address,
            postal_code: holder.postal
        }
    }))

    $prebook.setState(() => res)
})
