import { Form } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useFormValues from './useFormValues'
import UpdateFormValues from '../actions/UpdateFormValues'
import { $formLoading } from '../stores'

const FakeInput = () => null
const Item = props => {
    return (
        <Form.Item noStyle {...props}>
            <FakeInput />
        </Form.Item>
    )
}

export default function useForm(callback = () => ({}), deps = []) {
    const [formRef] = Form.useForm()

    const [form] = useState(formRef)

    const { name, onSubmit, props } = useMemo(() => callback({ form }), deps)

    const values = useFormValues(name)

    useEffect(() => {
        if (!values) {
            UpdateFormValues(name, form.getFieldsValue())
            return
        }
        const formFieldsValue = form.getFieldsValue()
        const isSameValue = JSON.stringify(values) === JSON.stringify(formFieldsValue)
        if (!isSameValue) {
            form.resetFields()
            form.setFieldsValue(values)
            UpdateFormValues(name, form.getFieldsValue())
        }
    }, [name, form, values])

    const onValuesChange = useCallback(() => {
        UpdateFormValues(name, form.getFieldsValue())
    }, [name, form])

    const onFinish = useCallback(
        params => {
            const submit = async () => {
                if (!onSubmit) {
                    return
                }
                $formLoading.setState(s => ({ ...s, [name]: true }))
                await onSubmit(params)
            }
            submit()
                .catch(console.error)
                .finally(() => {
                    $formLoading.setState(s => ({ ...s, [name]: false }))
                })
        },
        [name, onSubmit]
    )

    const $props = useMemo(() => {
        return { form, onValuesChange, onFinish, ...props }
    }, [form, onValuesChange, onFinish, props])

    const $form = useMemo(() => {
        return { name, Item, submit: form.submit }
    }, [name, form])

    return [$props, $form]
}
