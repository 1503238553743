import React from 'react'
import { Card, Table } from 'antd'
import _ from 'lodash'
import RegionMappingForm from '../RegionMappingForm'
import container from './container'

const { Meta } = Card

const columns = [
    {
        key: 'region_id',
        dataIndex: 'region_id',
        title: 'Region ID'
    },
    {
        key: 'supplier_code',
        dataIndex: 'supplier_code',
        title: 'Supplier'
    },
    {
        key: 'region_name',
        dataIndex: 'region_name',
        title: 'Region Name'
    },
    {
        key: 'country_code',
        dataIndex: 'country_code',
        title: 'Country Code'
    },
    {
        key: 'source_dest_code',
        dataIndex: 'source_dest_code',
        title: 'S.Dest Code'
    },
    {
        key: 'source_dest_name',
        dataIndex: 'source_dest_name',
        title: 'S.Dest Name'
    },
    {
        key: 'source_country_code',
        dataIndex: 'source_country_code',
        title: 'S.Country Code'
    }
]

const DestinationMapping = props => {
    let { regions } = props

    regions = _.sortBy(regions, r => Number(r.region_id), 'supplier_code')

    return (
        <div>
            <RegionMappingForm />
            <br />
            <Card>
                <Meta description="Click into the plus square to see the mapping with other suppliers" />
                <br />
                <Table bordered rowKey={(item, idx) => idx} dataSource={regions} columns={columns} />
            </Card>
        </div>
    )
}

export default container(DestinationMapping)
