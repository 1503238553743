import React from 'react'
import { Card, Form, Select, Button, Spin, Row, Col } from 'antd'
import container from './container'

const AlertSettingSetup = props => {
    const { projects, accounts, $props, $loading, $fieldProps } = props
    const handleSelectedProjectChange = value => {
        console.log(value)
    }
    return (
        <Spin spinning={$loading}>
            <Form {...$props} layout={'inline'}>
                <Card
                    size={'small'}
                    title={'Add Alert'}
                    style={{ flex: 1 }}
                    extra={
                        <Row type={'flex'} gutter={8} align={'middle'}>
                            <Col>
                                <Button htmlType={'submit'} type={'primary'}>
                                    Add
                                </Button>
                            </Col>
                        </Row>
                    }>
                    <Row>
                        <Form.Item name={'project'} label={'Project'} {...$fieldProps.project}>
                            <Select
                                disabled={!projects}
                                loading={!projects}
                                style={{ width: 200 }}
                                placeholder={'Please select a project'}>
                                {(projects || []).map(item => (
                                    <Select.Option key={item.id} value={item.value}>
                                        {item.label}
                                    </Select.Option>
                                ))}
                                onChange={value => handleSelectedProjectChange(value)}
                            </Select>
                        </Form.Item>
                        <Form.Item name={'account'} label={'Account'} {...$fieldProps.account}>
                            <Select
                                disabled={!accounts}
                                loading={!accounts}
                                style={{ width: 200 }}
                                optionFilterProp="value"
                                showSearch
                                filterOption={(input, option) =>
                                    option.value.toLowerCase().includes(input.toLowerCase())
                                }
                                placeholder={'Please select an Account'}>
                                {(accounts || []).map(item => (
                                    <Select.Option key={item.id} value={item.value}>
                                        {item.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Row>
                </Card>
            </Form>
        </Spin>
    )
}

export default container(AlertSettingSetup)
