import { useEffect, useMemo } from 'react'
import { compose, hoc, useStore } from '@'
import { useForm, useFormLoading } from '@form'
import { message } from 'antd'
import GetSuppliers from '../../actions/GetSuppliers'
import GetRoom from '../../actions/GetRoom'
import Prebook from '../../actions/Prebook'
import GetHotelInfo from '../../actions/GetHotelInfo'
import { $step, $hotelMap, $roomMap, $selectedHotel, $suppliers, $externalAccount } from '../../stores'

const $fieldProps = {
    room: {
        rules: [{ required: true, message: 'Please select a room!' }]
    }
}

const container = compose(
    hoc(props => {
        const [suppliers, selectedHotel, hotelMap, roomMap, externalAccount] = useStore([
            $suppliers,
            $selectedHotel,
            $hotelMap,
            $roomMap,
            $externalAccount
        ])

        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                await Prebook(values)
                $step.setState(() => 2)
            }

            const onFinishFailed = async ({ errorFields }) => {
                const roomError = errorFields.find(i => i.name && i.name.indexOf('room') > -1)
                if (roomError) {
                    message.warn('Please select a room!', 10)
                }
            }

            return {
                name: 'HotelDetailsPage',
                onSubmit,
                props: {
                    onFinishFailed
                }
            }
        }, [])

        const $loading = useFormLoading($form.name)

        const hotelInfo = useMemo(() => {
            return (selectedHotel && hotelMap && hotelMap[selectedHotel.goquo_id]) || {}
        }, [selectedHotel, hotelMap])

        const roomInfo = useMemo(() => {
            return selectedHotel && roomMap && roomMap[selectedHotel.goquo_id]
        }, [selectedHotel, roomMap])

        const supplierMap = useMemo(() => {
            if (!suppliers) {
                return {}
            }
            const map = {}
            suppliers.forEach(i => {
                map[i.contract_id] = i
            })
            return map
        }, [suppliers])

        const roomList = useMemo(() => {
            if (!roomInfo) {
                return []
            }

            const room = {}
            roomInfo.forEach(({ source_id, rooms }) => {
                rooms.forEach(r => {
                    room[r.idx] = room[r.idx] || []
                    room[r.idx].push({
                        ...r,
                        source_id
                    })
                })
            })

            const list = Object.values(room)

            list.forEach(rooms => {
                rooms.sort((a, b) => {
                    return Math.min(...a.rates.map(i => i.price)) - Math.min(...b.rates.map(i => i.price))
                })
            })

            return list
        }, [roomInfo])

        useEffect(() => {
            if (suppliers) {
                return
            }
            GetSuppliers()
        }, [suppliers])

        useEffect(() => {
            if (!selectedHotel) {
                return
            }
            GetRoom(selectedHotel)
        }, [selectedHotel])

        useEffect(() => {
            if (!roomInfo || !roomInfo[0]) {
                return
            }
            GetHotelInfo(roomInfo[0])
        }, [roomInfo])

        return {
            $props,
            $form,
            $loading,
            $fieldProps,
            externalAccount,
            selectedHotel,
            supplierMap,
            hotelInfo,
            roomInfo,
            roomList,
            ...props
        }
    })
)

export default container
