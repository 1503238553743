import { createAction } from '@'
import axios from 'axios'
import { getAuth, fetchErrorHandler, loginErrorHandle } from '@misc'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GenToken', async ({ id, email } = {}) => {
    const auth = getAuth()
    try {
        const res = await axios({
            url: `${ENDPOINT}/hotel/generate-token`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...auth },
            data: {
                id: `${id}`,
                email
            }
        })

        return fetchErrorHandler(res)
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }
})
