import { createAction } from '@'
import { getAuth, loginErrorHandle } from '@misc'
import axios from 'axios'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('CreateRole', async params => {
    const auth = getAuth()

    try {
        await axios({
            url: `${ENDPOINT}/roles`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...auth },
            data: {
                id: params.id,
                description: params.description,
                permissions: params.permissions
            }
        })
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }

    if (params.onSuccess) {
        await params.onSuccess()
    }
})
