import { useCallback, useEffect, useMemo, useState } from 'react'
import { compose, hoc, useStore } from '@'
import JSZip from 'jszip'
import { $tableData, $expandedRows, $filter } from '../../stores'
import ExportFile from '../../actions/ExportFile'

const container = compose(
    hoc(props => {
        const [downloadLink, setDownloadLink] = useState(null)

        useEffect(() => {
            $filter.setState(() => ({ type: 'all' }))
        }, [])

        const [tableData, expandedRowKeys, filter] = useStore([$tableData, $expandedRows, $filter])

        const expandAll = useCallback(() => {
            $expandedRows.setState(() => (tableData || []).map(i => i.key))
        }, [tableData])

        const collapseAll = useCallback(() => {
            $expandedRows.setState(() => [])
        }, [])

        const handleExpand = useCallback((expanded, item) => {
            $expandedRows.setState(s =>
                expanded ? [...(s || []), item.key] : (s || []).filter(key => key !== item.key)
            )
        }, [])

        const handleTypeChange = useCallback(event => {
            const { value } = event.target
            $filter.setState(s => ({ ...s, type: value }))
        }, [])

        const dataSource = useMemo(() => {
            if (tableData && filter && filter.type === 'one') {
                return tableData.filter(row => row.children.length === 1)
            }
            if (tableData && filter && filter.type === 'many') {
                return tableData.filter(row => row.children.length > 1)
            }
            return tableData
        }, [tableData, filter])

        useEffect(() => {
            if (!dataSource || !dataSource.length) {
                setDownloadLink(null)
                return
            }
            const file = ExportFile(dataSource)

            const zip = new JSZip()
            zip.file(`results-${Date.now()}.csv`, file)
            zip.generateAsync({ type: 'blob' })
                .then(blob => {
                    const url = window.URL.createObjectURL(blob)
                    setDownloadLink(url)
                })
                .catch(console.error)
        }, [dataSource])

        return {
            filter,
            handleTypeChange,
            expandAll,
            collapseAll,
            dataSource,
            downloadLink,
            expandedRowKeys,
            handleExpand,
            ...props
        }
    })
)

export default container
