import { useEffect, useState } from 'react'
import { compose, hoc, useStore } from '@'
import { $logs } from '../../stores'

const container = compose(
    hoc(props => {
        const [logs] = useStore([$logs])
        const [downloadLink, setDownloadLink] = useState(null)
        useEffect(() => {
            if (!logs || !logs.length) {
                setDownloadLink(null)
                return
            }
        }, [logs])
        return {
            logs,
            downloadLink,
            ...props
        }
    })
)

export default container
