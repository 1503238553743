import { compose, hoc } from '@'
import { useCallback } from 'react'
import SetModalVisible from '../../actions/SetModalVisible'

const container = compose(
    hoc(props => {
        const { name, visible } = props
        const handleClick = useCallback(
            event => {
                event.preventDefault()
                SetModalVisible(name, visible)
            },
            [name, visible]
        )
        return {
            handleClick,
            ...props
        }
    })
)

export default container
