import { compose, hoc } from '@'
import { useForm, useFormLoading } from '@form'
import GetAmenitiesUnmap from '../../actions/GetAmenitiesUnmap'
import GetAmenitiesGoQuo from '../../actions/GetAmenitiesGoQuo'
import { notification } from 'antd'
import apiErrorHandler from '../../../api-error-handler'

const $fieldProps = {
    type: { rules: [{ required: true, message: 'Please select amenities type' }] },
    provider: { rules: [{ required: true, message: 'Please select a provider' }] }
}

const container = compose(
    hoc(props => {
        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                GetAmenitiesGoQuo({
                    type: values.type
                })
                GetAmenitiesUnmap({
                    type: values.type,
                    provider: values.provider
                }).catch(error =>
                    notification.error({
                        message: 'Error!!!',
                        description: apiErrorHandler(error)
                    })
                )
            }

            return {
                name: 'AmenitiesUnmap',
                onSubmit
            }
        }, [])

        const $loading = useFormLoading($form.name)
        return {
            $props,
            $loading,
            $fieldProps,
            ...props
        }
    })
)

export default container
