import React from 'react'
import { Form, Carousel, Descriptions, Rate, Tag, Button, Radio, Card, Spin, Row, Col } from 'antd'
import { Root } from './styled'
import container from './container'

const HotelDetailsPage = props => {
    const { supplierMap, hotelInfo, roomList, externalAccount, $props, $loading, $fieldProps } = props
    const images = (hotelInfo && hotelInfo.images) || []
    const amenities = (hotelInfo && hotelInfo.amenities) || []
    return (
        <Root>
            <Spin spinning={$loading}>
                <Form {...$props}>
                    <Row type={'flex'} gutter={16}>
                        <Col style={{ flex: 1 }}>
                            <h2>
                                {hotelInfo.name} <Rate value={hotelInfo.star_rating || 0} disabled />
                            </h2>
                            <Descriptions size={'small'} column={1}>
                                <Descriptions.Item label={'City'}>{hotelInfo.city}</Descriptions.Item>
                                <Descriptions.Item label={'Address'}>{hotelInfo.address}</Descriptions.Item>
                                <Descriptions.Item label={'Phone'}>{hotelInfo.phone}</Descriptions.Item>
                                <Descriptions.Item label={'Fax'}>{hotelInfo.fax}</Descriptions.Item>
                                <Descriptions.Item label={'Email'}>{hotelInfo.email}</Descriptions.Item>
                                <Descriptions.Item label={'Check out'}>{hotelInfo.checkout}</Descriptions.Item>
                                <Descriptions.Item label={'Website'}>
                                    {
                                        <Button type={'link'} href={hotelInfo.website} target={'_blank'}>
                                            Open on new tab
                                        </Button>
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col style={{ flex: 1 }}>
                            <Carousel dots={false} autoplay style={{ display: 'block', width: 600, height: 300 }}>
                                {images.map((item, idx) => (
                                    <div key={idx}>
                                        <img
                                            width={600}
                                            height={300}
                                            src={item.url}
                                            style={{ objectFit: 'cover' }}
                                            alt=""
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        </Col>
                    </Row>
                    <br />
                    <Card
                        title={'Select Room'}
                        size={'small'}
                        extra={
                            !externalAccount ? (
                                <Button type={'primary'} htmlType={'submit'} disabled={!roomList || !roomList.length}>
                                    Continue
                                </Button>
                            ) : null
                        }>
                        {roomList.map((rooms, ridx) => {
                            return (
                                <Form.Item
                                    name={['rooms', ridx, 'room']}
                                    key={ridx}
                                    style={{ margin: 0 }}
                                    {...$fieldProps.room}>
                                    <Radio.Group style={{ width: '100%' }}>
                                        <Card size={'small'} title={`Room ${ridx + 1}`}>
                                            <div className={'zibra1'}>
                                                {rooms.map((room, roidx) => (
                                                    <Row
                                                        key={roidx}
                                                        type={'flex'}
                                                        align={'middle'}
                                                        justify={'space-between'}
                                                        gutter={16}>
                                                        <Col style={{ width: 300 }}>
                                                            <strong>{room.room_name}</strong>
                                                        </Col>
                                                        <Col style={{ flex: 1 }}>
                                                            {room.rates.map((rate, raidx) => (
                                                                <label
                                                                    key={raidx}
                                                                    className={'zibra1'}
                                                                    style={{ cursor: 'pointer' }}>
                                                                    <Row
                                                                        type={'flex'}
                                                                        gutter={16}
                                                                        style={{ padding: 5 }}>
                                                                        <Col>
                                                                            <Radio
                                                                                value={JSON.stringify({
                                                                                    idx: room.idx,
                                                                                    room_type_id: room.room_type_id,
                                                                                    rate_detail: rate.rate_detail
                                                                                })}
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            style={{
                                                                                width: 80,
                                                                                textAlign: 'right'
                                                                            }}>
                                                                            <strong>{rate.price.toFixed(2)}</strong>
                                                                        </Col>
                                                                        <Col>
                                                                            <i>{rate.currency}</i>
                                                                        </Col>
                                                                        <Col style={{ width: 130 }}>
                                                                            <strong>{rate.board_basis}</strong>
                                                                        </Col>
                                                                        <Col>
                                                                            {!externalAccount &&
                                                                            supplierMap &&
                                                                            supplierMap[rate.contract_id] ? (
                                                                                <i>
                                                                                    {supplierMap[rate.contract_id].name}
                                                                                </i>
                                                                            ) : null}
                                                                        </Col>
                                                                        <Col>
                                                                            {(rate.promos || []).map((promo, pidx) => (
                                                                                <div key={pidx}>{promo.name}</div>
                                                                            ))}
                                                                        </Col>
                                                                    </Row>
                                                                </label>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>
                                        </Card>
                                    </Radio.Group>
                                </Form.Item>
                            )
                        })}
                    </Card>
                    <br />
                    {amenities.length ? (
                        <div>
                            <h3>Amenities</h3>
                            {amenities.map((item, aidx) => (
                                <Tag key={aidx}>{item.name}</Tag>
                            ))}
                            <br />
                        </div>
                    ) : null}

                    <br />
                    {hotelInfo.checkin_instructions ? (
                        <div>
                            <h3>Check In Instructions</h3>
                            <div dangerouslySetInnerHTML={{ __html: hotelInfo.checkin_instructions }}></div>
                            <br />
                        </div>
                    ) : null}
                    {hotelInfo.special_checkin_instructions ? (
                        <div>
                            <h3>Special Check In Instructions</h3>
                            <div dangerouslySetInnerHTML={{ __html: hotelInfo.special_checkin_instructions }}></div>
                            <br />
                        </div>
                    ) : null}
                    {hotelInfo.description ? (
                        <div>
                            <h3>Description</h3>
                            <div dangerouslySetInnerHTML={{ __html: hotelInfo.description }}></div>
                            <br />
                        </div>
                    ) : null}
                </Form>
            </Spin>
        </Root>
    )
}

export default container(HotelDetailsPage)
