export default [
    'GoQuo',
    'Agoda',
    'APItude',
    'CNBooking',
    'CUG',
    'Darina',
    'Dida',
    'DOTW',
    'EAN',
    'EPS',
    'FIT',
    'Getaroom',
    'GIATA',
    'GTA',
    'HMS',
    'HotelsPro',
    'Innstant',
    'JacTravel',
    'Jalan',
    'Marriott',
    'MG',
    'Miki',
    'NTA',
    'PriceLine',
    'Quantum',
    'RateHawk',
    'RoomsXML',
    'Smyrooms',
    'TBO',
    'Traveloka',
    'TripAdvisor',
    'Zumata',
    'Rotana'
]
