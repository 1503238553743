import { compose, hoc, useStore } from '@'
import { $hotelMappings, $hotelMappingFrom, $hotelInfoMapping } from '../../stores'

const container = compose(
    hoc(props => {
        const [hotelMappings, hotelMappingFrom, hotelInfoMapping] = useStore([
            $hotelMappings,
            $hotelMappingFrom,
            $hotelInfoMapping
        ])

        return {
            hotelMappings,
            hotelMappingFrom,
            hotelInfoMapping,
            ...props
        }
    })
)

export default container
