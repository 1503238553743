import React, { useMemo } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Button, Card, Row, Col, Table } from 'antd'
import { UnControlled as CodeMirror } from 'react-codemirror2'
// import ReactJson from 'react-json-view'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/mode/xml/xml'
import 'codemirror/mode/javascript/javascript'
import { Root } from './styled'
import container from './container'
import prettyData from 'pretty-data'
import ReactJson from 'react-json-view'

const transformData = (request, response) => {
    let requestRender = () => null
    let responseRender = () => null

    if (request && typeof request === 'object') {
        requestRender = () => (
            <ReactJson
                src={request}
                theme="monokai"
                iconStyle="square"
                name={false}
                displayDataTypes={false}
                collapsed={3}
                collapseStringsAfterLength={30}
            />
        )
    }

    if (request && request[0] === '<') {
        requestRender = () => (
            <CodeMirror
                value={`${prettyData.pd.xml(request)}`}
                options={{
                    mode: 'xml',
                    theme: 'material',
                    lineNumbers: true
                }}
            />
        )
    }

    if (response && typeof response === 'object') {
        responseRender = () => (
            <ReactJson
                src={response}
                theme="monokai"
                iconStyle="square"
                name={false}
                displayDataTypes={false}
                collapsed={3}
                collapseStringsAfterLength={30}
            />
        )
    }

    if (response && response[0] === '<') {
        responseRender = () => (
            <CodeMirror
                value={`${prettyData.pd.xml(response)}`}
                options={{
                    mode: 'xml',
                    theme: 'material',
                    lineNumbers: true
                }}
            />
        )
    }

    return (
        <>
            {requestRender()}
            <br />
            {responseRender()}
        </>
    )
}

const LogViewer = props => {
    const { logs, downloadLink } = props
    const columns = useMemo(() => {
        return [
            {
                title: 'Time',
                key: 'time',
                render(log) {
                    return new Date(log.time * 1000).toISOString()
                    // return new Date(10 * 1000).toISOString()
                }
            },
            {
                title: 'Supplier',
                dataIndex: 'supplier',
                key: 'supplier'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            }
        ]
    }, [])

    return (
        <Root>
            <Card
                size={'small'}
                title={'Result'}
                extra={
                    <Row type={'flex'} gutter={16} align={'middle'}>
                        <Col>
                            {downloadLink ? (
                                <Button
                                    type={'primary'}
                                    icon={<DownloadOutlined />}
                                    href={downloadLink}
                                    target={'blank'}
                                    download={'log.zip'}>
                                    Download (.zip)
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                }>
                <Table
                    size={'small'}
                    bordered
                    columns={columns}
                    dataSource={logs || []}
                    expandedRowRender={log => {
                        return transformData(log.request, log.response)
                    }}
                    rowKey={'time'}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000']
                    }}
                />
            </Card>
        </Root>
    )
}

export default container(LogViewer)
