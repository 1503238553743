import React from 'react'
import { useState } from 'react'
import container from './container'
import { Table, Button, Select } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import AmenitiesMappingForm from '../AmenitiesMappingForm'
import CreateNewAmenitiesMappingForm from '../CreateNewAmenitiesForm'

const DestinationMapping = props => {
    const { amenitiesUnmap, amenitiesType, amenitiesProvider, amenitiesGoQuo, saveAmenities } = props

    const [value, setValue] = useState('')

    function handle(record, value) {
        record.goquo_id = value
        record.type = amenitiesType
        record.provider = amenitiesProvider
        console.log(record)
        saveAmenities(record)
    }

    const columns = [
        {
            key: 'code',
            dataIndex: 'code',
            title: 'Amenities Code'
        },
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Amenities Name'
        },
        {
            key: 'gq_id',
            dataIndex: 'gq_id',
            title: 'GoQuo Id',
            render: () => (
                <Select
                    placeholder="Select amenities type"
                    showSearch
                    style={{ width: '100%' }}
                    onChange={val => {
                        setValue(val)
                    }}>
                    {amenitiesGoQuo.map(amenity => (
                        <Select.Option value={amenity.id} key={amenity.id}>
                            {amenity.title}
                        </Select.Option>
                    ))}
                </Select>
            )
        },
        {
            key: 'act',
            dataIndex: 'act',
            title: '',
            render: (text, record) => (
                <Button id={record.id} onClick={() => handle(record, value)}>
                    <SaveOutlined /> Save
                </Button>
            )
        }
    ]

    return (
        <div>
            <div style={{ display: 'flex', margin: '10px' }}>
                <div style={{ flex: 2, marginRight: '5px' }}>
                    <AmenitiesMappingForm />
                </div>
                <div style={{ flex: 1, marginLeft: '5px' }}>
                    <CreateNewAmenitiesMappingForm />
                </div>
            </div>
            <div style={{ margin: '10px' }}>
                <Table bordered rowKey={(item, idx) => idx} dataSource={amenitiesUnmap} columns={columns} />
            </div>
        </div>
    )
}

export default container(DestinationMapping)
