import React, { useCallback, useEffect, useMemo } from 'react'
import { useStore } from '@'
import SetTableData from '../actions/SetTableData'
import SetTableFilter from '../actions/SetTableFilter'
import SetTableLoading from '../actions/SetTableLoading'
import { $tableFilter, $tableData, $tableLoading } from '../stores'

export default function withTable(name, callback) {
    return Com => {
        return props => {
            const { defaultFilter, extraFilter, fetch = async () => ({ rows: null, count: 0 }) } = callback(props)

            const [data, filter, loading] = useStore([
                $tableData.subStore(name),
                $tableFilter.subStore(name),
                $tableLoading.subStore(name)
            ])

            useEffect(() => {
                if (!defaultFilter) {
                    return
                }
                SetTableFilter(name, {
                    ...defaultFilter
                })
            }, [defaultFilter])

            useEffect(() => {
                if (!filter) {
                    return
                }
                SetTableLoading(name, true)
                fetch({
                    ...filter,
                    ...extraFilter
                }).then(
                    res => {
                        if (res.rows && res.rows.length === 0 && res.count > 0) {
                            SetTableFilter(name, {
                                offset: Math.max(0, (Math.floor(res.count / filter.limit) - 1) * filter.limit)
                            })
                            return
                        }
                        SetTableLoading(name, false)
                        SetTableData(name, res)
                    },
                    err => {
                        SetTableLoading(name, false)
                        console.error(err)
                    }
                )
            }, [filter, extraFilter, fetch])

            const handleScroll = useCallback(() => {
                fetch(
                    {
                        ...filter,
                        ...extraFilter
                    },
                    data
                )
                    .then(res => {
                        SetTableData(name, res)
                    })
                    .catch(console.error)
            }, [filter, extraFilter, fetch, data])

            const setFilter = useCallback(newFilter => {
                SetTableFilter(name, newFilter)
            }, [])

            const $table = useMemo(() => {
                return {
                    data,
                    filter,
                    setFilter,
                    table: {
                        loading,
                        dataSource: (data && data.rows) || []
                    },
                    pagination: {
                        onChange: (page, pageSize) => {
                            setFilter({
                                offset: pageSize * (page - 1)
                            })
                        },
                        onShowSizeChange: (current, size) => {
                            setFilter({
                                offset: size * (current - 1),
                                limit: size
                            })
                        },
                        current: filter && Math.floor(filter.offset / filter.limit) + 1,
                        total: data && data.count,
                        pageSize: (filter && filter.limit) || 0
                    },
                    scroll: {
                        loadMore: handleScroll,
                        hasMore: ((data && data.rows) || []).length < ((data && data.count) || 0)
                    }
                }
            }, [data, filter, loading, setFilter, handleScroll])

            return <Com {...props} $table={$table} />
        }
    }
}
