import React, { useState } from 'react'
import 'antd/dist/antd.css'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Table, Input, InputNumber } from 'antd'

const EditableContext = React.createContext()

const EditableRow = ({ form, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
)

const EditableFormRow = Form.create()(EditableRow)

function EditableCell({ editable, record, handleSave, children, dataIndex, title, renderCell, restProps, inputType }) {
    const [editing, setEditing] = useState(false)
    const [form, setForm] = useState(null)

    const getInput = (inputType, save) => {
        if (inputType === 'number') {
            return <InputNumber onPressEnter={save} onBlur={save} />
        }
        return <Input onPressEnter={save} onBlur={save} />
    }

    const toggleEdit = () => {
        setEditing(!editing)
    }

    const save = e => {
        form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                alert(error)
                return
            }
            toggleEdit()
            handleSave({ ...record, ...values })
        })
    }

    const render = form => {
        setForm(form)
        return renderCell ? (
            renderCell({ form, editing, record, save, toggleEdit, children })
        ) : editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`
                        }
                    ],
                    initialValue: record[dataIndex]
                })(getInput(inputType, save))}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {children}
            </div>
        )
    }

    return <td {...restProps}>{editable ? <EditableContext.Consumer>{render}</EditableContext.Consumer> : children}</td>
}

function EditableTable({ dataSource, columns, handleSave, rowKey }) {
    const components = {
        body: {
            row: EditableFormRow,
            cell: EditableCell
        }
    }
    const tableColumns = columns.map(col => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
                renderCell: col.renderCell,
                inputType: col.inputType
            })
        }
    })
    return (
        <Table
            rowKey={rowKey}
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataSource}
            columns={tableColumns}
        />
    )
}

export default EditableTable
