import React, { useState, useEffect, useRef } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Card, Input, Switch, notification } from 'antd'
import axios from 'axios'
import _ from 'lodash'

import FormDialog from '../../components/FormDialog'
import UserList from '../../components/UserList'
import ManageUser from '../../components/management/tour-user'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'

const PRODUCT = 'tour'

const columns = [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'UserID'
    },
    {
        key: 'email',
        dataIndex: 'email',
        title: 'Email'
    }
]

function TourUser({ form, location, history }) {
    const apiUrl = `${appConfig.apiUrl}/${PRODUCT}`
    const [users, setUsers] = useState([])
    const [currentUser, setCurrentUser] = useState({})
    const userForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const getUsers = () => {
        return axios({
            method: 'get',
            url: `${apiUrl}/users`,
            headers
        })
            .then(({ data }) => {
                setUsers(data)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    useEffect(() => {
        getUsers()
    }, [])

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, values) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }
                let options = {
                    url: `${apiUrl}/users`,
                    headers,
                    data: { ...values, del_flag: Number(!values.del_flag) }
                }

                switch (userForm.current.formState) {
                    case 'CREATE':
                        options.method = 'post'
                        options.data = values

                        delete(options.data.del_flag)
                        break
                    case 'UPDATE': {
                        const changedFields = {}

                        Object.keys(values).forEach(prop => {
                            if (
                                prop !== 'password' &&
                                values[prop] !== undefined &&
                                values[prop] !== currentUser[prop]
                            ) {
                                changedFields[prop] = values[prop]
                            }

                            if (prop === 'password' && values.password !== currentUser.hash_passwd) {
                                changedFields.password = values.password
                            }
                        })

                        if (!Object.keys(changedFields).length) {
                            notification.warn({
                                message: 'Warning!!!',
                                description: "User infos don't change!!!"
                            })
                            return
                        }

                        options.method = 'patch'
                        options.url += `/${currentUser.id}`
                        options.data = changedFields

                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return Promise.resolve('Unknow action')
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description:
                                (userForm.current.formState === 'CREATE' ? 'Created' : 'Updated') +
                                ' user: ' +
                                values.email
                        })
                        return getUsers()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
    }
    const { getFieldDecorator } = form

    const setFormState = (state, values = {}) => {
        const formFields = ['username', 'email', 'password', 'del_flag']

        if (['CREATE', 'HIDE'].includes(state)) {
            form.resetFields()
        } else {
            values.password = values.hash_passwd
            const fields = _.pick(values, formFields)
            fields.del_flag = !fields.del_flag
            form.setFieldsValue(fields)
        }

        userForm.current.setFormState(state)
        setCurrentUser(values)
    }

    return (
        <div>
            <Card>
                <UserList
                    users={users}
                    product={PRODUCT}
                    columns={columns}
                    location={location}
                    history={history}
                    manageUser={ManageUser}
                    setFormState={setFormState}
                />
                <FormDialog
                    style={{ minWidth: '540px' }}
                    closeHandler={() => setFormState('HIDE')}
                    saveHandler={onSave}
                    ref={userForm}>
                    <Form {...formItemLayout}>
                        <Form.Item label="Email" hasFeedback>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The Email is not valid!'
                                    },
                                    { required: true, message: 'Please enter your email!' }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Password" hasFeedback>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please enter your password!' }]
                            })(<Input.Password />)}
                        </Form.Item>
                        <Form.Item label="Active">
                            {getFieldDecorator('del_flag', {
                                initialValue: currentUser.del_flag,
                                valuePropName: 'checked'
                            })(<Switch />)}
                        </Form.Item>
                    </Form>
                </FormDialog>
            </Card>
        </div>
    )
}

export default Form.create({ name: 'tour-user' })(TourUser)