import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal } from 'antd'
import _ from 'lodash'

const FormDialog = ({ closeHandler, saveHandler, children, style = {} }, ref) => {
    const [formState, setFormState] = useState('HIDE')

    useImperativeHandle(ref, () => ({
        setFormState,
        formState
    }))

    const hide = () => {
        setFormState('HIDE')
        closeHandler()
    }

    const newStyle = _.defaults(style, { minWidth: '840px' })

    return (
        <Modal
            visible={['CREATE', 'UPDATE', 'READ'].includes(formState)}
            onClick={hide}
            title={formState === 'CREATE' ? 'Create User' : formState === 'UPDATE' ? 'Edit User' : ''}
            onOk={saveHandler}
            okText={'Save'}
            onCancel={hide}
            style={newStyle}>
            {children}
        </Modal>
    )
}

export default forwardRef(FormDialog)
