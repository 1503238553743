import React from 'react'
import {
    CloseCircleOutlined,
    EditOutlined,
    SettingOutlined,
    UserAddOutlined,
    UserDeleteOutlined
} from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Input, Modal, Switch, notification, Select, Tag, Radio, List, InputNumber } from 'antd'
import axios from 'axios'
import EditTable from '../EditTable'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'
import { Can, Say, check } from '../can'

const headers = JSON.parse(localStorage.getItem('headers') || '{}')
const apiUrl = `${appConfig.apiUrl}/flight`
const RESOURCE = 'flight-user'
const _ = require('lodash')

const { Option } = Select

const convertKey = preferenceType => {
    if (!preferenceType) {
        notification.error({ message: 'Cannot get preference type!!!' })
        return
    }

    return preferenceType === 'exclude' ? 'airline_exclude' : 'airline_include'
}

const toArray = str => {
    return str ? str.split(',') : []
}

const EditableContext = React.createContext()

class EditableCell extends React.Component {
    state = {
        editing: false
    }

    toggleEdit = () => {
        const editing = !this.state.editing
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus()
            }
        })
    }

    save = e => {
        console.log('...save')
        const { record, handleSave } = this.props
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return
            }
            this.toggleEdit()
            handleSave({ ...record, ...values })
        })
    }

    renderCell = () => {
        const { children, dataIndex, record, title, form } = this.props
        this.form = form
        const { editing } = this.state

        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {this.props.form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`
                        }
                    ],
                    initialValue: record[dataIndex]
                })(<InputNumber ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={this.toggleEdit}>
                {children}
            </div>
        )
    }

    render() {
        // eslint-disable-next-line
        const {
            editable,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps}>
                {editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}
            </td>
        )
    }
}
class ManageUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userId: null,
            user_suppliers: [],
            suppliers: [],
            selectedSupplier: null,
            supplierUseOnlyOne: null,
            isUseOnlyOne: null,
            defaultSupplierUseOnlyOne: null,
            defaultUseOnlyOne: null,
            setUseOnlyOne: false,
            userMarkup: null,
            addSupplierModal: false,
            setMarkupModal: false,
            removeSupplierModal: false,
            switchModal: false,
            selectedRow: null,
            editAirlinePreferences: false
        }
    }

    componentDidMount() {
        const { props } = this
        const userId = props.match.params.id
        this.setState({ userId })
        setTimeout(this.getSuppliers, 0)
    }

    getSuppliers = () => {
        axios({
            method: 'get',
            url: `${apiUrl}/users/${this.state.userId}/suppliers`,
            headers
        })
            .then(({ data }) => {
                this.setState({ user_suppliers: data })
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )

        axios({
            method: 'get',
            url: `${apiUrl}/suppliers`,
            headers
        })
            .then(({ data }) => {
                const suppliers = data.filter(s => !s.del_flag)
                this.setState({ suppliers })
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    toggleAddSupplier = () => {
        this.setState({
            addSupplierModal: !this.state.addSupplierModal
        })
    }

    toggleSetMarkup = () => {
        this.setState({
            setMarkupModal: !this.state.setMarkupModal
        })
    }

    toggleRemoveSupplier = () => {
        this.setState({
            removeSupplierModal: !this.state.removeSupplierModal
        })
    }

    toggleSwitchModal = () => {
        this.setState({
            switchModal: !this.state.switchModal
        })
    }

    openAirlinePreferences = row => {
        this.setState({
            selectedRow: row,
            editAirlinePreferences: true
        })
    }

    addSupplier = () => {
        const { props } = this
        axios({
            method: 'post',
            url: `${apiUrl}/users/${props.match.params.id}/suppliers`,
            headers,
            data: {
                supplier_id: this.state.selectedSupplier
            }
        })
            .then(() => {
                this.toggleAddSupplier()
                this.getSuppliers()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    removeSupplier = supplierId => {
        const { props } = this
        const userId = props.match.params.id

        axios({
            method: 'delete',
            url: `${apiUrl}/users/${userId}/suppliers/${supplierId}`,
            headers
        })
            .then(() => {
                this.toggleRemoveSupplier()
                this.getSuppliers()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    applyMarkup = () => {
        const { props } = this
        const userId = props.match.params.id

        axios({
            method: 'patch',
            url: `${apiUrl}/users/${userId}/suppliers`,
            headers,
            data: {
                markup: this.state.userMarkup
            }
        })
            .then(() => {
                this.toggleSetMarkup()
                this.getSuppliers()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    selectSupliers = () => {
        const existed = this.state.user_suppliers.map(u => u.id)

        const optGroup = this.state.suppliers
            .filter(s => !existed.includes(s.id))
            .reduce(
                (out, supplier) => {
                    if (supplier.airline_code) {
                        out.Airline.push({
                            value: supplier.id,
                            label: `${supplier.airline_code} - ${supplier.name}`
                        })
                    } else {
                        out.OTA.push({
                            value: supplier.id,
                            label: supplier.name
                        })
                    }
                    return out
                },
                { Airline: [], OTA: [] }
            )

        return Object.keys(optGroup).map(key => {
            return (
                <Select.OptGroup label={key} key={key}>
                    {optGroup[key].map((item, idx) => {
                        return (
                            <Select.Option value={item.value} key={idx}>
                                {item.label}
                            </Select.Option>
                        )
                    })}
                </Select.OptGroup>
            )
        })
    }

    selectSupplier = selectedSupplier => {
        this.setState({ selectedSupplier })
    }

    changeMarkup = event => {
        this.setState({ userMarkup: event.target.value })
    }

    changeSupplierStatus = supplier => {
        const { props } = this
        const userId = props.match.params.id

        axios({
            method: 'patch',
            url: `${apiUrl}/users/${userId}/suppliers/${supplier.id}`,
            headers,
            data: {
                supplier_code: supplier.code,
                active: !!supplier.inactive
            }
        })
            .then(() => {
                this.toggleSwitchModal()
                this.getSuppliers()
                this.selectInfoUseOnlyOne()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    showConfirm = row => {
        Modal.confirm({
            title: `Are you sure to turn ${row.inactive ? 'on' : 'off'} ${row.name}?`,
            onOk: () => this.changeSupplierStatus(row),
            onCancel: () => this.toggleSwitchModal()
        })
    }

    flightType = (cell, row) => {
        const allow = []
        if (row.domestic) allow.push('Domestic')
        if (row.international) allow.push('International')

        return allow.map(type => (
            <Tag color="purple" key={type}>
                {type}
            </Tag>
        ))
    }

    airlinePreferences = (cell, active) => {
        if (!cell) return
        return cell.split(',').map(code => (
            <Tag color={active ? 'volcano' : 'gray'} key={code}>
                {code}
            </Tag>
        ))
    }

    statusCell = (cell, row) => (
        <Switch
            disabled={!check(RESOURCE, 'edit') || row.default_use_only_one === 1}
            defaultChecked={!row.inactive}
            checked={!row.inactive || row.default_use_only_one === 1}
            onClick={() => this.showConfirm(row)}
        />
    )

    showDeleteConfirm = row => {
        Modal.confirm({
            title: `Are you sure to remove ${row.name}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => this.removeSupplier(row.id),
            onCancel: () => this.toggleRemoveSupplier()
        })
    }

    actionCell = (cell, row) => (
        <Can resource={RESOURCE} perform="edit">
            <Say yes>
                <Button ghost type="primary" icon={<EditOutlined />} onClick={() => this.openAirlinePreferences(row)} />{' '}
                <Button type="danger" icon={<UserDeleteOutlined />} onClick={() => this.showDeleteConfirm(row)} />
            </Say>
        </Can>
    )

    handleSave = row => {
        // passing through params if we move EditableCell component to global
        const rowKey = 'id'
        const editableCellKey = 'markup_percent'

        const newData = [...this.state.user_suppliers]
        const index = newData.findIndex(item => row[rowKey] === item[rowKey])
        const item = newData[index]
        newData.splice(index, 1, {
            ...item,
            ...row
        })

        const userId = this.props.match.params.id
        axios({
            method: 'patch',
            url: `${apiUrl}/users/${userId}/suppliers/${row.id}`,
            headers,
            data: {
                markup: Number(row[editableCellKey]),
                supplier_code: row.code
            }
        })
            .then(() => {
                this.setState({ user_suppliers: newData })
            })
            .catch(error => {
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
                this.componentDidMount()
            })
    }

    toggleSetUseOnlyOne = () => {
        this.setState({
            setUseOnlyOne: !this.state.setUseOnlyOne
        })
        if (this.state.setUseOnlyOne === true) window.location.reload()
    }

    useOnlyOneType = (cell, row) => {
        const allow = []
        if (row.use_only_one) allow.push('Use Only One')
        if (row.default_use_only_one) allow.push('Default')

        return allow.map(type => (
            <Tag color="purple" key={type}>
                {type}
            </Tag>
        ))
    }

    selectUseOnlyOne = () => {
        const existed = this.state.user_suppliers.map(u => u.id)
        const optGroup = this.state.suppliers.filter(s => existed.includes(s.id))
        const opt = _.uniq(
            optGroup.map(item => item.code),
            'code'
        )
        return opt.map(item => (
            <Select.Option value={item} key={item}>
                {item}
            </Select.Option>
        ))
    }

    getdataUseOnlyOne = supplierUseOnlyOne => {
        this.setState({ supplierUseOnlyOne: supplierUseOnlyOne }, () => {
            this.selectInfoUseOnlyOne()
        })
    }

    selectInfoUseOnlyOne = () => {
        const { props } = this
        const params = {
            supplier_code: this.state.supplierUseOnlyOne,
            user_id: this.state.userId
        }
        if (this.state.supplierUseOnlyOne != null) {
            axios({
                method: 'post',
                url: `${apiUrl}/users/${props.match.params.id}/suppliers/defaultsupplieruseonlyone`,
                headers,
                data: params
            })
                .then(({ data }) => {
                    this.setState({ defaultSupplierUseOnlyOne: data })
                    this.filldataIsUseOnlyOne()
                    this.getdefaultSupplier()
                })
                .catch(error =>
                    notification.error({
                        message: 'Error!!!',
                        description: apiErrorHandler(error)
                    })
                )
        }
    }

    filldataIsUseOnlyOne = () => {
        if (this.state.defaultSupplierUseOnlyOne != null) {
            const find = this.state.defaultSupplierUseOnlyOne.find(item => item.use_only_one === 1)
            if (find != null) {
                this.setState({ isUseOnlyOne: 1 }, () => {
                    this.getdefaultSupplier()
                })
            } else this.setState({ isUseOnlyOne: 0 }, () => {})
        }
    }

    getdefaultSupplier = () => {
        if (this.state.defaultSupplierUseOnlyOne != null) {
            const find = this.state.defaultSupplierUseOnlyOne.find(item => item.default_use_only_one === 1)
            if (find != null && this.state.isUseOnlyOne == 1) {
                return (
                    <Select
                        defaultValue={find.source_id}
                        onSelect={this.getdataDefaultUseOnlyOne}
                        style={{ minWidth: '100%' }}>
                        <Select.Option value={find.source_id} key={find.source_id}>
                            {find.name}
                        </Select.Option>
                        {this.filldataDefaultUseOnlyOne()}
                    </Select>
                )
            } else if (find == null && this.state.isUseOnlyOne == 1) {
                return (
                    <Select onSelect={this.getdataDefaultUseOnlyOne} style={{ minWidth: '100%' }}>
                        {this.filldataDefaultUseOnlyOne()}
                    </Select>
                )
            }
        }
    }

    filldataDefaultUseOnlyOne = () => {
        const opt = _.uniq(
            this.state.defaultSupplierUseOnlyOne
                .filter(item => item.default_use_only_one !== 1)
                .map(item => {
                    return {
                        source_id: item.source_id,
                        name: item.name
                    }
                }),
            'name'
        )
        return opt.map(i => (
            <Select.Option value={i.source_id} key={i.source_id}>
                {i.name}
            </Select.Option>
        ))
    }

    getdataisUseOnlyOne = isUseOnlyOne => {
        this.setState({ isUseOnlyOne }, () => {})
    }

    getdataDefaultUseOnlyOne = defaultUseOnlyOne => {
        this.setState({ defaultUseOnlyOne }, () => {})
    }

    saveUseOnlyOneSetting = () => {
        const { props } = this
        const params = {
            user_id: this.state.userId,
            supplier_code: this.state.supplierUseOnlyOne,
            use_only_one: this.state.isUseOnlyOne,
            supplier_source_id: this.state.defaultUseOnlyOne || ''
        }
        if (this.state.supplierUseOnlyOne != null) {
            axios({
                method: 'post',
                url: `${apiUrl}/users/${props.match.params.id}/suppliers/savedefaultsupplier`,
                headers,
                data: params
            })
                .then(() => {
                    this.toggleSetUseOnlyOne()
                    this.getSuppliers()
                })
                .catch(error =>
                    notification.error({
                        message: 'Error!!!',
                        description: apiErrorHandler(error)
                    })
                )
        }
    }

    render() {
        const self = this
        let tableColumn = [
            {
                key: 'code',
                dataIndex: 'code',
                title: 'Supplier'
            },
            {
                key: 'airline_code',
                dataIndex: 'airline_code',
                title: 'Airline Code'
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: 'Name'
            },
            {
                key: 'markup_percent',
                dataIndex: 'markup_percent',
                title: 'Markup (%)',
                editable: true
            },
            {
                key: 'route_type',
                dataIndex: 'route_type',
                title: 'Allow Route Type',
                className: 'text-center',
                render: this.flightType
            },
            {
                key: 'airline_exclude',
                dataIndex: 'airline_exclude',
                title: 'Airline Exclude',
                className: 'text-center',
                render: (cell, row) => this.airlinePreferences(cell, row.preference_type === 'exclude')
            },
            {
                key: 'airline_include',
                dataIndex: 'airline_include',
                title: 'Airline Include',
                className: 'text-center',
                render: (cell, row) => this.airlinePreferences(cell, row.preference_type === 'include')
            },
            {
                key: 'use_only_one',
                dataIndex: 'use_only_one',
                title: 'Use Only One',
                className: 'text-center',
                render: this.useOnlyOneType
            },
            {
                key: 'inactive',
                dataIndex: 'inactive',
                title: 'Status',
                className: 'text-center',
                render: this.statusCell
            }
        ]

        if (check(RESOURCE, 'edit')) {
            tableColumn.push({
                key: 'dummy',
                dataIndex: 'dummy',
                text: 'Action',
                className: 'text-center',
                render: this.actionCell
            })
        }

        const components = {
            body: {
                cell: Form.create({ name: 'markup_editable' })(EditableCell)
            }
        }

        tableColumn = tableColumn.map(col => {
            if (!col.editable) {
                return col
            }
            return {
                ...col,
                inputType: col.dataIndex === 'markup_percent' ? 'number' : 'text',
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: self.handleSave
                })
            }
        })

        const { user_suppliers } = this.state

        return (
            <div>
                <div className="mb-3 row">
                    <Can resource={RESOURCE} perform="edit">
                        <Say yes>
                            <div className="col-md-7">
                                <Button type="primary" onClick={this.toggleAddSupplier}>
                                    <UserAddOutlined />
                                    Add Supplier
                                </Button>{' '}
                                <Button type="info" onClick={this.toggleSetMarkup}>
                                    <SettingOutlined /> Set Markup
                                </Button>{' '}
                                <Button type="info" onClick={this.toggleSetUseOnlyOne}>
                                    <SettingOutlined /> Use only one
                                </Button>
                            </div>
                        </Say>
                    </Can>
                </div>
                <br />
                <EditTable
                    components={components}
                    rowKey="id"
                    bordered
                    handleSave={self.handleSave}
                    dataSource={user_suppliers}
                    columns={tableColumn}
                />

                <Can resource={RESOURCE} perform="edit">
                    <Say yes>
                        {/* add supplier modal */}
                        <Modal
                            visible={this.state.addSupplierModal}
                            onClick={this.toggleAddSupplier}
                            title="Add Supplier"
                            onOk={this.addSupplier}
                            onCancel={this.toggleAddSupplier}>
                            Select supplier you want to add into this user
                            <Select
                                showSearch
                                filterOption
                                optionFilterProp="children"
                                onSelect={this.selectSupplier}
                                style={{ minWidth: '100%' }}>
                                {this.selectSupliers()}
                            </Select>
                        </Modal>
                        {/* set markup modal */}
                        <Modal
                            visible={this.state.setMarkupModal}
                            title="Markup Configuration"
                            onOk={this.applyMarkup}
                            onCancel={this.toggleSetMarkup}>
                            Enter the markup value will apply for all suppliers in this user
                            <Input type="number" onChange={this.changeMarkup} />
                        </Modal>
                        {/* set use only one modal */}
                        <Modal
                            visible={this.state.setUseOnlyOne}
                            onClick={this.toggleSetUseOnlyOne}
                            title="Setting Use only one"
                            onOk={this.saveUseOnlyOneSetting}
                            onCancel={this.toggleSetUseOnlyOne}>
                            <table style={{ minWidth: '100%' }}>
                                <tbody>
                                    <tr>
                                        <th>Select Supplier:</th>
                                        <td>
                                            <Select onSelect={this.getdataUseOnlyOne} style={{ minWidth: '100%' }}>
                                                {this.selectUseOnlyOne()}
                                            </Select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td>
                                            <Radio.Group
                                                value={this.state.isUseOnlyOne}
                                                onChange={e => this.getdataisUseOnlyOne(e.target.value)}>
                                                <Radio value={0}>Use all</Radio>
                                                <Radio value={1}>Use only one</Radio>
                                            </Radio.Group>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Select Default Supplier:</th>
                                        <td>{this.getdefaultSupplier()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Modal>
                        {/* set airline preferences */}
                        <AirlinePreferences
                            selectedRow={this.state.selectedRow}
                            editAirlinePreferences={this.state.editAirlinePreferences}
                            userId={this.props.match.params.id}
                            onSubmit={this.getSuppliers}
                            onClose={() => this.setState({ editAirlinePreferences: false })}
                        />
                    </Say>
                </Can>
            </div>
        )
    }
}

class AirlinePreferences extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editAirlinePreferences: false,
            newValues: {},
            filteredAirline: [],
            airlines: []
        }
        this.getAirlines()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { selectedRow: row, editAirlinePreferences } = nextProps

        if (row && editAirlinePreferences) {
            const newValues = {
                preference_type: row.preference_type,
                airline_include: toArray(row.airline_include),
                airline_exclude: toArray(row.airline_exclude)
            }
            this.setState({
                newValues,
                editAirlinePreferences: true
            })
        } else {
            this.setState({
                newValues: {},
                editAirlinePreferences: false
            })
        }
    }

    toggleAirlinePreferences = () => {
        if (this.state.editAirlinePreferences) {
            this.props.onClose()
        }
        this.setState({
            editAirlinePreferences: !this.state.editAirlinePreferences
        })
    }

    getAirlines = () => {
        return axios({
            method: 'get',
            url: `${apiUrl}/airlines`,
            headers
        })
            .then(({ data }) => {
                this.setState({ airlines: data })
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    airlinePreferences = (cell, active) => {
        if (!cell) return
        return cell.split(',').map(code => (
            <Tag color={active ? 'volcano' : 'gray'} key={code}>
                {code}
            </Tag>
        ))
    }

    setNewValue = (key, value) => {
        const newValues = { ...this.state.newValues }
        newValues[key] = value

        this.setState({ newValues })
    }

    clickedRemoveAirlineButton = item => {
        const { newValues } = this.state
        const rule = convertKey(newValues.preference_type)
        if (!rule) return
        const airlines = newValues[rule]
        const index = airlines.indexOf(item.code)
        if (index > -1) {
            airlines.splice(index, 1)
        }

        newValues[rule] = airlines

        this.setState({ newValues })
    }

    changeAirlinePreferences = () => {
        const { newValues } = this.state
        const { userId } = this.props

        const data = {
            preference_type: newValues.preference_type
        }

        if (newValues.preference_type === 'include') {
            data.airline_include = newValues.airline_include.join(',')
        } else if (newValues.preference_type === 'exclude') {
            data.airline_exclude = newValues.airline_exclude.join(',')
        } else {
            notification.error({
                message: 'Error!!!',
                description: 'Cannot get preference type'
            })

            return
        }

        axios({
            method: 'patch',
            url: `${apiUrl}/users/${userId}/suppliers/${this.props.selectedRow.id}`,
            headers,
            data
        })
            .then(() => {
                this.toggleAirlinePreferences()
                this.props.onSubmit()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    removeAirlinePreferences = () => {
        const { userId } = this.props
        axios({
            method: 'patch',
            url: `${apiUrl}/users/${userId}/suppliers/${this.props.selectedRow.id}`,
            headers,
            data: {
                preference_type: '',
                airline_exclude: '',
                airline_include: ''
            }
        })
            .then(() => {
                this.toggleAirlinePreferences()
                this.props.onSubmit()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    handleSearchAirline = value => {
        const val = value.toLocaleLowerCase()
        const stateAirlines = this.state.airlines
        const filteredAirline = stateAirlines.filter(x => `${x.code}-${x.name}`.toLocaleLowerCase().indexOf(val) > -1)
        this.setState({ filteredAirline: filteredAirline })
    }

    handleChangeAirline = value => {
        const [code] = value.split('-')
        const { preference_type, airline_include, airline_exclude } = this.state.newValues
        let data = preference_type === 'exclude' ? airline_exclude : airline_include
        data = data || []
        data = data.concat([code])
        this.setNewValue(convertKey(preference_type), data)
    }

    render() {
        const airlineOptions = this.state.filteredAirline.map(({ code, name }) => (
            <Option key={`${code}-${name}`}>{`${code} - ${name}`}</Option>
        ))
        const { preference_type, airline_exclude, airline_include } = this.state.newValues
        const selectedAirlines = (preference_type === 'exclude' ? airline_exclude : airline_include) || []
        const airlinePreferences = this.state.filteredAirline.filter(({ code }) => selectedAirlines.includes(code))

        return (
            <Modal
                visible={this.state.editAirlinePreferences}
                title="Airline Preferences"
                onOk={this.changeAirlinePreferences}
                onCancel={this.toggleAirlinePreferences}
                footer={[
                    <Button key="back" onClick={this.toggleAirlinePreferences}>
                        Cancel
                    </Button>,
                    <Button key="remove" type="danger" onClick={this.removeAirlinePreferences}>
                        Remove
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.changeAirlinePreferences}>
                        Submit
                    </Button>
                ]}>
                <Form>
                    <Form.Item wrapperCol={{ flex: 1 }}>
                        <Radio.Group
                            onChange={({ target: { value } }) => this.setNewValue('preference_type', value)}
                            defaultValue={this.state.newValues.preference_type}>
                            <Radio value={'exclude'}>Exclude</Radio>
                            <Radio value={'include'}>Include</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item wrapperCol={{ flex: 1 }}>
                        <Select
                            showSearch
                            size="large"
                            placeholder={'Search Airline'}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={this.handleSearchAirline}
                            onChange={this.handleChangeAirline}
                            notFoundContent={null}>
                            {airlineOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ flex: 1 }}>
                        Selected Airlines {airlinePreferences.length ? `(${airlinePreferences.length})` : ''}:
                        <List
                            size="small"
                            bordered
                            dataSource={airlinePreferences}
                            style={{ maxHeight: '250px', overflowX: 'auto' }}
                            renderItem={item => (
                                <List.Item>
                                    {`${item.code} - ${item.name}`}
                                    <Button
                                        style={{
                                            float: 'right',
                                            border: 'none',
                                            color: 'red',
                                            marginTop: '-5px'
                                        }}
                                        onClick={() => this.clickedRemoveAirlineButton(item)}>
                                        <CloseCircleOutlined />
                                    </Button>
                                </List.Item>
                            )}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default ManageUser
