import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { notification, Card, Col, Row, Input } from 'antd'
import _ from 'lodash'

import FormDialog from '../../components/FormDialog'
import apiErrorHandler from '../../api-error-handler'
import appConfig from '../../config'
import EPSSupplierList from '../../components/EPSSupplierList'

const PRODUCT = 'hotel'

const columns = [
    {
        key: 'hotel_id',
        dataIndex: 'hotel_id',
        title: 'Hotel ID'
    },
    {
        key: 'hotel_name',
        dataIndex: 'hotel_name',
        title: 'Hotel Name'
    },
    {
        key: 'region_name',
        dataIndex: 'region_name',
        title: 'Region'
    }
]

function HotelTaxes({ form }) {
    const apiUrl = `${appConfig.apiOffer}/asgard`
    const [EPSList, setEPSList] = useState([])
    const [currentSupplier, setCurrentSupplier] = useState({})
    const [changedFields, setChangedFields] = useState({})
    const [formState, _setFormState] = useState('HIDE')
    const supplierForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const getEPSList = () => {
        return axios({
            method: 'get',
            url: `${apiUrl}/taxes`,
            headers
        })
            .then(({ data }) => {
                setEPSList(data.data)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    useEffect(() => {
        getEPSList()
    }, [])

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, values) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }

                let options = {
                    url: `${apiUrl}/taxes`,
                    headers,
                    data: { tax_and_fee: values.tax_and_fee, mandatory_tax: values.mandatory_tax }
                }

                switch (supplierForm.current.formState) {
                    case 'UPDATE': {
                        Object.keys(values).forEach(prop => {
                            if (values[prop] !== undefined && values[prop] !== currentSupplier[prop]) {
                                changedFields[prop] = values[prop]
                            }
                        })

                        if (!Object.keys(changedFields).length) {
                            notification.warn({
                                message: 'Warning!!!',
                                description: "EPS taxes infos don't change!!!"
                            })
                            return
                        }

                        options.method = 'put'
                        options.url += `/${currentSupplier.id}`
                        options.data = changedFields

                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description:
                                (formState === 'CREATE' ? 'Created' : 'Updated') + ' EPS taxes: ' + values.hotel_name
                        })
                        getEPSList()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 }
    }

    const { getFieldDecorator } = form

    const setFormState = (state, values = {}) => {
        const formFields = [
            'id',
            'hotel_id',
            'hotel_name',
            'region_name',
            'tax_and_fee',
            'mandatory_tax.request_currency.value',
            'mandatory_tax.request_currency.currency',
            'mandatory_tax.billable_currency.value',
            'mandatory_tax.billable_currency.currency'
        ]
        if (['HIDE'].includes(state)) {
            form.resetFields()
        } else {
            setChangedFields({})
            const fields = _.pick(values, formFields)
            form.setFieldsValue(fields)
        }

        supplierForm.current.setFormState(state)
        _setFormState(state)
        setCurrentSupplier(values)
    }

    return (
        <Card>
            <EPSSupplierList
                EPSList={EPSList}
                product={PRODUCT}
                columns={columns}
                getEPSList={getEPSList}
                setFormState={setFormState}
            />
            <FormDialog closeHandler={() => setFormState('HIDE')} saveHandler={onSave} ref={supplierForm}>
                {supplierForm && supplierForm.current ? (
                    <Form {...formItemLayout}>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Hotel ID" hasFeedback>
                                    {getFieldDecorator('hotel_id', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: false,
                                                message: ' Please enter Hotel ID!'
                                            }
                                        ]
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Hotel name" hasFeedback>
                                    {getFieldDecorator('hotel_name', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: false,
                                                message: ' Please enter Hotel name!'
                                            }
                                        ]
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Region" hasFeedback>
                                    {getFieldDecorator('region_name', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: false,
                                                message: ' Please enter Region!'
                                            }
                                        ]
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Tax & Fee" hasFeedback>
                                    {getFieldDecorator('tax_and_fee', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter Tax & Fee!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12} style={{ fontSize: 14, marginBottom: 5, fontWeight: 600 }}>
                                Mandatory tax:
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: 10 }}>
                            <Col md={12}>Request Currency:</Col>
                            <Col md={12}>Billable Currency:</Col>
                        </Row>
                        <Row gutter={16} justify="space-around">
                            <Col md={12}>
                                <Form.Item label="Value" hasFeedback>
                                    {getFieldDecorator('mandatory_tax.request_currency.value', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter value!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Currency" hasFeedback>
                                    {getFieldDecorator('mandatory_tax.request_currency.currency', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter currency!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Value" hasFeedback>
                                    {getFieldDecorator('mandatory_tax.billable_currency.value', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter value!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Currency" hasFeedback>
                                    {getFieldDecorator('mandatory_tax.billable_currency.currency', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter currency!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ) : null}
            </FormDialog>
        </Card>
    )
}

export default Form.create({ name: 'taxes-management' })(HotelTaxes)
