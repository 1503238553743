import React from 'react'
import { MinusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import Icon from '@ant-design/icons'
import { Form, Spin, DatePicker, Popover, Select, Button, Card, Row, Col, Checkbox } from 'antd'
import { IconHotel, IconAirport, IconCity } from '@icons'
import { Root, Room } from './styled'
import container from './container'
import countries from '../../countries'

const RoomList = props => {
    const {
        $values,
        handleAddRoom,
        handleRemoveRoom,
        handleAddChild,
        handleRemoveChild,
        handleAddInfant,
        handleRemoveInfant
    } = props

    const rooms = ($values && $values.rooms) || []

    return (
        <div style={{ width: 600 }} className={'ant-form ant-form-inline'}>
            {rooms.map((room, idx) => (
                <Room key={idx} style={{ flex: 1 }}>
                    <Card
                        title={`Room ${idx + 1}`}
                        size={'small'}
                        extra={
                            <Row type={'flex'} gutter={8}>
                                <Col>
                                    <Button
                                        type={'primary'}
                                        ghost
                                        onClick={() => {
                                            handleAddChild(idx)
                                        }}>
                                        Add Child
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type={'primary'}
                                        ghost
                                        onClick={() => {
                                            handleAddInfant(idx)
                                        }}>
                                        Add Infant
                                    </Button>
                                </Col>
                                {rooms.length > 1 ? (
                                    <Col>
                                        <Button
                                            type={'danger'}
                                            ghost
                                            onClick={() => {
                                                handleRemoveRoom(room)
                                            }}>
                                            Remove
                                        </Button>
                                    </Col>
                                ) : null}
                            </Row>
                        }>
                        <Row type={'flex'} gutter={8}>
                            <Col>
                                <Row align={'middle'}>
                                    <Form.Item name={['rooms', idx, 'adults']} label={'Adults'} colon={false}>
                                        <Select placeholder={'Adults'} style={{ width: 60 }}>
                                            {[...Array(9).keys()].map(item => (
                                                <Select.Option key={item} value={item + 1}>
                                                    {item + 1}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <MinusCircleOutlined
                                        style={{
                                            fontSize: 24,
                                            marginLeft: 5,
                                            color: 'transparent'
                                        }}
                                    />
                                </Row>
                            </Col>
                            {(rooms[idx].children || []).map((child, cidx) => (
                                <Col key={cidx}>
                                    <Row align={'middle'}>
                                        <Form.Item
                                            name={['rooms', idx, 'children', cidx, 'age']}
                                            label={`Child ${cidx + 1} age`}
                                            colon={false}>
                                            <Select placeholder={'Ages'} style={{ width: 60 }}>
                                                {[...Array(11).keys()].map(item => (
                                                    <Select.Option key={item} value={item + 2}>
                                                        {item + 2}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <MinusCircleOutlined
                                            onClick={() => {
                                                handleRemoveChild(idx, child)
                                            }}
                                            style={{ fontSize: 24, marginLeft: 5 }}
                                        />
                                    </Row>
                                </Col>
                            ))}
                            {(rooms[idx].infants || []).map((infant, cidx) => (
                                <Col key={cidx}>
                                    <Row align={'middle'}>
                                        <Form.Item
                                            name={['rooms', idx, 'infants', cidx, 'age']}
                                            label={`Infant ${cidx + 1} age`}
                                            colon={false}>
                                            <Select placeholder={'Ages'} style={{ width: 60 }}>
                                                {[...Array(2).keys()].map(item => (
                                                    <Select.Option key={item} value={item}>
                                                        {item}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <MinusCircleOutlined
                                            onClick={() => {
                                                handleRemoveInfant(idx, infant)
                                            }}
                                            style={{ fontSize: 24, marginLeft: 5 }}
                                        />
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    </Card>
                    <br />
                </Room>
            ))}
            <Button type={'primary'} ghost block onClick={handleAddRoom}>
                Add Room
            </Button>
        </div>
    )
}

const SearchForm = props => {
    const { users, suggestions, handleSuggestion, $props, $fieldProps, $values, $loading } = props

    // const range = $values.for && $values.for.value

    // const nights = range && range.length === 2 ? range[1].diff(range[0], 'days') : 0

    // const nightsName = nights > 1 ? 'Nights' : 'Night'

    const rooms = ($values && $values.rooms) || []

    const adults = rooms.reduce((count, room) => count + (room.adults || 0), 0)

    const children = rooms.reduce((count, room) => count + ((room.children && room.children.length) || 0), 0)

    const infants = rooms.reduce((count, room) => count + ((room.infants && room.infants.length) || 0), 0)

    const rName = rooms.length > 1 ? 'Rooms' : 'Room'

    const adultName = adults > 1 ? 'Adults' : 'Adult'

    const childrenName = children > 1 ? 'Children' : 'Child'

    const infantName = infants > 1 ? 'Infants' : 'Infant'

    const roomGuestInfo = [
        rooms.length > 0 ? `${rooms.length} ${rName}` : '',
        adults > 0 ? `${adults} ${adultName}` : '',
        children > 0 ? `${children} ${childrenName}` : '',
        infants > 0 ? `${infants} ${infantName}` : ''
    ].join(' ')

    return (
        <Spin spinning={$loading}>
            <Form colon={false} hideRequiredMark {...$props}>
                <Card size={'small'}>
                    <Root>
                        <div className={'container'}>
                            <Row align={'middle'}>
                                <Col flex={1}>
                                    <Form.Item name={'userId'} wrapperCol={{ flex: 1 }} {...$fieldProps.userId}>
                                        <Select
                                            loading={!users}
                                            disabled={!users}
                                            size={'large'}
                                            style={{ width: '100%' }}
                                            placeholder={'Please select an user'}
                                            showSearch
                                            allowClear
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {(users || []).map(user => (
                                                <Select.Option key={user.id} value={user.id}>
                                                    {`${user.name} - ${user.email}`}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row align={'middle'}>
                                <Col flex={1}>
                                    <Form.Item name={'where'} wrapperCol={{ flex: 1 }} {...$fieldProps.where}>
                                        <Select
                                            size={'large'}
                                            showArrow={false}
                                            // notFoundContent={'Type any places'}
                                            showSearch
                                            onSearch={handleSuggestion}
                                            placeholder={'Enter a destination or property'}
                                            style={{ width: '100%' }}
                                            filterOption={() => true}>
                                            {suggestions.map(item => (
                                                <Select.Option key={item.id} value={`${item.type}_${item.id}`}>
                                                    <div>
                                                        <div style={{ display: 'inline-block', marginRight: 5 }}>
                                                            {item.type === 'HOTEL' ? (
                                                                <Icon component={IconHotel} />
                                                            ) : null}
                                                            {item.type === 'AIRPORT' ? (
                                                                <Icon component={IconAirport} />
                                                            ) : null}
                                                            {item.type === 'CITY' ? (
                                                                <Icon component={IconCity} />
                                                            ) : null}
                                                        </div>
                                                        <div
                                                            style={{ display: 'inline-block' }}
                                                            dangerouslySetInnerHTML={{ __html: item.name }}
                                                        />
                                                    </div>
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={8} align={'middle'}>
                                <Col span={12}>
                                    <Form.Item name={'for'} wrapperCol={{ flex: 1 }} {...$fieldProps.for}>
                                        <DatePicker.RangePicker
                                            size={'large'}
                                            style={{ width: '100%' }}
                                            placeholder={['Checkin', 'Checkout']}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item wrapperCol={{ flex: 1 }}>
                                        <div style={{ display: 'none' }}>
                                            <RoomList {...props} />
                                        </div>
                                        <Popover
                                            trigger={'click'}
                                            content={<RoomList {...props} />}
                                            placement="bottomRight">
                                            <Button size={'large'} style={{ width: '100%' }}>
                                                {roomGuestInfo}
                                            </Button>
                                        </Popover>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={8} align={'middle'}>
                                <Col span={12}>
                                    <Form.Item
                                        name={'nationality'}
                                        wrapperCol={{ flex: 1 }}
                                        {...$fieldProps.nationality}>
                                        <Select
                                            size={'large'}
                                            placeholder={`What is your nationality?`}
                                            style={{ width: '100%' }}
                                            showSearch
                                            showArrow={false}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {countries.map(item => (
                                                <Select.Option key={item.code} value={item.code}>
                                                    {`${item.name} (${item.code})`}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name={'package'} wrapperCol={{ flex: 1 }}>
                                        <Checkbox>Is Package</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item wrapperCol={{ flex: 1 }}>
                                        <Button
                                            size={'large'}
                                            htmlType={'submit'}
                                            type={'primary'}
                                            icon={<SearchOutlined />}
                                            style={{ width: '100%' }}>
                                            Search
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Root>
                </Card>
            </Form>
        </Spin>
    )
}

export default container(SearchForm)
