import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { notification, Card, Col, Row, Input, AutoComplete, Tag } from 'antd'
import _ from 'lodash'

import FormDialog from '../../components/FormDialog'
import SupplierList from '../../components/SupplierList'
import apiErrorHandler from '../../api-error-handler'
import appConfig from '../../config'

const PRODUCT = 'hotel'

const columns = [
    {
        key: 'code',
        dataIndex: 'code',
        title: 'Code',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.code.localeCompare(b.code)
    },
    {
        key: 'source_id',
        dataIndex: 'source_id',
        title: 'Source'
    },
    {
        key: 'contract_id',
        dataIndex: 'contract_id',
        title: 'Contract'
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name'
    },
    {
        key: 'rate_type',
        dataIndex: 'rate_type',
        title: 'Rate Type',
        render: cell => {
            switch (cell) {
                case 'Package':
                    return <Tag color="green">{cell}</Tag>
                case 'Standalone':
                    return <Tag color="orange">{cell}</Tag>
                default:
                    return <Tag color="geekblue">{cell}</Tag>
            }
        }
    }
]

function HotelSupplier({ form }) {
    const apiUrl = `${appConfig.apiUrl}/${PRODUCT}`
    const [suppliers, setSuppliers] = useState([])
    const [currentSupplier, setCurrentSupplier] = useState({})
    const [changedFields, setChangedFields] = useState({})
    const [formState, _setFormState] = useState('HIDE')
    const supplierForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const getSuppliers = () => {
        return axios({
            method: 'get',
            url: `${apiUrl}/suppliers`,
            headers
        })
            .then(({ data }) => {
                setSuppliers(data)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    useEffect(() => {
        getSuppliers()
    }, [])

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, values) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }

                let options = {
                    url: `${apiUrl}/suppliers`,
                    headers,
                    data: values
                }

                switch (supplierForm.current.formState) {
                    case 'CREATE':
                        options.method = 'post'
                        options.data = values
                        break
                    case 'UPDATE': {
                        Object.keys(values).forEach(prop => {
                            if (values[prop] !== undefined && values[prop] !== currentSupplier[prop]) {
                                changedFields[prop] = values[prop]
                            }
                        })

                        if (!Object.keys(changedFields).length) {
                            notification.warn({
                                message: 'Warning!!!',
                                description: "Supplier infos don't change!!!"
                            })
                            return
                        }

                        options.method = 'put'
                        options.url += `/${currentSupplier.id}`
                        options.data = changedFields

                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description: (formState === 'CREATE' ? 'Created' : 'Updated') + ' supplier: ' + values.code
                        })
                        getSuppliers()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
    }

    const { getFieldDecorator, setFieldsValue } = form

    const setFormState = (state, values = {}) => {
        const formFields = [
            'name',
            'code',
            'source_id',
            'contract_id',
            'username',
            'password',
            'email',
            'client_id',
            'api_key',
            'shared_secret',
            'url',
            'business_type',
            'rate_type'
        ]

        if (['CREATE', 'HIDE'].includes(state)) {
            form.resetFields()
        } else {
            setChangedFields({})
            const fields = _.pick(values, formFields)
            form.setFieldsValue(fields)
        }

        supplierForm.current.setFormState(state)
        _setFormState(state)
        setCurrentSupplier(values)
    }

    const selectSupplier = code => {
        const supplier = suppliers.find(s => s.code === code)

        if (supplier) {
            setFieldsValue({
                source_id: supplier.source_id,
                url: supplier.url
            })
        } else {
            form.resetFields()
            setFieldsValue({
                source_id: Math.random()
                    .toString(36)
                    .substring(2, 15)
            })
        }

        if (formState === 'CREATE') {
            const latestContract = _.maxBy(suppliers, s => Number(s.contract_id))
            setFieldsValue({
                contract_id: (Number(latestContract.contract_id) + 1).toString()
            })
        }
    }

    return (
        <Card>
            <SupplierList
                suppliers={suppliers}
                product={PRODUCT}
                columns={columns}
                getSuppliers={getSuppliers}
                setFormState={setFormState}
            />
            <FormDialog closeHandler={() => setFormState('HIDE')} saveHandler={onSave} ref={supplierForm}>
                {supplierForm && supplierForm.current ? (
                    <Form {...formItemLayout}>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Code" hasFeedback>
                                    {getFieldDecorator('code', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Code is required!'
                                            },
                                            { required: true, message: 'Please enter supplier code!' }
                                        ]
                                    })(
                                        <AutoComplete
                                            dataSource={_.uniqBy(suppliers, 'code').map(s => s.code)}
                                            placeholder="Select Supplier"
                                            onChange={selectSupplier}
                                            disabled={formState === 'UPDATE'}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Name" hasFeedback>
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Name is required!'
                                            },
                                            { required: true, message: 'Please enter supplier name!' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Source" hasFeedback>
                                    {getFieldDecorator('source_id', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter Source ID!'
                                            }
                                        ]
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Contract" hasFeedback>
                                    {getFieldDecorator('contract_id', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter Contact ID!'
                                            }
                                        ]
                                    })(<Input disabled />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Username" hasFeedback>
                                    {getFieldDecorator('username', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter Username!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Password" hasFeedback>
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter Password!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Url" hasFeedback>
                                    {getFieldDecorator('url', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter URL!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Email" hasFeedback>
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                type: 'email'
                                            },
                                            {
                                                required: true,
                                                message: ' Please enter email!'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Client Id" hasFeedback>
                                    {getFieldDecorator('client_id', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="API Key" hasFeedback>
                                    {getFieldDecorator('api_key', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Shared Secret" hasFeedback>
                                    {getFieldDecorator('shared_secret', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Rate Type" hasFeedback>
                                    {getFieldDecorator('rate_type', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Rate type is required!'
                                            },
                                            { required: true, message: 'Please select one rate type!' }
                                        ]
                                    })(<AutoComplete dataSource={['Combined', 'Package', 'Standalone']} />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Business Type" hasFeedback>
                                    {getFieldDecorator('business_type', {
                                        rules: [
                                            {
                                                type: 'string'
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ) : null}
            </FormDialog>
        </Card>
    )
}

export default Form.create({ name: 'hotel-supplier' })(HotelSupplier)
