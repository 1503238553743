import React from 'react'
import { Input, Button, Spin, Form } from 'antd'
import container from './container'

const LoginForm = props => {
    const { $loading, $props, $fieldProps } = props
    return (
        <Spin spinning={$loading}>
            <Form {...$props}>
                <Form.Item name={'email'} {...$fieldProps.email}>
                    <Input placeholder={'Email'} />
                </Form.Item>
                <Form.Item name={'password'} {...$fieldProps.password}>
                    <Input placeholder={'Password'} type={'password'} />
                </Form.Item>
                <Button type={'primary'} htmlType={'submit'} block>
                    Sign In
                </Button>
            </Form>
        </Spin>
    )
}

export default container(LoginForm)
