import React from 'react'
import container from './container'
import { Card, Button, Select, Spin, Form, Row, Col } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import suppliers from '../../../suppliers-amenities'

const amenitiesTypes = ['hotel', 'room']

const AmenitiesMappingForm = props => {
    const { $props, $loading, $fieldProps } = props
    return (
        <Spin spinning={$loading}>
            <Form colon={false} {...$props}>
                <Card
                    title="Amenities Mapping"
                    size="small"
                    extra={
                        <Button htmlType={'submit'} type="primary">
                            <SearchOutlined /> Find
                        </Button>
                    }>
                    <div className="d-md-flex align-items-center">
                        <Card.Meta description="Check amenities mapping between GoQuo and suppliers." />
                    </div>
                    <br />
                    <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <Form.Item name={'type'} wrapperCol={{ flex: 1 }} {...$fieldProps.type}>
                                <Select placeholder="Select amenities type" showSearch style={{ width: '100%' }}>
                                    {amenitiesTypes.map(type => (
                                        <Select.Option value={type} key={type}>
                                            {type}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={16}>
                            <Form.Item name={'provider'} wrapperCol={{ flex: 1 }} {...$fieldProps.provider}>
                                <Select placeholder="Select provider here" showSearch style={{ width: '100%' }}>
                                    {suppliers.map(provider => (
                                        <Select.Option value={provider} key={provider}>
                                            {provider}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Spin>
    )
}

export default container(AmenitiesMappingForm)
