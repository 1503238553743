/* eslint-disable no-unused-vars */
import { createAction } from '@'
import { getAuth, loginErrorHandle } from '@misc'
import axios from 'axios'
import { get } from 'lodash'
import moment from 'moment'
import prettyData from 'pretty-data'
import { $fetching, $logs } from '../stores'
const _ = require('lodash')

const ENDPOINT = process.env.REACT_APP_API_URL

const getAppLabel = (app, env) => {
    if (app === 'hotel') {
        return `(labels.k8s-pod/app="hotel-offer" OR labels.k8s-pod/app="hotel-booking") AND
        (labels.k8s-pod/release="hotel-svc-${env}" OR labels."k8s-pod/release"="hotel-booksvc-${env}")`
    }

    if (app === 'tour-gateway') {
        return `labels.k8s-pod/app="${app}-${env}" AND
    labels.k8s-pod/release="${app}-${env}"`
    }

    return `labels.k8s-pod/app="${app}" AND
    labels.k8s-pod/release="${app}-${env}"`
}

const fetchLog = async values => {
    const auth = getAuth()

    const data = []
    const options = {
        start: moment(values.range[0]).format('YYYY-MM-DD[T]HH:mm:ss'),
        end: moment(values.range[1]).format('YYYY-MM-DD[T]HH:mm:ss'),
        ...(values.rid && { rid: values.rid }),
        ...(values.lid && { lid: values.lid }),
        ...(values.sid && { lid: values.sid })
    }

    try {
        const res = await axios({
            url: `${ENDPOINT}/api-logs-aws`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...auth
            },
            data: options
        })
        if (res.data && Array.isArray(res.data)) {
            Array.prototype.push.apply(data, res.data)
        }
    } catch (e) {
        console.error(e)
    }

    const msgMap = {}
    const msgList = []
    data.forEach(r => {
        try {
            const logs = []
            const name = r.metric || r.method
            const src = ''
            const requestTxt = r.request
            const responseTxt = r.response
            const msg = r.message
            if (requestTxt && requestTxt !== '') {
                logs.push({
                    key: r.rid,
                    timestamp: r.timestamp,
                    name: `${name}_request`,
                    src,
                    msg: requestTxt
                })
            }
            if (responseTxt && responseTxt !== '') {
                logs.push({
                    key: r.rid,
                    timestamp: r.time,
                    name: `${name}_response`,
                    src,
                    msg: responseTxt
                })
            }
            if (msg && msg !== null) {
                logs.push({
                    key: r.rid,
                    timestamp: r.time,
                    name,
                    src,
                    msg
                })
            }
        } catch (error) {
            console.log(error)
        }
    })

    const res = data.map(item => {
        return {
            request: item.request,
            response: item.response,
            supplier: item.supplier || '',
            name: item.metric || item.method,
            time: item.time
        }
    })

    res.sort((a, b) => a.timestamp - b.timestamp)

    return res
}

export default createAction('GetAwsLogs', async values => {
    $logs.setState(() => undefined)

    const version = Math.random().toString()
    const endDate = values.time === 1 ? values.range[1] : moment()
    const startDate = values.time === 1 ? values.range[0] : null

    let date = endDate

    $fetching.setState(() => ({
        version,
        date
    }))

    const loop = async () => {
        const res = await fetchLog({
            ...values,
            time: 1,
            range: [moment(date), moment(date)]
        })
        const fetching = $fetching.getState()
        if (!fetching || fetching.version !== version) {
            return
        }
        $logs.setState(s => (s ? s.slice(0) : []).concat(res))
        date = moment(date).subtract(1, 'h')
        if (startDate && startDate.isAfter(date, 'hours')) {
            $fetching.setState(() => undefined)
            return
        }
        $fetching.setState(s => ({
            ...s,
            date
        }))
        setTimeout(() => {
            loop().catch(console.error)
        }, 10)
    }

    loop().catch(console.error)
})
