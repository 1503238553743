import React from 'react'
import axios from 'axios'
import { Bar } from 'react-chartjs-2'
import { notification } from 'antd'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'

class TotalBooking extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            headers: null,
            barData: { datasets: [], labels: [] }
        }
    }

    randomRGB(length) {
        const rgb = {}
        for (let i = 0; i < length; i++) {
            const r = Math.floor(Math.random() * 256)
            const g = Math.floor(Math.random() * 256)
            const b = Math.floor(Math.random() * 256)

            const key = `rgb(${r},${g},${b})`
            const val = `rgb(${r},${g},${b},0.4)`
            rgb[key] = val
        }
        return rgb
    }

    UNSAFE_componentWillMount() {
        this.setState({
            headers: JSON.parse(localStorage.getItem('headers') || '{}')
        })
    }

    UNSAFE_componentWillReceiveProps(props) {
        const { by } = this.props
        if (props.by !== by) {
            this.componentDidMount()
        }
    }

    componentDidMount() {
        const date = new Date().getDate()
        axios({
            method: 'get',
            url: `${appConfig.apiUrl}/hotel/bookings/chart`,
            headers: this.state.headers,
            params: {
                date
            },
            json: true
        })
            .then(({ data }) => {
                const dataset = data[this.props.by]
                const rgb = this.randomRGB(Object.keys(dataset).length)

                this.setState({
                    barData: {
                        labels: Object.keys(dataset),
                        datasets: [
                            {
                                label: 'label',
                                data: Object.values(dataset),
                                fill: true,
                                backgroundColor: Object.values(rgb),
                                borderColor: Object.keys(rgb),
                                borderWidth: 1
                            }
                        ]
                    }
                })
            })
            .catch(error => {
                notification.warning({
                    message: 'Warning!!!',
                    description: apiErrorHandler(error)
                })
            })
    }

    barChartOpts = {
        legend: {
            display: false
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        }
    }

    render() {
        return (
            <div>
                <Bar data={this.state.barData} options={this.barChartOpts} />
            </div>
        )
    }
}

export default TotalBooking
