import { createAction } from '@'
import { $projects } from '../stores'

export default createAction('GetProjects', async () => {
    $projects.setState(() => undefined)

    const projects = [
        {
            label: 'Flight',
            value: 'flight',
            id: 'flight'
        },
        {
            label: 'Hotel',
            value: 'hotel',
            id: 'hotel'
        },
        {
            label: 'Tour',
            value: 'tour',
            id: 'tour'
        }
    ]
    $projects.setState(() => projects)
})
