import React from 'react'
import { EditOutlined, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Table, Button, Modal, Input, DatePicker, notification, Switch, Row, Col, Popconfirm } from 'antd'
import axios from 'axios'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'
import { Can, Say, check } from '../can'
import TextArea from 'antd/lib/input/TextArea'

const headers = JSON.parse(localStorage.getItem('headers') || '{}')
const apiUrl = `${appConfig.apiUrl}/flight/suppliers`
const RESOURCE = 'flight-supplier'
const moment = require('moment')

class ManageSupplier extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addConfigModal: false,
            travelport_configs: [],
            selected: null,
            routeTableData: []
        }
        this.getTravelportConfigs(props.match.params.id)
    }

    handleAddRouteToTable = () => {
        const regex = /^[A-Z]{3}$/
        const { departAirport, returnAirport, routeTableData } = this.state
        const maxKey = routeTableData.length > 0 ? Math.max(...routeTableData.map(item => item.key)) : -1

        if (regex.test(departAirport) && (returnAirport !== null && returnAirport !== undefined && returnAirport !== '')) {
            const newRoute = { departAirport, returnAirport, key: maxKey + 1 }
            const updatedRouteTableData = [...routeTableData, newRoute]
            this.setState({ routeTableData: updatedRouteTableData })
        } else {
            notification.error({
                message: 'Depart only contain 1 airport, and Return cannot empty.'
            })
        }
    }

    handleDeleteRouteRow = key => {
        const { routeTableData } = this.state
        const newData = routeTableData.filter(item => item.key !== key)
        this.setState({ routeTableData: newData })
    }

    handleCellEdit = (key, dataIndex) => (e) => {
        const { routeTableData } = this.state
        const newData = [...routeTableData]
        const index = newData.findIndex(item => key === item.key)
        if (index > -1) {
            const item = newData[index]
            newData[index] = { ...item, [dataIndex]: e.target.value }
            this.setState({ routeTableData: newData })
        }
    }

    getTravelportConfigs = id => {
        return axios({
            method: 'get',
            url: `${apiUrl}/travelport_configs/${id}`,
            headers
        })
            .then(({ data }) => {
                data.data.forEach(item => {
                    item.inactive = item.inactive ? true : false
                })

                this.setState({ travelport_configs: data.data })
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    getTravelportConfig = id => {
        return axios({
            method: 'get',
            url: `${apiUrl}/travelport_config/${id}`,
            headers
        })
            .then(({ data }) => {
                this.setState({ travelport_configs: data.data })
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    deleteTravelportConfig = id => {
        return (
            axios({
                method: 'get',
                url: `${apiUrl}/travelport_config/delete/${id}`,
                headers
            })
                // eslint-disable-next-line no-unused-vars
                .then(({ data }) => {
                    this.getTravelportConfigs(this.props.match.params.id)
                    this.setState({ routeTableData: [] })
                })
                .catch(error =>
                    notification.error({
                        message: 'Error!!!',
                        description: apiErrorHandler(error)
                    })
                )
        )
    }

    editTravelportConfig = row => {
        const groupedRoutes = {}
        row.routes.split(';').forEach((route, index) => {
            const [departAirport, destination] = route.split('-')
            if (!groupedRoutes[departAirport]) {
                groupedRoutes[departAirport] = {
                    departAirport: departAirport,
                    returnAirport: destination,
                    key: index
                }
            } else {
                groupedRoutes[departAirport].returnAirport += ',' + destination
            }
        })

        const result = Object.values(groupedRoutes)

        this.setState({ routeTableData: result })

        const editData = {
            account_codes: row.account_codes,
            booking_date: [moment(row.booking_date_from), moment(row.booking_date_to)],
            travel_date: [moment(row.travel_date_from), moment(row.travel_date_to)],
            inactive: row.inactive
        }
        this.props.form.setFieldsValue(editData)
        this.toggleAddConfig()
        this.setState({ selected: row.id })
    }

    actionCell = (cell, row) => (
        <Can resource={RESOURCE} perform="edit">
            <Say yes>
                <Button onClick={() => this.editTravelportConfig(row)} type="primary">
                    <EditOutlined /> Edit
                </Button>
                <Button onClick={() => this.showConfirmDelete(row.id)} type="danger">
                    <UserDeleteOutlined /> Remove
                </Button>
            </Say>
        </Can>
    )

    toggleAddConfig = () => {
        this.setState({
            addConfigModal: !this.state.addConfigModal
        })
    }

    addConfig = () => {
        this.setState({ 
            selected: null,
            routeTableData: [],
            departAirport: '',
            returnAirport: ''
        })
        this.props.form.resetFields()
        this.toggleAddConfig()
    }

    addConfigFlight = () => {
        const { routeTableData } = this.state

        return new Promise(resolve => {
            this.props.form.validateFields((err, values) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }
                values.booking_date_from = moment(values.booking_date[0]).format('YYYY-MM-DD')
                values.booking_date_to = moment(values.booking_date[1]).format('YYYY-MM-DD')
                values.travel_date_from = moment(values.travel_date[0]).format('YYYY-MM-DD')
                values.travel_date_to = moment(values.travel_date[1]).format('YYYY-MM-DD')

                delete values.booking_date
                delete values.travel_date
                delete values.departAirport
                delete values.returnAirport

                values.airline_code = this.props.location.state
                values.supplier_id = this.props.match.params.id

                let routes = ''

                routeTableData.map(flight => {
                    const departAirport = flight.departAirport.trim()
                    const returnAirports = [...new Set(flight.returnAirport.split(',').map(airport => airport.trim()))]

                    returnAirports.map(airport => {
                        routes += `${departAirport}-${airport};`
                    })
                })

                values.routes = routes.slice(0, -1)

                let options = {
                    url: `${apiUrl}/travelport_config/insert`,
                    headers,
                    data: values,
                    method: 'post'
                }

                if (this.state.selected != null) {
                    options.url = `${apiUrl}/travelport_config/update`
                    options.data = { ...values, id: this.state.selected }
                }

                return axios(options)
                    .then(() => {
                        notification.success({
                            message: 'Successful!!!'
                        })
                        this.getTravelportConfigs(this.props.match.params.id)
                        this.toggleAddConfig()
                        this.setState({ selected: null })
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    statusCell = (cell, row) => (
        <Switch
            disabled={!check(RESOURCE, 'edit')}
            defaultChecked={!row.inactive}
            checked={row.inactive}
            onClick={() => this.showConfirm(row)}
        />
    )

    showConfirm = row => {
        Modal.confirm({
            title: `Are you sure to turn ${row.inactive ? 'on' : 'off'}?`,
            onOk: () => this.changeSupplierStatus(row)
        })
    }

    showConfirmDelete = id => {
        Modal.confirm({
            title: `Are you sure to delete?`,
            onOk: () => this.deleteTravelportConfig(id)
        })
    }

    changeSupplierStatus = row => {
        const { props } = this
        const supplier_id = props.match.params.id

        axios({
            method: 'post',
            url: `${apiUrl}/travelport_config/change-status`,
            headers,
            data: {
                id: row.id,
                supplier_id,
                inactive: !row.inactive
            }
        })
            .then(() => {
                this.getTravelportConfigs(supplier_id)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    render() {
        const { RangePicker } = DatePicker
        const { routeTableData, departAirport, returnAirport } = this.state

        let columns = [
            {
                key: 'booking_date_from',
                dataIndex: 'booking_date_from',
                title: 'Booking Date From',
                className: 'text-center'
            },
            {
                key: 'booking_date_to',
                dataIndex: 'booking_date_to',
                title: 'Booking Date To',
                className: 'text-center'
            },
            {
                key: 'travel_date_from',
                dataIndex: 'travel_date_from',
                title: 'Travel Date From',
                className: 'text-center'
            },
            {
                key: 'travel_date_to',
                dataIndex: 'travel_date_to',
                title: 'Travel Date To',
                className: 'text-center'
            },
            {
                key: 'routes',
                title: 'Routes',
                className: 'text-center',
                dataIndex: 'routes'
            },
            {
                key: 'account_codes',
                dataIndex: 'account_codes',
                title: 'Account codes',
                className: 'text-center'
            },
            {
                key: 'inactive',
                dataIndex: 'inactive',
                title: 'Status',
                className: 'text-center',
                render: this.statusCell
            }
        ]

        const routeColumns = [
            {
                title: 'Departure Airport',
                dataIndex: 'departAirport',
                key: 'departAirport'
            },
            {
                title: 'Return Airport',
                dataIndex: 'returnAirport',
                key: 'returnAirport',
                render: (text, record) => (
                    <TextArea
                        value={text}
                        onChange={this.handleCellEdit(record.key, 'returnAirport')}
                    />
                )
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure you want to delete this row?"
                        onConfirm={() => this.handleDeleteRouteRow(record.key)}
                        okText="Yes"
                        cancelText="No">
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                )
            }
        ]

        const formItemLayout = {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 18
            }
        }

        if (check(RESOURCE, 'edit')) {
            columns.push({
                key: 'dummy',
                title: 'Action',
                className: 'text-center',
                render: this.actionCell
            })
        }

        return (
            <div>
                <div>
                    <Can resource={RESOURCE} perform="edit">
                        <Say yes>
                            <div className="m-b-20">
                                <Button type="primary" onClick={this.addConfig}>
                                    <UserAddOutlined /> Add config
                                </Button>{' '}
                            </div>
                        </Say>
                    </Can>
                </div>
                <Table dataSource={this.state.travelport_configs} columns={columns} bordered rowKey="id" />
                <Can resource={RESOURCE} perform="edit">
                    <Say yes>
                        <Modal
                            title="Fare config"
                            visible={this.state.addConfigModal}
                            onClick={this.toggleAddConfig}
                            onCancel={this.toggleAddConfig}
                            onOk={() => {
                                this.addConfigFlight()
                            }}>
                            <Form>
                                <Form.Item label="Booking Date" hasFeedback {...formItemLayout}>
                                    {this.props.form.getFieldDecorator('booking_date', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'The Booking date from is not valid!'
                                            }
                                        ]
                                    })(<RangePicker />)}
                                </Form.Item>
                                <Form.Item label="Travel Date" hasFeedback {...formItemLayout}>
                                    {this.props.form.getFieldDecorator('travel_date', {
                                        rules: [{ required: true, message: 'Please enter your Travel Date from!' }]
                                    })(<RangePicker />)}
                                </Form.Item>
                                <Form.Item label="Routes">
                                    <Row gutter={8}>
                                        <Col span={9}>
                                            <Form.Item>
                                                <Input
                                                    placeholder="Departure Airport"
                                                    value={departAirport}
                                                    onChange={e => this.setState({ departAirport: e.target.value })}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item>
                                                <Input
                                                    placeholder="Return Airport"
                                                    value={returnAirport}
                                                    onChange={e => this.setState({ returnAirport: e.target.value })}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Button type="primary" onClick={this.handleAddRouteToTable}>
                                                Add to Table
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item>
                                    <Table
                                        dataSource={routeTableData}
                                        columns={routeColumns}
                                        pagination={false}
                                        rowKey={(record, index) => index}
                                    />
                                </Form.Item>
                                <Form.Item label="Account Codes" hasFeedback {...formItemLayout}>
                                    {this.props.form.getFieldDecorator('account_codes', {
                                        rules: [{ required: true, message: 'Please enter your account_codes!' }]
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Active" {...formItemLayout}>
                                    {this.props.form.getFieldDecorator('inactive', {
                                        valuePropName: 'checked'
                                    })(<Switch />)}
                                </Form.Item>
                            </Form>
                        </Modal>
                    </Say>
                </Can>
            </div>
        )
    }
}

const WrappedLogin = Form.create()(ManageSupplier)

export default WrappedLogin
