import axios from 'axios'
import { createAction } from '@'
import { getAuth } from '@misc'
import { $amenitiesGoQuo } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetGoQuoAmenities', async params => {
    $amenitiesGoQuo.setState(() => [])

    const auth = getAuth()

    const amenitiesGoQuo = await axios({
        url: `${ENDPOINT}/hotel/mappings/amenities/goquo`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...auth },
        data: {
            type: params.type
        }
    })

    let amenitiesArr = amenitiesGoQuo.data

    if (amenitiesArr.length > 0) $amenitiesGoQuo.setState(() => amenitiesArr)
})
