import { createAction } from '@'
import { SetFormValues } from '@form'
import { SetModalVisible } from '@modal'

export default createAction('OpenEditUser', async (item, onSuccess = () => {}) => {
    SetFormValues('UserForm', {
        onSuccess: onSuccess,
        id: item.id,
        name: item.name,
        email: item.email,
        role: item.role_id,
        external: item.external === 1
    })
    SetModalVisible('UserForm', true)
})
