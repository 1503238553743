import { compose, hoc } from '@'
import { useForm, useFormLoading } from '@form'
import GetHotelMapping from '../../actions/GetHotelMapping'

const $fieldProps = {
    supplier: { rules: [{ required: true, message: 'Please select a supplier' }] },
    hotel_ids: { rules: [{ required: true, message: 'Please enter hotel id(s)' }] }
}

const container = compose(
    hoc(props => {
        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                GetHotelMapping({
                    from: values.supplier,
                    ids: values.hotel_ids.split(';')
                })
            }

            return {
                name: 'RegionMappingForm',
                onSubmit
            }
        }, [])

        const $loading = useFormLoading($form.name)
        return {
            $props,
            $loading,
            $fieldProps,
            ...props
        }
    })
)

export default container
