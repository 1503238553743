import axios from 'axios'
import { createAction } from '@'
import { getAuth } from '@misc'
import { $logsRemoveDuplicate } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetHotelsDuplicate', async () => {
    $logsRemoveDuplicate.setState(() => [])

    const auth = getAuth()

    const logsRemoveDuplicateLst = await axios({
        url: `${ENDPOINT}/hotel/mappings/logs-delete-duplicate`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...auth }
    })

    if (logsRemoveDuplicateLst.data.length > 0) $logsRemoveDuplicate.setState(() => logsRemoveDuplicateLst.data)
})
