import React, { useState, useEffect } from 'react'
import { FormOutlined, EditOutlined, HistoryOutlined, UserAddOutlined } from '@ant-design/icons'
import { notification, Switch, Button, Modal, Table, Row, Col, Input } from 'antd'
import { Route, Link } from 'react-router-dom'
import axios from 'axios'
import _ from 'lodash'

import { Can, Say, check } from './can'
import appConfig from '../config'
import apiErrorHandler from '../api-error-handler'
import LogList from './LogList'
import { filterTable } from '@/search'

import ManageSupplier from './management/flight-supplier'

const { apiUrl } = appConfig

function SupplierList({ suppliers, getSuppliers, columns, product, setFormState }) {
    const [flagShowModal, setFlagShowModal] = useState(false)
    const [flagShowLog, setFlagShowLog] = useState(false)
    const [logs, setLogs] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [initialSuppliers, setInitialSuppliers] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    useEffect(() => {
        setInitialSuppliers(suppliers)
        setSupplierList(suppliers)
    }, [suppliers])

    const changeSupplierStatus = supplier => {
        axios({
            method: 'patch',
            url: `${apiUrl}/${product}/suppliers/${supplier.id}`,
            headers,
            data: {
                supplier_code: supplier.code,
                active: !!supplier.del_flag
            }
        })
            .then(() => {
                toggleSwitchModal()
                getSuppliers()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    const showLog = row => {
        return Promise.all([
            axios({
                method: 'get',
                url: `${appConfig.apiUrl}/logs`,
                headers,
                params: {
                    path: `/${product}/suppliers/${row.id}`,
                    operator: '='
                }
            }),
            axios({
                method: 'get',
                url: `${appConfig.apiUrl}/logs`,
                headers,
                params: {
                    path: `/${product}/suppliers/${row.id}/%`,
                    operator: 'like'
                }
            })
        ])
            .then(data => {
                const logs = [...data[0].data, ...data[1].data]

                setLogs(
                    _(logs)
                        .sortBy('created_at')
                        .reverse()
                        .value()
                )
                setFlagShowLog(true)
                setModalTitle(`Logs of supplier: ${row.code}`)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    const toggleSwitchModal = () => {
        setFlagShowModal(!flagShowModal)
    }

    const showConfirm = row => {
        Modal.confirm({
            title: `Are you sure to turn ${row.inactive ? 'on' : 'off'} ${row.name}?`,
            onOk: () => changeSupplierStatus(row),
            onCancel: () => toggleSwitchModal()
        })
    }

    const statusCell = (cell, row) => (
        <Switch
            disabled={!check(`${product}-supplier`, 'edit')}
            defaultChecked={!row.del_flag}
            checked={!row.del_flag}
            onClick={() => showConfirm(row)}
        />
    )

    const actionCell = (cell, row) => {
        return (
            <div>
                {(['flight'].includes(product) && ['Travelport'].includes(row.code)) ? (
                    <Link to={{pathname:`/flight/suppliers/travelport/${row.id}`, state: row.airline_code}}>
                        <Button ghost type="danger" icon={<FormOutlined />} />
                     </Link>
                ) : (
                    ''
                )}

                <Can resource={`${product}-supplier`} perform={'edit'}>
                    <Say yes>
                        <Button
                            ghost
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => setFormState('UPDATE', row)}
                        />
                    </Say>
                </Can>
                <Can resource={`${product}-supplier`} oneOfPerformeds={['create', 'edit']}>
                    <Say yes>
                        <Button type="dashed" icon={<HistoryOutlined />} onClick={() => showLog(row)} />
                    </Say>
                </Can>
            </div>
        )
    }

    const closeLog = () => {
        setFlagShowLog(false)
    }

    const onSearch = e => {
        const searchInput = e.target.value
        const suppliers = filterTable(searchInput, initialSuppliers, columns)
        setSupplierList(suppliers)
    }

    const tableColumns = [...columns]

    if (check(`${product}-supplier`, 'create') || check(`${product}-supplier`, 'edit')) {
        tableColumns.push(
            {
                key: 'del_flag',
                dataIndex: 'del_flag',
                title: 'Status',
                className: 'text-center',
                render: statusCell
            },
            {
                key: 'dummy',
                dataIndex: 'dummy',
                title: 'Action',
                className: 'text-center',
                render: actionCell
            }
        )
    }

    return (
        <div>
            <Route path={'/flight/suppliers/travelport/:id'} component={ManageSupplier}/>
            <Route
                exact
                path={`/${product}/suppliers`}
                render={() => {
                    return (
                        <div>
                            <Row>
                                <Col md={16}>
                                    <Can resource={`${product}-supplier`} perform={'create'}>
                                        <Say yes>
                                            <Button type="primary" icon={<UserAddOutlined />} onClick={() => setFormState('CREATE')}>
                                                Create
                                            </Button>
                                        </Say>
                                    </Can>
                                </Col>
                                <Col md={8}>
                                    <Input placeholder="Search" onChange={onSearch} />
                                </Col>
                            </Row>
                            <br />
                            <Table dataSource={supplierList} columns={tableColumns} bordered rowKey="id" />
                        </div>
                    )
                }
            }/>
            

            {/* log modal */}
            <Modal
                visible={flagShowLog}
                onClick={closeLog}
                style={{ minWidth: '840px' }}
                onCancel={() => setFlagShowLog(false)}
                title={modalTitle || ''}
                footer={null}>
                <LogList data={logs} />
            </Modal>
        </div>
    )
}

export default SupplierList
