import { createAction } from '@'
import { getAuth } from '@misc'
import axios from 'axios'
import GetAlerts from './GetAlerts'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('UpdateActiveAlert', async (record, value) => {
    const auth = getAuth()
    await axios({
        url: `${ENDPOINT}/alert-settings/active/${record.id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...auth },
        data: {
            is_active: value
        }
    })
    GetAlerts()
})
