import { useCallback, useMemo, useState } from 'react'
import { compose, hoc } from '@'
import ToggleUserStatus from '../../actions/ToggleUserStatus'

const container = compose(
    hoc(props => {
        const { item, onSuccess, loadingName = 'loading', onClickName = 'onClick' } = props

        const [loading, setLoading] = useState(false)

        const handleChangeStatus = useCallback(() => {
            Promise.resolve()
                .then(async () => {
                    setLoading(true)

                    await ToggleUserStatus({ id: item.id, del_flag: item.del_flag, onSuccess })
                })
                .finally(() => {
                    setLoading(false)
                })
        }, [item, setLoading])

        const handleClick = useCallback(() => {
            handleChangeStatus()
        }, [item, handleChangeStatus])

        const childProps = useMemo(
            () => ({
                [loadingName]: loading,
                [onClickName]: handleClick
            }),
            [loadingName, onClickName, loading, handleClick]
        )

        return {
            childProps,
            ...props
        }
    })
)

export default container
