import { useState, useCallback, useMemo } from 'react'
import { useStore } from '@'
import { $modalVisible } from '../stores'
import SetModalVisible from '../actions/SetModalVisible'

export default function useModal(callback = () => ({}), deps = []) {
    const [$form, setForm] = useState()
    const [$loading, setLoading] = useState()
    const { name, props } = useMemo(callback, deps)

    const hasForm = useMemo(() => !!$form, [$form])

    const [visible] = useStore([$modalVisible.subStore(name)])

    const handleOk = useCallback(() => {
        if ($form) {
            $form.submit()
            return
        }
        SetModalVisible(name, false)
    }, [name, $form])

    const handleCancel = useCallback(() => {
        SetModalVisible(name, false)
    }, [name])

    const zIndex = useMemo(() => {
        const defaultZIndex = 1000
        const subStores = Object.values($modalVisible.getSubStore())
        if (!visible) {
            return defaultZIndex // default
        }
        const totalModal = subStores.length
        const totalVisible = subStores.filter(i => i.state).length
        return 1000 - totalModal + totalVisible
    }, [visible])

    const $props = useMemo(
        () => ({
            ...Object.assign(
                {
                    visible: !!visible,
                    centered: true,
                    closable: false,
                    onOk: handleOk,
                    onCancel: handleCancel,
                    zIndex: zIndex
                },
                hasForm ? { maskClosable: !$loading, confirmLoading: $loading } : {}
            ),
            ...props
        }),
        [visible, handleOk, handleCancel, zIndex, hasForm, $loading, props]
    )

    const $modal = useMemo(
        () => ({
            name,
            open: () => {
                SetModalVisible(name, true)
            },
            close: () => {
                SetModalVisible(name, false)
            },
            setForm: form => {
                setForm(form)
            },
            setLoading: loading => {
                setLoading(loading)
            }
        }),
        [name, setForm, setLoading]
    )

    return [$props, $modal]
}
