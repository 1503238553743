import { createAction } from '@'
import axios from 'axios'
import querystring from 'querystring'
import { $hotelInfoMapping } from '../stores'

const ENDPOINT = process.env.REACT_APP_HOTELINFO_ENDPOINT

export default createAction('GetHotelInfo', async mappings => {
    const hotelInfoMapping = $hotelInfoMapping.getState()

    await Promise.all(
        mappings.map(async item => {
            const key = `${item.supplier_code}-${item.hotel_id}`

            if (hotelInfoMapping && hotelInfoMapping[key]) {
                return
            }

            const qs = {
                supplier_code: item.supplier_code,
                hotel_id: item.hotel_id
            }

            if (qs.supplier_code === 'GoQuo') {
                delete qs.supplier_code
            }

            try {
                const { data } = await axios({
                    url: ENDPOINT + `/hotel?${querystring.stringify(qs)}`,
                    method: 'GET'
                })
                $hotelInfoMapping.setState(s => ({
                    ...s,
                    [key]: { name: data.name || ' ', address: data.address || ' ' }
                }))
            } catch (e) {
                $hotelInfoMapping.setState(s => ({
                    ...s,
                    [key]: { name: ` `, address: ` ` }
                }))
            }
        })
    )
})
