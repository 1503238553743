import { useCallback, useEffect, useMemo } from 'react'
import { compose, hoc, useStore } from '@'
import GetRoles from '../../actions/GetRoles'
import GetUsers from '../../actions/GetUsers'
import OpenCreateUser from '../../actions/OpenCreateUser'
import OpenEditUser from '../../actions/OpenEditUser'
import { $users, $roles } from '../../stores'

const container = compose(
    hoc(props => {
        const [users, roles] = useStore([$users, $roles])

        const powerMap = useMemo(() => {
            const map = {}
            const permissions = JSON.parse(localStorage.getItem('permissions')) || []
            permissions.forEach(item => {
                if (item.resource_code === 'user') {
                    map[item.permission_code] = true
                }
            })
            return map
        }, [])

        useEffect(() => {
            GetRoles().catch(console.error)
        }, [])

        useEffect(() => {
            GetUsers().catch(console.error)
        }, [])

        const refresh = useCallback(() => {
            GetUsers().catch(console.error)
        }, [])

        const handleOpenCreateUser = useCallback(() => {
            OpenCreateUser(refresh)
        }, [refresh])

        const handleOpenEditUser = useCallback(
            item => {
                OpenEditUser(item, refresh)
            },
            [refresh]
        )

        return {
            powerMap,
            users,
            roles,
            refresh,
            handleOpenCreateUser,
            handleOpenEditUser,
            ...props
        }
    })
)

export default container
