import { useEffect, useMemo } from 'react'
import { compose, hoc, useStore } from '@'
import { withModalV4, useModal } from '@modal'
import { useForm, useFormLoading, useFormValues } from '@form'
import CreateRole from '../../actions/CreateRole'
import EditRole from '../../actions/EditRole'
import { $resourcePermissions, $permissions } from '../../stores'
import GetResourcePermissions from '../../actions/GetResourcePermissions'
import GetPermissions from '../../actions/GetPermissions'

const $fieldProps = {
    id: { rules: [{ required: true, message: 'Role Id is required' }] },
    description: { rules: [{ required: true, message: 'Description is required' }] },
    permission: {
        initialValue: false,
        valuePropName: 'checked'
    }
}

const container = compose(
    hoc(props => {
        const { $modal } = props

        const [resourcePermissions, permissions] = useStore([$resourcePermissions, $permissions])

        useEffect(() => {
            GetResourcePermissions().catch(console.error)
            GetPermissions().catch(console.error)
        }, [])

        const resources = useMemo(() => {
            if (!resourcePermissions) {
                return []
            }
            const map = {}
            resourcePermissions.forEach(item => {
                map[item.resource_code] = map[item.resource_code] || {
                    resource_code: item.resource_code,
                    resource_name: item.resource_name,
                    resource_parent: item.resource_parent
                }
                map[item.resource_code][item.permission_code] = true
            })
            Object.entries(map).forEach(([k, v]) => {
                if (v.resource_parent) {
                    map[v.resource_parent].children = map[v.resource_parent].children || []
                    map[v.resource_parent].children.push(v)
                    delete map[k]
                }
            })
            return Object.values(map)
        }, [resourcePermissions])

        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                const permissions = []
                Object.entries(values.permissions).forEach(([resource_code, resource]) => {
                    Object.entries(resource).forEach(([permission_code, permission]) => {
                        if (!permission) {
                            return
                        }
                        permissions.push({
                            resource_code,
                            permission_code
                        })
                    })
                })
                values.permissions = permissions
                if (values.isEdit) {
                    await EditRole(values)
                } else {
                    await CreateRole(values)
                }
                $modal.close()
            }

            return {
                name: 'RoleForm',
                onSubmit,
                props: { colon: false, layout: 'vertical', wrapperCol: { flex: 1 } }
            }
        }, [$modal])

        const $loading = useFormLoading($form.name)

        $modal.setForm($form)
        $modal.setLoading($loading)

        return {
            resources,
            permissions,
            $form,
            $props,
            $loading,
            $fieldProps,
            ...props
        }
    }),
    withModalV4(props => {
        const $values = useFormValues('RoleForm')

        const isEditMode = useMemo(() => {
            return !!($values && $values.id)
        }, [$values])

        const [$props, $modal] = useModal(
            () => ({
                name: 'RoleForm',
                props: {
                    title: isEditMode ? 'Edit Role' : 'Create Role',
                    width: 700,
                    bodyStyle: { paddingTop: 0, paddingBottom: 0, height: 520 },
                    centered: true,
                    destroyOnClose: true,
                    okText: isEditMode ? 'Update' : 'Create'
                }
            }),
            [isEditMode]
        )

        return {
            $props,
            $modal,
            ...props
        }
    })
)

export default container
