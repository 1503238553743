import { compose, hoc, useStore } from '@'
import { $logsRemoveDuplicate } from '../../stores'
import { useForm, useFormLoading } from '@form'
import GetHotelsDuplicate from '../../actions/GetHotelsDuplicate'
import GetLogsRemove from '../../actions/GetLogsRemovedHotels'
import { notification } from 'antd'
import apiErrorHandler from '../../../api-error-handler'

const $fieldProps = {
    ids: { rules: [{ required: true, message: 'Please enter goquo ids list, split by ;' }] },
    percent: {
        rules: [
            {
                required: false,
                message: 'Please enter proper similarity percentage.'
            }
        ],
        initialValue: 30
    },
    distance: {
        rules: [
            {
                required: false,
                message: 'Please enter proper distance value.'
            }
        ],
        initialValue: 500
    }
}

const container = compose(
    hoc(props => {
        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                GetHotelsDuplicate({
                    ids: values.ids,
                    percent: values.percent || 30,
                    distance: values.distance || 500
                }).catch(error =>
                    notification.error({
                        message: 'Error!!!',
                        description: apiErrorHandler(error)
                    })
                )
            }
            return {
                name: 'CompareHotelForm',
                onSubmit
            }
        }, [])

        const $loading = useFormLoading($form.name)
        const [logsRemoveDuplicate] = useStore([$logsRemoveDuplicate])
        const getLogs = async () => {
            GetLogsRemove().catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
        }
        return { getLogs, logsRemoveDuplicate, $props, $loading, $fieldProps, ...props }
    })
)

export default container
