import { createAction } from '@'
import axios from 'axios'
import { message } from 'antd'

const responseHandler = (res, { skipError }) => {
    try {
        const { data } = res
        if (skipError) {
            return data
        }
        if (!data) {
            throw new Error('Not found data')
        }
        if (!data.success) {
            throw new Error(data.message || 'Unknown error')
        }
        return data
    } catch (e) {
        message.error(e.message, 15)
        throw e
    }
}

export default createAction('Fetch', async (options, config = {}) => {
    try {
        const res = await axios(options)
        return responseHandler(res, config)
    } catch (e) {
        if (e.response) {
            return responseHandler(e.response, config)
        }
        throw e
    }
})
