import { LoadingOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Card, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Spin } from 'antd'
import React from 'react'
import container from './container'
import { Root } from './styled'

const LogsInputForm = props => {
    const { searchKeys, fetching, handleStopFetching, $props, $loading, $values, $fieldProps } = props
    return (
        <Spin spinning={$loading}>
            <Form layout={'inline'} {...$props}>
                <Card
                    style={{ flex: 1 }}
                    size={'small'}
                    title={'Logs Viewer'}
                    extra={
                        <Row type={'flex'} gutter={8} align={'middle'}>
                            <Col>
                                {fetching ? (
                                    <div>
                                        <strong>Fetching {fetching.date.format(`YYYY-MM-DD`)} ...</strong>
                                        <span> </span>
                                        <Button onClick={handleStopFetching} type={'danger'} icon={<LoadingOutlined />}>
                                            Stop
                                        </Button>
                                    </div>
                                ) : (
                                    <Button htmlType={'submit'} type={'primary'}>
                                        Submit
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    }>
                    <Spin spinning={!!fetching}>
                        <Root>
                            <Form.Item name={'app'} label={'App'} {...$fieldProps.app}>
                                <Radio.Group>
                                    <Radio value={'hotel'}>Hotel</Radio>
                                    <Radio value={'flight'}>Flights</Radio>
                                    <Radio value={'tour-gateway'}>Tour</Radio>
                                    <Radio value={'car'}>Car</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <div>
                                <Form.Item name={'time'} label={'Time'} {...$fieldProps.time}>
                                    <Radio.Group>
                                        <Radio value={0}>No limit</Radio>
                                        <Radio value={1}>Custom</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {$values && $values.time === 1 ? (
                                    <Form.Item name={'range'} label={'Range'} {...$fieldProps.range}>
                                        <DatePicker.RangePicker />
                                    </Form.Item>
                                ) : null}
                            </div>
                            <div>
                                <Form.Item name={'release'} label={'Release'} {...$fieldProps.release}>
                                    <AutoComplete
                                        placeholder={'Release'}
                                        style={{ width: 230 }}
                                        dataSource={['staging', 'production']}
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    name={'keys'}
                                    label={'Search By'}
                                    className={'width-auto'}
                                    {...$fieldProps.keys}>
                                    <Checkbox.Group options={searchKeys} />
                                </Form.Item>
                            </div>
                            {searchKeys
                                .filter(item => {
                                    if ($values && $values.keys) {
                                        return $values.keys.indexOf(item.value) > -1
                                    }
                                    return false
                                })
                                .map(item => (
                                    <div key={item.value}>
                                        <Form.Item name={item.value} label={item.label} {...$fieldProps[item.value]}>
                                            <Input placeholder={item.value.toUpperCase()} style={{ width: 230 }} />
                                        </Form.Item>
                                    </div>
                                ))}
                        </Root>
                    </Spin>
                </Card>
            </Form>
        </Spin>
    )
}

export default container(LogsInputForm)
