import { compose, hoc, useStore } from '@'
import { SetFormValues, useForm, useFormLoading, useFormValues } from '@form'
import { useCallback, useEffect, useMemo } from 'react'
import GetGoogleCloudLogs from '../../actions/GetGoogleCloudLogs'
import { $fetching } from '../../stores'

const initValue = {
    app: 'hotel',
    time: 0,
    release: 'staging',
    keys: []
}

const searchMap = {
    hotel: [
        { value: 'rid', label: 'Request ID' },
        { value: 'lid', label: 'Log ID' },
        { value: 'ref', label: 'Client Ref' },
        { value: 'bid', label: 'Booking ID' }
    ],
    flights: [
        { value: 'sid', label: 'Search ID' },
        { value: 'lid', label: 'Log ID' },
        { value: 'tracking_id', label: 'Tracking ID' }
    ],
    'tour-gateway': [
        { value: 'rid', label: 'Request ID' },
        { value: 'sid', label: 'Search ID' },
        { value: 'lid', label: 'Log ID' }
    ],
    car: [
        { value: 'rid', label: 'Request ID' },
        { value: 'lid', label: 'Log ID' },
        { value: 'vid', label: 'Vehicle ID' },
        { value: 'bid', label: 'Booking ID' },
        { value: 'ref', label: 'Client Ref' }
    ]
}

const $fieldProps = ({ $values }) => {
    const base = {
        app: {
            rules: [{ required: true }]
        },
        time: {
            rules: [{ required: true }]
        },
        range: {
            rules: [{ required: true }]
        },
        release: { rules: [{ required: true }] },
        keys: { rules: [{ required: true, message: 'You have to choose at least 1 option' }] },
        value: { rules: [{ required: true }] }
    }

    if ($values && $values.keys) {
        $values.keys.forEach(name => {
            base[name] = { rules: [{ required: true }] }
        })
    }

    return base
}

const container = compose(
    hoc(props => {
        const [fetching] = useStore([$fetching])

        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                await GetGoogleCloudLogs(values)
            }

            return {
                name: 'LogsInputForm',
                onSubmit
            }
        }, [])

        const $loading = useFormLoading($form.name)

        const $values = useFormValues($form.name)

        const app = useMemo(() => {
            return $values && $values.app
        }, [$values])

        const searchKeys = useMemo(() => {
            if (!app) {
                return []
            }
            return searchMap[app]
        }, [app])

        useEffect(() => {
            if (app) {
                SetFormValues('LogsInputForm', s => ({ ...s, keys: [] }))
            }
        }, [app])

        useEffect(() => {
            $fetching.setState(() => undefined)
            SetFormValues('LogsInputForm', initValue)
        }, [])

        const handleStopFetching = useCallback(() => {
            $fetching.setState(() => undefined)
        }, [])

        return {
            $props,
            $loading,
            $values,
            $fieldProps: useMemo(() => $fieldProps({ $values }), []),
            searchKeys,
            fetching,
            handleStopFetching,
            ...props
        }
    })
)

export default container
