import React from 'react'
import { DollarOutlined } from '@ant-design/icons'
import { Card, notification, Row, Col, Statistic } from 'antd'
import { format, startOfWeek, endOfWeek } from 'date-fns'
import axios from 'axios'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'

const headers = JSON.parse(localStorage.getItem('headers') || '{}')

class BookingSummary extends React.Component {
    constructor(props) {
        super(props)

        let startDateOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 })
        let endDateOfWeek = endOfWeek(new Date(), { weekStartsOn: 1 })
        startDateOfWeek = format(startDateOfWeek, 'dd')
        endDateOfWeek = format(endDateOfWeek, 'dd MMM yyyy')

        this.state = {
            today: format(new Date(), 'dd MMM yyyy'),
            thisWeek: `${startDateOfWeek} - ${endDateOfWeek}`,
            thisMonth: format(new Date(), 'MMM yyyy'),
            exchange: {},
            summary: {
                today: '',
                week: '',
                month: '',
                earning: []
            }
        }
    }

    componentDidMount() {
        this.getData()
        this.getExchangeRates()
    }

    UNSAFE_componentWillReceiveProps({ product }) {
        if (product !== this.props.product) {
            setTimeout(() => this.getData(), 0)
        }
    }

    getExchangeRates() {
        axios({
            method: 'get',
            url: `${appConfig.apiUrl}/currency-rates/USD`,
            headers
        })
            .then(({ data }) => {
                this.setState({ exchange: data.quotes || {} })
            })
            .catch(() => {
                this.setState({ exchange: {} })
            })
    }

    getData() {
        axios({
            method: 'get',
            url: `${appConfig.apiUrl}/${this.props.product}/bookings/summary`,
            headers
        })
            .then(({ data }) => {
                this.setState({ summary: data })
            })
            .catch(error => {
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
                this.setState({
                    summary: {
                        today: '',
                        week: '',
                        month: '',
                        earning: []
                    }
                })
            })
    }

    render() {
        const { summary, exchange, today, thisWeek, thisMonth } = this.state

        const sellingInUSD = (summary.earning || []).reduce((total, item) => {
            const rate = item.currency === 'USD' ? 1 : exchange[item.currency + 'USD']
            total += item.selling * (rate || 0)

            return total
        }, 0)

        return (
            <Row gutter={16}>
                <Col md={6} xs={24}>
                    <Card>
                        <Statistic
                            title="Today"
                            value={summary.today}
                            valueStyle={{ color: 'darkorchid' }}
                            suffix={`/${today}`}
                        />
                    </Card>
                </Col>
                <Col md={6} xs={24}>
                    <Card>
                        <Statistic
                            title="Weekly"
                            value={summary.week}
                            valueStyle={{ color: 'darkorchid' }}
                            suffix={`/${thisWeek}`}
                        />
                    </Card>
                </Col>
                <Col md={6} xs={24}>
                    <Card>
                        <Statistic
                            title="Monthly"
                            value={summary.month}
                            valueStyle={{ color: 'darkorchid' }}
                            suffix={`/${thisMonth}`}
                        />
                    </Card>
                </Col>
                <Col md={6} xs={24}>
                    <Card>
                        <Statistic
                            title="Total selling this month"
                            value={sellingInUSD.toFixed(2)}
                            valueStyle={{ color: 'darkorchid' }}
                            prefix={<DollarOutlined />}
                        />
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default BookingSummary
