import { useCallback, useEffect, useMemo } from 'react'
import { compose, hoc, useStore } from '@'
import GetRoles from '../../actions/GetRoles'
import OpenCreateRole from '../../actions/OpenCreateRole'
import OpenEditRole from '../../actions/OpenEditRole'
import { $roles } from '../../stores'

const container = compose(
    hoc(props => {
        const [roles] = useStore([$roles])

        const powerMap = useMemo(() => {
            const map = {}
            const permissions = JSON.parse(localStorage.getItem('permissions')) || []
            permissions.forEach(item => {
                if (item.resource_code === 'role') {
                    map[item.permission_code] = true
                }
            })
            return map
        }, [])

        useEffect(() => {
            GetRoles().catch(console.error)
        }, [])

        const refresh = useCallback(() => {
            GetRoles().catch(console.error)
        }, [])

        const handleOpenCreateRole = useCallback(() => {
            OpenCreateRole(refresh)
        }, [refresh])

        const handleOpenEditRole = useCallback(
            item => {
                OpenEditRole(item, refresh)
            },
            [refresh]
        )

        return {
            powerMap,
            roles,
            refresh,
            handleOpenCreateRole,
            handleOpenEditRole,
            ...props
        }
    })
)

export default container
