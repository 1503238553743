import axios from 'axios'
import { createAction } from '@'
import { getAuth } from '@misc'
import { $amenitiesUnmap } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('SaveAmenitiesMapping', async params => {
    const auth = getAuth()

    $amenitiesUnmap.setState(() => [])
    try {
        await axios({
            url: `${ENDPOINT}/hotel/mappings/amenities/createAmenity`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...auth },
            data: {
                data: params
            }
        })
    } catch (error) {
        console.log(error)
    }
})
