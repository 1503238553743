import { message } from 'antd'

export const loginErrorHandle = ({ response: res }) => {
    if (!res) {
        return
    }
    if (res.status === 400 || res.status === 401 || res.status === 404) {
        localStorage.clear()
        message.error('Session Timeout')
        window.location.href = `${window.location.origin}/#/login`
    }
}

export const fetchErrorHandler = res => {
    try {
        const { data } = res
        if (!data) {
            throw new Error('Not found data')
        }
        if (!data.success) {
            throw new Error(data.message || 'Unknown error')
        }
        return data
    } catch (e) {
        message.error(e.message, 15)
        throw e
    }
}

export const getAuth = () => {
    const headers = localStorage.getItem('headers')
    try {
        return JSON.parse(headers)
    } catch (e) {
        return {}
    }
}

export const getCurrentUser = () => {
    const user = localStorage.getItem('user')
    try {
        return JSON.parse(user)
    } catch (e) {
        return {}
    }
}
