import axios from 'axios'
import { createAction } from '@'
import { $airportSuggestions } from '../stores'
import { getAuth, loginErrorHandle } from '../../@misc'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetAirportSuggestion', async value => {
    $airportSuggestions.setState(() => undefined)

    window.__mappingAirportSuggestion = value

    const auth = getAuth()

    try {
        const res = await axios({
            url: `${ENDPOINT}/proxy`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...auth },
            data: {
                url: `https://www.air-port-codes.com/api/v1/autocomplete`,
                method: 'POST',
                json: true,
                headers: {
                    Referer: 'https://www.air-port-codes.com/',
                    'APC-Auth': '96dc04b3fb'
                },
                form: {
                    limit: 10,
                    term: value
                },
                jar: false
            }
        })

        if (value !== window.__mappingAirportSuggestion) {
            return
        }

        const results = (res.data.body.airports || []).map(item => ({
            text: `${item.city}, ${item.country.name} (${item.iata} - ${item.name})`,
            value: item.iata
        }))

        $airportSuggestions.setState(() => results)
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }
})
