import React from 'react'
import { Divider, Button, Col, Row } from 'antd'
import LoginForm from '../LoginForm'
import GoogleLogin from '../GoogleLogin'
import textLogo from 'assets/images/logo-text.png'
import bg from 'assets/images/auth-bg.jpg'
import container from './container'

const sidebarBackground = {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: '100vh',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
}

const LoginPage = props => {
    const { byEmail, handleUseEmail } = props
    return (
        <div style={sidebarBackground}>
            <Row style={{ display: 'flex', justifyContent: 'center', width: '75%', margin: '0 auto' }}>
                <Col md={10} lg={8} style={{ background: 'white', padding: 15 }}>
                    <img src={textLogo} alt="logo" width="100%" />
                    <br />
                    <Divider>Login</Divider>
                    <div>
                        {byEmail ? (
                            <div>
                                <LoginForm />
                                <Divider>OR</Divider>
                            </div>
                        ) : null}
                        <GoogleLogin />
                        {!byEmail ? (
                            <div>
                                <Divider>OR</Divider>
                                <Button type={'primary'} block onClick={handleUseEmail}>
                                    Sign in with Email & Password
                                </Button>
                            </div>
                        ) : null}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default container(LoginPage)
