import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Route, Link } from 'react-router-dom'
import { EditOutlined, FormOutlined, HistoryOutlined, UserAddOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Switch, Row, Col, Input, notification } from 'antd'
import _ from 'lodash'

import { Can, Say, check } from './can'
import appConfig from '../config'
import apiErrorHandler from '../api-error-handler'
import LogList from './LogList'
import { filterTable } from '@/search'

const { apiUrl } = appConfig

function UserList({ users, columns, manageUser, product, setFormState, history, location }) {
    const [flagShowLog, setFlagShowLog] = useState(false)
    const [logs, setLogs] = useState([])
    const [modalTitle, setModalTitle] = useState('')
    const [initialUsers, setInitialUsers] = useState([])
    const [userList, setUserList] = useState([])
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    useEffect(() => {
        setInitialUsers(users)
        setUserList(users)
    }, [users])

    const showLog = row => {
        return Promise.all([
            axios({
                method: 'get',
                url: `${apiUrl}/logs`,
                headers,
                params: {
                    path: `/${product}/users/${row.id}`,
                    operator: '='
                }
            }),
            axios({
                method: 'get',
                url: `${apiUrl}/logs`,
                headers,
                params: {
                    path: `/${product}/users/${row.id}/%`,
                    operator: 'like'
                }
            })
        ])
            .then(data => {
                const logs = [...data[0].data, ...data[1].data]

                setLogs(
                    _(logs)
                        .sortBy('created_at')
                        .reverse()
                        .value()
                )
                setFlagShowLog(true)
                setModalTitle(`Logs of user ${row.email}:`)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    const onClickedEdit = user => {
        setFormState('UPDATE', user)
    }

    const actionCell = (cell, row) => {
        return (
            <div>
                {['hotel', 'flight', 'tour'].includes(product) ? (
                    <Can resource={`${product}-user`} perform={'edit'}>
                        <Say yes>
                            <Button ghost type="primary" icon={<EditOutlined />} onClick={() => onClickedEdit(row)} />
                        </Say>
                    </Can>
                ) : (
                    ''
                )}
                <Link to={`/${product}/users/${row.id}`} onClick={() => history.push(location)}>
                    <Button ghost type="danger" icon={<FormOutlined />} />
                </Link>
                <Can resource={`${product}-user`} oneOfPerformeds={['create', 'edit']}>
                    <Say yes>
                        <Button type="dashed" icon={<HistoryOutlined />} onClick={() => showLog(row)} />
                    </Say>
                </Can>
            </div>
        )
    }

    const closeLog = () => {
        setFlagShowLog(false)
        setModalTitle('')
    }

    const onSearch = e => {
        const searchInput = e.target.value
        const userList = filterTable(searchInput, initialUsers, columns)
        setUserList(userList)
    }

    const tableColumns = [...columns]

    if (check(`${product}-user`, 'edit')) {
        tableColumns.push(
            {
                key: 'del_flag',
                dataIndex: 'del_flag',
                title: 'Status',
                className: 'text-center',
                render: cell => <Switch disabled checked={!cell} />
            },
            {
                key: 'dummy',
                dataIndex: 'dummy',
                title: 'Action',
                className: 'text-center',
                // fixed: 'right',
                render: actionCell
            }
        )
    }

    return (
        <div>
            <Route path={`/${product}/users/:id`} component={manageUser} />
            <Route
                exact
                path={`/${product}/users`}
                render={() => {
                    return (
                        <div>
                            <Row>
                                <Col md={16}>
                                    <Can resource={`${product}-user`} perform={'create'}>
                                        <Say yes>
                                            <Button
                                                type="primary"
                                                icon={<UserAddOutlined />}
                                                onClick={() => setFormState('CREATE')}>
                                                Create
                                            </Button>
                                        </Say>
                                    </Can>
                                </Col>
                                <Col md={8}>
                                    <Input placeholder="Search" onChange={onSearch} />
                                </Col>
                            </Row>
                            <br />
                            <Table dataSource={userList} columns={tableColumns} bordered rowKey="id" />
                        </div>
                    )
                }}
            />

            {/* log modal */}
            <Modal
                visible={flagShowLog}
                onClick={closeLog}
                style={{ minWidth: '840px' }}
                onCancel={() => setFlagShowLog(false)}
                title={modalTitle || ''}
                footer={null}>
                <LogList data={logs} />
            </Modal>
        </div>
    )
}

export default UserList
