import React from 'react'
import { LogoutOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Modal, Menu, Dropdown, notification, Card, Input, Avatar } from 'antd'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import _ from 'lodash'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'

// import logodarkicon from '../../assets/images/logo-icon.png'

const avatar = 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png'

class RightHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    UNSAFE_componentWillMount() {
        this.setState({
            headers: JSON.parse(localStorage.getItem('headers') || '{}'),
            user: JSON.parse(localStorage.getItem('user') || '{}')
        })
    }

    logout = () => {
        localStorage.clear()
        this.props.history.push('/login')
    }

    changePassword = e => {
        e.preventDefault()
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    await axios({
                        method: 'post',
                        url: `${appConfig.apiUrl}/change-password`,
                        headers: this.state.headers,
                        data: _.pick(values, ['password', 'new_password'])
                    })
                    notification.success({
                        description: 'Change password success'
                    })
                } catch (error) {
                    notification.error({
                        message: 'Error!!!',
                        description: apiErrorHandler(error)
                    })
                }
                window.location.reload()
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form

        // const { theme, layout } = this.props
        // let className = styled.right

        // if (theme === 'dark' && layout === 'topmenu') {
        //     className = `${styled.right}  ${styled.dark}`
        // }

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        }

        const menu = (
            <Menu>
                {/* <Menu.Item key="1" onClick={() => this.setState({ showChangePassword: true })}>
                    <Icon type="lock" /> Change Password
                </Menu.Item> */}
                <Menu.Item key="2" onClick={this.logout}>
                    <LogoutOutlined /> Logout
                </Menu.Item>
            </Menu>
        )

        return (
            <div
                style={{
                    float: 'right',
                    height: '100%',
                    overflow: 'hidden',
                    marginRight: 24
                }}>
                <Dropdown overlay={menu}>
                    <Card.Meta
                        avatar={<Avatar src={this.state.user.avatar || avatar} />}
                        description={this.state.user.name}
                    />
                </Dropdown>

                <Modal
                    visible={this.state.showChangePassword}
                    onCancel={() => this.setState({ showChangePassword: false })}
                    onSubmit={this.changePassword}
                    onOk={this.changePassword}
                    title="Change Password">
                    <Form {...formItemLayout} onSubmit={this.changePassword}>
                        <Form.Item label="Old Password" hasFeedback>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input old password!'
                                    },
                                    {
                                        validator: this.validateToNextPassword
                                    }
                                ]
                            })(<Input.Password />)}
                        </Form.Item>
                        <Form.Item label="New Password" hasFeedback>
                            {getFieldDecorator('new_password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input new password!'
                                    },
                                    {
                                        validator: this.validateToNextPassword
                                    }
                                ]
                            })(<Input.Password />)}
                        </Form.Item>
                        <Form.Item label="Confirm Password" hasFeedback>
                            {getFieldDecorator('confirm_password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please confirm your password!'
                                    },
                                    {
                                        validator: this.compareToFirstPassword
                                    }
                                ]
                            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default withRouter(Form.create({ name: 'change_password' })(RightHeader))
