// https://www.iconfont.cn/search
import React from 'react'

const Svg = ({ children, ...otherProps }) => {
    return (
        <svg
            viewBox="0 0 1024 1024"
            data-icon="line"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
            focusable="false"
            {...otherProps}>
            {children}
        </svg>
    )
}

export const IconHotel = () => (
    <Svg viewBox="0 0 1024 1024">
        <path d="M677.647059 1024V602.352941l271.058823 100.833883v315.934117z m103.664941-298.164706L692.705882 693.609412v76.649412l88.576 25.840941V725.835294z m0 134.02353L692.705882 827.723294v76.649412l88.576 25.901176v-70.294588z m111.073882-101.857883L803.809882 725.835294v76.649412l88.576 25.901176v-70.26447z m0 134.174118l-88.576-32.316235v76.8l88.576 25.840941v-70.204236zM406.588235 0l240.941177 150.588235v873.411765h-240.941177V0zM75.294118 1024V180.705882l301.447529-180.705882v1024m-139.565176-220.611765l104.658823-65.716706v-88.847058L237.176471 716.8v86.528z m0-142.004706l104.658823-65.716705v-88.877177l-104.658823 68.035765v86.528z m0-141.94447l104.658823-65.716706v-88.937412l-104.658823 68.035765v86.618353z m0-141.944471L341.835294 311.717647V222.870588L237.176471 290.816v86.678588z m0-142.095059l104.628705-65.716705V80.805647L237.176471 148.781176v86.618353zM118.061176 878.200471l90.172236-56.621177v-85.955765L118.061176 794.202353v83.998118z m0-141.944471l90.172236-56.681412v-85.895529L118.061176 652.197647v84.058353z m0-142.004706l90.172236-56.681412v-85.985882L118.061176 510.192941v84.058353z m0-142.004706l90.172236-56.621176v-85.985883L118.061176 368.248471v83.998117z m0-141.94447l90.172236-56.621177V167.695059L118.061176 226.273882V310.211765z" />
    </Svg>
)

export const IconAirport = () => (
    <Svg viewBox="0 0 1024 1024">
        <path d="M1005.824 43.136L967.232 5.76s-70.784-23.04-120 26.88c-82.688 84.224-193.856 197.12-193.856 197.12L108.544 150.784l-68.416 62.72L444.16 447.68l-147.968 145.28-248.32 16.768-47.616 17.152 254.336 140.8L390.4 1023.616l18.752-48.128 15.68-238.272s417.536-424.32 581.184-590.848c40.064-40.512-0.128-103.232-0.128-103.232M592.576 597.184l201.792 377.664 53.504-60.48-55.68-500.224-199.616 183.04" />
    </Svg>
)

export const IconCity = () => (
    <Svg viewBox="0 0 1024 1024">
        <path d="M722.261 656.256c63.147-85.205 88.406-150.187 88.406-231.125a294.4 294.4 0 0 0-37.76-144.768 42.667 42.667 0 0 1 74.368-41.814A379.733 379.733 0 0 1 896 425.131c0 100.992-31.872 183.04-105.173 281.941-45.27 61.141-128.214 148.65-249.472 263.68a42.667 42.667 0 0 1-59.52-0.768C365.867 854.187 285.14 767.061 239.06 707.883 162.645 609.749 128 525.696 128 425.13c0-211.243 171.947-382.464 384-382.464 69.76 0 136.917 18.602 195.67 53.333a42.667 42.667 0 1 1-43.393 73.472A298.24 298.24 0 0 0 512 128c-164.992 0-298.667 133.12-298.667 297.13 0 79.489 27.435 146.049 93.014 230.358 39.552 50.773 108.458 125.824 206.378 224.597C614.272 782.38 684.288 707.5 722.261 656.256z" />
        <path d="M514.475 613.547a192 192 0 1 1 0-384 192 192 0 0 1 0 384z m0-85.334a106.667 106.667 0 1 0 0-213.333 106.667 106.667 0 0 0 0 213.333z" />
    </Svg>
)

export const IconTaxes = () => (
    <Svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM9.33333 11.3333H4.66667V10H9.33333V11.3333ZM11.3333 8.66667H4.66667V7.33333H11.3333V8.66667ZM11.3333 6H4.66667V4.66667H11.3333V6Z"
            fill="white"
        />
    </Svg>
)
