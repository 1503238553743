import React from 'react'
import { Card, Form, Select, Input, Button, Spin, Row, Col } from 'antd'
import container from './container'

const CardForm = props => {
    const { users, $props, $loading, $fieldProps } = props
    return (
        <Spin spinning={$loading}>
            <Form {...$props} layout={'inline'}>
                <Card
                    size={'small'}
                    title={'Issue Card'}
                    style={{ flex: 1 }}
                    extra={
                        <Row type={'flex'} gutter={8} align={'middle'}>
                            <Col>
                                <Button htmlType={'submit'} type={'primary'}>
                                    Issue
                                </Button>
                            </Col>
                        </Row>
                    }>
                    <Row>
                        <Form.Item name={'user'} label={'User'} {...$fieldProps.user}>
                            <Select
                                disabled={!users}
                                loading={!users}
                                style={{ width: 200 }}
                                placeholder={'Please select an user'}>
                                {(users || []).map(item => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.email}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name={'reference'} label={'Reference'} {...$fieldProps.reference}>
                            <Input type={'text'} placeholder={'Reference'} />
                        </Form.Item>

                        <Form.Item name={'amount'} label={'Amount'} {...$fieldProps.amount}>
                            <Input suffix={'USD'} type={'number'} placeholder={'Amount'} />
                        </Form.Item>

                        {/*{Array(1000)*/}
                        {/*    .fill()*/}
                        {/*    .map((_, i) => (*/}
                        {/*        <Form.Item key={i} name={['amountx', i]} label={'Amount'}>*/}
                        {/*            <Input suffix={'USD'} type={'number'} placeholder={'Amount'} />*/}
                        {/*        </Form.Item>*/}
                        {/*    ))}*/}
                    </Row>
                </Card>
            </Form>
        </Spin>
    )
}

export default container(CardForm)
