import React, { useState } from 'react'
import container from './container'
import { Table, Button, Radio, Space } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import CompareHotelForm from '../CompareHotelForm'
import _ from 'lodash'

const SimilarHotelsTable = props => {
    const { hotelsDuplicate, removeDuplicate } = props

    function transform(originalObject) {
        return Object.keys(originalObject).map(key => ({ [key]: originalObject[key] }))
    }

    function removeAll(selectedValues) {
        console.log(transform(selectedValues))
        removeDuplicate(transform(selectedValues))
    }

    function removeOne(record, selectedValues) {
        const { [record.hotel.hotel_id]: removedValue } = selectedValues
        const chosenRecord = { [record.hotel.hotel_id]: removedValue }
        console.log(transform(chosenRecord))
        removeDuplicate(transform(chosenRecord))
    }

    const [selectedValues, setSelectedValues] = useState({})

    const handleRadioChange = (groupKey, value) => {
        setSelectedValues({ ...selectedValues, [groupKey]: value })
    }

    const columns = [
        {
            title: '',
            dataIndex: 'key',
            key: 'key',
            render: (text, record) => {
                const rowSpan = record.span
                if (rowSpan > 1) {
                    return {
                        children: record.key,
                        props: {
                            rowSpan
                        }
                    }
                } else if (rowSpan === 0) {
                    return {
                        props: {
                            className: 'hide-cell'
                        }
                    }
                } else {
                    return {
                        children: record.key
                    }
                }
            }
        },
        {
            title: 'Hotel Information',
            dataIndex: 'hotel',
            key: 'hotel',
            render: (text, record) => {
                const rowSpan = record.span
                if (rowSpan > 1) {
                    const hotel = record.hotel
                    return {
                        children: (
                            <div>
                                <div style={{ marginBottom: '10px' }}>
                                    <b>Hotel Name: </b>
                                    {hotel.name}
                                    <br />
                                    <b>GoQuo ID: </b>
                                    {hotel.hotel_id}
                                </div>
                                <div>
                                    <b>Address: </b> {hotel.city}, {hotel.country}
                                    <br />
                                    <b>Lat:</b> {hotel.lat}
                                    <br />
                                    <b>Long:</b> {hotel.lng}
                                </div>
                            </div>
                        ),
                        props: {
                            rowSpan
                        }
                    }
                } else if (rowSpan === 0) {
                    return {
                        props: {
                            className: 'hide-cell'
                        }
                    }
                } else {
                    const hotel = record.hotel
                    return {
                        children: (
                            <div>
                                <div style={{ marginBottom: '10px' }}>
                                    <b>Hotel Name: </b>
                                    {hotel.name}
                                    <br />
                                    <b>GoQuo ID: </b>
                                    {hotel.hotel_id}
                                </div>
                                <div>
                                    <b>Address: </b> {hotel.city}, {hotel.country}
                                    <br />
                                    <b>Lat:</b> {hotel.lat}
                                    <br />
                                    <b>Long:</b> {hotel.lng}
                                </div>
                            </div>
                        )
                    }
                }
            }
        },
        {
            title: 'Hotels Match',
            dataIndex: 'similar',
            key: 'similar',
            render: similar => {
                if (_.isNil(similar.hotel_id) === true) {
                    return {
                        children: <p>none match case</p>
                    }
                } else {
                    return {
                        children: (
                            <div>
                                <div key={similar.hotel_id}>
                                    <b>Hotel Name: </b>
                                    {similar.name}
                                    <br />
                                    <b>GoQuo ID: </b>
                                    {similar.hotel_id}
                                </div>
                            </div>
                        )
                    }
                }
            }
        },
        {
            title: 'Pick One',
            dataIndex: 'similar',
            key: 'pickOne',
            render: (text, record) => {
                const { similar, key, hotel } = record
                if (_.isNil(similar.hotel_id)) {
                    return {
                        children: <p>none match case</p>
                    }
                }

                return {
                    children: (
                        <Radio.Group
                            name={`radioGroup-${key}`}
                            onChange={e => handleRadioChange(hotel.hotel_id, e.target.value)}
                            value={selectedValues[hotel.hotel_id]}
                            style={{ display: 'flex', flexDirection: 'column' }}>
                            <Radio key={similar.hotel_id} value={similar.hotel_id}>
                                <b>Similarity: </b>
                                {similar.similarity} %
                                <br />
                                <b>Distance: </b>
                                {similar.distance} meters <br />
                            </Radio>
                        </Radio.Group>
                    )
                }
            }
        },
        {
            title: (
                <Button type="primary" size="large" onClick={() => removeAll(selectedValues)}>
                    <DeleteOutlined /> Remove All
                </Button>
            ),
            key: 'actions',
            render: (text, record) => {
                const { similar } = record
                const rowSpan = record.span
                if (_.isNil(similar.hotel_id)) {
                    return {
                        children: <p></p>
                    }
                } else if (rowSpan >= 1) {
                    return {
                        children: (
                            <Space>
                                <Button onClick={() => removeOne(record, selectedValues)}>
                                    <DeleteOutlined /> Remove Duplicate
                                </Button>
                            </Space>
                        ),
                        props: {
                            rowSpan
                        }
                    }
                }
            }
        }
    ]

    return (
        <div>
            <CompareHotelForm />
            <br />
            <Table
                bordered
                rowKey={(record, index) => index}
                dataSource={hotelsDuplicate}
                columns={columns}
                pagination={false}
            />
        </div>
    )
}

export default container(SimilarHotelsTable)
