import React from 'react'
import { SettingOutlined, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Button, Modal, Input, InputNumber, Switch, notification, Select } from 'antd'
import axios from 'axios'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'
import { Can, Say, check } from '../can'
import EditTable from '../EditTable'

const headers = JSON.parse(localStorage.getItem('headers') || '{}')
const apiUrl = `${appConfig.apiUrl}/hotel`
const RESOURCE = 'hotel-user'

const EditableContext = React.createContext()

class EditableCell extends React.Component {
    state = {
        editing: false
    }

    toggleEdit = () => {
        const editing = !this.state.editing
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus()
            }
        })
    }

    save = e => {
        const { record, handleSave } = this.props
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return
            }
            this.toggleEdit()
            handleSave({ ...record, ...values })
        })
    }

    renderCell = () => {
        const { children, dataIndex, record, title, form } = this.props
        this.form = form
        const { editing } = this.state
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {this.props.form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`
                        }
                    ],
                    initialValue: record[dataIndex]
                })(<InputNumber ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={this.toggleEdit}>
                {children}
            </div>
        )
    }

    render() {
        // eslint-disable-next-line
        const { editable, dataIndex, title, record, index, handleSave, children, ...restProps } = this.props

        return (
            <td {...restProps}>
                {editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}
            </td>
        )
    }
}

class ManageUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headers: {},
            user_suppliers: [],
            suppliers: [],
            selectedSupplier: null,
            userMarkup: null,
            addSupplierModal: false,
            setMarkupModal: false,
            removeSupplierModal: false,
            switchModal: false
        }
    }

    componentDidMount() {
        const userId = this.props.match.params.id
        axios({
            method: 'get',
            url: `${apiUrl}/users/${userId}/suppliers`,
            headers
        })
            .then(({ data }) => {
                this.setState({ user_suppliers: data })
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )

        axios({
            method: 'get',
            url: `${apiUrl}/suppliers`,
            headers
        })
            .then(({ data }) => {
                this.setState({ suppliers: data })
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    toggleAddSupplier = () => {
        this.setState({
            addSupplierModal: !this.state.addSupplierModal
        })
    }

    toggleSetMarkup = () => {
        this.setState({
            setMarkupModal: !this.state.setMarkupModal
        })
    }

    toggleRemoveSupplier = () => {
        this.setState({
            removeSupplierModal: !this.state.removeSupplierModal
        })
    }

    toggleSwitchModal = () => {
        this.setState({
            switchModal: !this.state.switchModal
        })
    }

    addSupplier = () => {
        const userId = this.props.match.params.id
        const supplierId = this.state.selectedSupplier
        axios({
            method: 'post',
            url: `${apiUrl}/users/${userId}/suppliers`,
            headers,
            data: { supplier_id: supplierId }
        })
            .then(() => {
                this.toggleAddSupplier()
                this.componentDidMount()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    removeSupplier = supplierId => {
        const userId = this.props.match.params.id
        axios({
            method: 'delete',
            url: `${apiUrl}/users/${userId}/suppliers/${supplierId}`,
            headers
        })
            .then(() => {
                this.toggleRemoveSupplier()
                this.componentDidMount()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    applyMarkup = () => {
        const userId = this.props.match.params.id
        const markup = this.state.userMarkup
        axios({
            method: 'patch',
            url: `${apiUrl}/users/${userId}/suppliers`,
            headers,
            data: { markup }
        })
            .then(() => {
                this.toggleSetMarkup()
                this.componentDidMount()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    selectSupliers = () => {
        const existed = this.state.user_suppliers.map(u => u.contract_id)

        return this.state.suppliers
            .filter(s => !existed.includes(s.contract_id) && !s.del_flag)
            .map(user => (
                <Select.Option value={user.id} key={user.id}>
                    {user.name}
                </Select.Option>
            ))
    }

    selectSupplier = selectedSupplier => {
        this.setState({ selectedSupplier })
    }

    changeMarkup = event => {
        this.setState({ userMarkup: event.target.value })
    }

    changeSupplierStatus = supplier => {
        const userId = this.props.match.params.id

        axios({
            method: 'patch',
            url: `${apiUrl}/users/${userId}/suppliers/${supplier.id}`,
            headers,
            data: {
                supplier_code: supplier.code,
                active: !!supplier.inactive
            }
        })
            .then(() => {
                this.toggleSwitchModal()
                this.componentDidMount()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    showConfirm = row => {
        Modal.confirm({
            title: `Are you sure to turn ${row.inactive ? 'on' : 'off'} ${row.name}?`,
            onOk: () => this.changeSupplierStatus(row),
            onCancel: () => this.toggleSwitchModal()
        })
    }

    alwaysUsePkgCell = cell => <Switch disabled checked={cell} />

    statusCell = (cell, row) => (
        <Switch
            disabled={!check(RESOURCE, 'edit')}
            defaultChecked={!row.inactive}
            checked={!row.inactive}
            onClick={() => this.showConfirm(row)}
        />
    )

    showDeleteConfirm = row => {
        Modal.confirm({
            title: `Are you sure to remove ${row.name}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => this.removeSupplier(row.id),
            onCancel: () => this.toggleRemoveSupplier()
        })
    }

    actionCell = (cell, row) => (
        <Can resource={RESOURCE} perform="edit">
            <Say yes>
                <Button onClick={() => this.showDeleteConfirm(row)} type="danger">
                    <UserDeleteOutlined /> Remove
                </Button>
            </Say>
        </Can>
    )

    handleSave = row => {
        // passing through params if we move EditableCell component to global
        const rowKey = 'id'
        const editableCellKey = 'markup_percent'

        const newData = [...this.state.user_suppliers]
        const index = newData.findIndex(item => row[rowKey] === item[rowKey])
        const item = newData[index]
        newData.splice(index, 1, {
            ...item,
            ...row
        })

        const userId = this.props.match.params.id
        axios({
            method: 'patch',
            url: `${apiUrl}/users/${userId}/suppliers/${row.id}`,
            headers,
            data: {
                markup: Number(row[editableCellKey]),
                supplier_code: row.code
            }
        })
            .then(() => {
                this.setState({ user_suppliers: newData })
            })
            .catch(error => {
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
                this.componentDidMount()
            })
    }

    render() {
        let columns = [
            {
                key: 'code',
                dataIndex: 'code',
                title: 'Code',
                defaultSortOrder: 'ascend',
                sorter: (a, b) => a.code.localeCompare(b.code)
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: 'Name'
            },
            {
                key: 'markup_percent',
                dataIndex: 'markup_percent',
                title: 'Markup (%)',
                editable: true
            },
            {
                key: 'always_use_pkg',
                dataIndex: 'always_use_pkg',
                title: 'Always PKG',
                className: 'text-center',
                render: this.alwaysUsePkgCell
            },
            {
                key: 'inactive',
                dataIndex: 'inactive',
                title: 'Status',
                className: 'text-center',
                render: this.statusCell
            }
        ]

        if (check(RESOURCE, 'edit')) {
            columns.push({
                key: 'dummy',
                title: 'Action',
                className: 'text-center',
                render: this.actionCell
            })
        }

        const components = {
            body: {
                cell: Form.create({ name: 'markup_editable' })(EditableCell)
            }
        }

        columns = columns.map(col => {
            if (!col.editable) {
                return col
            }
            return {
                ...col,
                inputType: col.dataIndex === 'markup_percent' ? 'number' : 'text',
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave
                })
            }
        })

        return (
            <div>
                <div>
                    <Can resource={RESOURCE} perform="edit">
                        <Say yes>
                            <div className="m-b-20">
                                <Button type="primary" onClick={this.toggleAddSupplier}>
                                    <UserAddOutlined /> Add Supplier
                                </Button>{' '}
                                <Button type="info" onClick={this.toggleSetMarkup}>
                                    <SettingOutlined /> Set Markup
                                </Button>
                            </div>
                        </Say>
                    </Can>
                </div>
                <br />
                <Can resource={RESOURCE} perform="edit">
                    <Say yes>
                        {/* add supplier modal */}
                        <Modal
                            visible={this.state.addSupplierModal}
                            onClick={this.toggleAddSupplier}
                            title="Add Supplier"
                            onOk={this.addSupplier}
                            onCancel={this.toggleAddSupplier}>
                            Select supplier you want to add into this user
                            <Select
                                showSearch
                                filterOption
                                optionFilterProp="children"
                                onSelect={this.selectSupplier}
                                style={{ minWidth: '100%' }}>
                                {this.selectSupliers()}
                            </Select>
                        </Modal>
                        {/* set markup modal */}
                        <Modal
                            visible={this.state.setMarkupModal}
                            title="Markup Configuration"
                            onOk={this.applyMarkup}
                            onCancel={this.toggleSetMarkup}>
                            Enter the markup value will apply for all suppliers in this user
                            <Input type="number" onChange={this.changeMarkup} />
                        </Modal>
                    </Say>
                </Can>

                <EditTable
                    components={components}
                    rowKey="id"
                    bordered
                    rowClassName={() => 'editable-row'}
                    dataSource={this.state.user_suppliers}
                    columns={columns}
                    handleSave={this.handleSave}
                />
            </div>
        )
    }
}

export default ManageUser
