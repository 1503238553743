import React, { useMemo } from 'react'
import { Card, Table, Spin } from 'antd'
import HotelMappingForm from '../HotelMappingForm'
import HotelMappingInfo from '../HotelMappingInfo'
import container from './container'

const { Meta } = Card

const DestinationMapping = props => {
    const { hotelMappings, hotelMappingFrom, hotelInfoMapping } = props

    const columns = [
        {
            key: 'hotel_id',
            dataIndex: 'hotel_id',
            title: `${hotelMappingFrom || ''} Hotel Id`,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.hotel_id.localeCompare(b.hotel_id)
        },
        {
            key: 'goquo_ids',
            dataIndex: 'goquo_ids',
            title: 'GoQuo Hotel Id',
            render: cell => cell.join()
        }
        // {
        //     key: 'name',
        //     dataIndex: 'name',
        //     title: 'Name'
        // },
        // {
        //     key: 'address',
        //     dataIndex: 'address',
        //     title: 'Address'
        // }
    ]

    const expandTableColumns = useMemo(() => {
        return [
            {
                key: 'supplier_code',
                dataIndex: 'supplier_code',
                title: 'Supplier',
                defaultSortOrder: 'ascend',
                sorter: (a, b) => {
                    const aS = a.supplier_code + a.hotel_id
                    const bS = b.supplier_code + b.hotel_id
                    return aS.localeCompare(bS)
                }
            },
            {
                key: 'hotel_id',
                dataIndex: 'hotel_id',
                title: 'Hotel Id'
            },
            {
                key: 'name',
                // dataIndex: 'name',
                title: 'Name',
                render: item => {
                    const key = `${item.supplier_code}-${item.hotel_id}`
                    return (
                        (hotelInfoMapping && hotelInfoMapping[key] && hotelInfoMapping[key].name) || <Spin spinning />
                    )
                }
            },
            {
                key: 'address',
                // dataIndex: 'address',
                title: 'Address',
                render: item => {
                    const key = `${item.supplier_code}-${item.hotel_id}`
                    return (
                        (hotelInfoMapping && hotelInfoMapping[key] && hotelInfoMapping[key].address) || (
                            <Spin spinning />
                        )
                    )
                }
            }
            // {
            //     key: 'action',
            //     dataIndex: 'action',
            //     title: 'Action',
            //     className: 'text-center',
            //     render: (cell, row) => (
            //         <Button type="primary" disabled={!row.name} onClick={() => getMappingTree(row)}>
            //             View Tree
            //         </Button>
            //     )
            // }
        ]
    }, [hotelInfoMapping])

    return (
        <div>
            <HotelMappingForm />
            <br />
            <Card>
                <Meta description="Click into the plus square to see the mapping with other suppliers" />
                <br />
                <Table
                    rowKey="hotel_id"
                    bordered
                    dataSource={hotelMappings}
                    columns={columns}
                    expandedRowRender={row => {
                        return (
                            <div>
                                <HotelMappingInfo mappings={row.mappings} />
                                <Table
                                    rowKey={r => r.supplier_code + r.hotel_id}
                                    size="middle"
                                    dataSource={row.mappings}
                                    columns={expandTableColumns}
                                />
                            </div>
                        )
                    }}
                />
            </Card>
        </div>
    )
}

export default container(DestinationMapping)
