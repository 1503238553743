import React from 'react'
import { Table, Tag } from 'antd'

function LogList({ data }) {
    const formatStatus = cell => {
        return 200 <= cell && cell < 300 ? <Tag color="green">Success</Tag> : <Tag color="red">Failed</Tag>
    }

    const logTableColumn = [
        {
            key: 'method',
            dataIndex: 'method',
            title: 'Method'
        },
        {
            key: 'path',
            dataIndex: 'path',
            title: 'Path'
            // sort: true
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
            render: formatStatus
        },
        {
            key: 'user',
            dataIndex: 'user',
            title: 'User'
        },
        {
            key: 'created_at',
            dataIndex: 'created_at',
            title: 'Created at'
            // sort: true
        }
    ]

    const expandTableColumn = [
        {
            key: 'request',
            dataIndex: 'request',
            title: 'Request',
            render: cell => <pre>{JSON.stringify(JSON.parse(cell), null, 2)}</pre>
        },
        {
            key: 'response',
            dataIndex: 'response',
            title: 'Response',
            render: cell => <pre>{cell ? JSON.stringify(JSON.parse(cell), null, 2) : ''}</pre>
        }
    ]

    return (
        <Table
            rowKey="id"
            bordered
            dataSource={data}
            columns={logTableColumn}
            pagination={{ size: 'small', simple: true }}
            expandedRowRender={row => (
                <Table rowKey="id" bordered dataSource={[row]} columns={expandTableColumn} pagination={false} />
            )}
        />
    )
}

export default LogList
