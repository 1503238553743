import axios from 'axios'
import { createAction } from '@'
import { getAuth } from '@misc'
import { $amenitiesUnmap } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('SaveAmenitiesMapping', async params => {
    const auth = getAuth()

    const amenitiesUnmap = await axios({
        url: `${ENDPOINT}/hotel/mappings/amenities/saveUnmap`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...auth },
        data: {
            data: params
        }
    })

    const dataUnmap = amenitiesUnmap.data

    var unmapAmenities = Object.keys(dataUnmap).map(key => {
        return {
            code: String(key),
            name: dataUnmap[key]
        }
    })

    $amenitiesUnmap.setState(() => unmapAmenities)
})
