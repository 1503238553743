import React from 'react'
import { Link } from 'react-router-dom'
import { CalendarOutlined, SyncOutlined } from '@ant-design/icons'
import { notification, Table, Card, Col, Row, Button, Modal, Tabs, Input, Popover, Spin, Select } from 'antd'
import axios from 'axios'
import qs from 'querystring'
import { startOfWeek, format } from 'date-fns'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

import { DateRangePicker, DateRange } from 'react-date-range'
import appConfig from '../config'
import apiErrorHandler from '../api-error-handler'
import { filterTable } from '@/search'

const { TabPane } = Tabs
const headers = JSON.parse(localStorage.getItem('headers') || '{}')
let apiUrl = ''

class Booking extends React.Component {
    constructor(props) {
        super(props)
        apiUrl = `${appConfig.apiUrl}/${props.product}`

        this.state = {
            headers: null,
            bookingLoading: false,
            bookings: [],
            filter: {
                input: '',
                status: ''
            },
            filterBookings: [],
            panes: {},
            dateRange: {
                selection: {
                    startDate: startOfWeek(new Date(), { weekStartsOn: 2 }),
                    endDate: new Date(),
                    key: 'selection'
                }
            },
            activeTab: 'filter',
            screenWidth: window.innerWidth,
            cancelledBooking: null
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.setState({ screenWidth: window.innerWidth }))
        this.getBookings()
    }

    onFilter = () => {
        const { filter } = this.state
        let filterBookings = this.state.bookings
        Object.keys(filter).forEach(key => {
            const val = filter[key]
            if (val) {
                switch (key) {
                    case 'input':
                        filterBookings = filterTable(val, filterBookings, this.props.columns)
                        break
                    case 'status':
                        filterBookings = filterBookings.filter(b => b.status === val)
                        break
                    default:
                        break
                }
            }
        })

        this.setState({ filterBookings })
    }

    getBookings() {
        const params = {
            from: format(this.state.dateRange.selection.startDate, 'yyyy-MM-dd'),
            to: format(this.state.dateRange.selection.endDate, 'yyyy-MM-dd')
        }

        this.setState({ bookingLoading: true })

        axios({
            method: 'get',
            url: `${apiUrl}/bookings?${qs.stringify(params)}`,
            headers
        })
            .then(({ data }) => {
                this.setState(
                    {
                        bookings: data,
                        bookingLoading: false
                    },
                    this.onFilter
                )
            })
            .catch(err => {
                this.setState({
                    bookingLoading: false
                })

                apiErrorHandler(err)
            })
    }

    dateRangeSelect = ranges =>
        this.setState(
            {
                dateRange: ranges
            },
            () => this.getBookings()
        )

    datePicker = () => {
        const { screenWidth } = this.state

        if (screenWidth >= 1048) {
            return (
                <DateRangePicker
                    ranges={[this.state.dateRange.selection]}
                    moveRangeOnFirstSelection={false}
                    onChange={this.dateRangeSelect}
                    months={2}
                    maxDate={new Date()}
                    direction="horizontal"
                />
            )
        }
        if (screenWidth > 820) {
            return (
                <DateRange
                    ranges={[this.state.dateRange.selection]}
                    moveRangeOnFirstSelection={false}
                    onChange={this.dateRangeSelect}
                    months={2}
                    maxDate={new Date()}
                    direction="horizontal"
                />
            )
        }
        return (
            <DateRange
                ranges={[this.state.dateRange.selection]}
                moveRangeOnFirstSelection={false}
                onChange={this.dateRangeSelect}
                months={1}
                maxDate={new Date()}
                direction="horizontal"
            />
        )
    }

    bookingStatus = statuses => {
        return (
            <Select
                showSearch
                onChange={status => {
                    const filter = this.state.filter
                    filter.status = status
                    this.setState({ filter }, this.onFilter)
                }}
                allowClear={true}
                style={{ minWidth: 150 }}>
                {statuses.map(status => (
                    <Select.Option value={status} key={status}>
                        {status}
                    </Select.Option>
                ))}
            </Select>
        )
    }

    onCancelBooking = (booking = null) => {
        this.setState({
            cancelledBooking: booking
        })

        Modal.confirm({
            title: `Are you sure to cancel booking ${booking.client_ref || booking.booking_reference}?`,
            onOk: () => this.cancelBooking()
        })
    }

    cancelBooking = async () => {
        const { cancelledBooking, panes } = this.state

        const tabId = cancelledBooking[this.props.attTabId]

        try {
            await axios({
                method: 'delete',
                url: `${apiUrl}/bookings/${cancelledBooking.booking_id}`,
                headers,
                params: {
                    reference: cancelledBooking.reference,
                    supplier_code: cancelledBooking.supplier_code,
                    pnr: cancelledBooking.pnr
                }
            })

            notification.success({
                message: `Booking ${cancelledBooking[this.props.attTabId]} Cancelled!`
            })

            delete panes[tabId]
            this.setState(
                {
                    cancelledBooking: null,
                    panes,
                    activeTab: 'filter'
                },
                this.getBookings
            )
        } catch (err) {
            apiErrorHandler(err)
        }
    }

    add = row => {
        const { panes } = this.state
        const activeTab = row[this.props.attTabId]

        panes[activeTab] = { row, loading: true }

        this.setState({ panes, activeTab })

        // no status for flight booking currently
        if (['Confirmed', 'Received'].includes(row.status) || this.props.product === 'flight') {
            axios({
                method: 'get',
                url: `${apiUrl}/bookings/${row.id}`,
                headers,
                params: {
                    reference: row.reference,
                    booking_id: row.booking_id,
                    supplier_code: row.supplier_code,
                    pnr: row.pnr
                }
            })
                .then(({ data }) => {
                    panes[activeTab].details = data
                    panes[activeTab].loading = false

                    this.setState({ panes })
                })
                .catch(err => {
                    panes[activeTab].details = {}
                    panes[activeTab].loading = false
                    apiErrorHandler(err)
                })
        }
    }

    remove = targetKey => {
        let { activeTab } = this.state
        let lastIndex
        const tabs = Object.keys(this.state.panes)
        tabs.forEach((pane, i) => {
            if (pane === targetKey) {
                lastIndex = i - 1
            }
        })

        const panes = this.state.panes
        delete panes[targetKey]

        if (tabs.length && activeTab === targetKey) {
            if (lastIndex >= 0) {
                activeTab = tabs[lastIndex]
            } else {
                activeTab = 'filter'
            }
        }

        this.setState({ panes, activeTab })
    }

    onChange = activeTab => {
        this.setState({ activeTab })
    }

    onEdit = (targetKey, action) => {
        this[action](targetKey)
    }

    onSearch = e => {
        const filter = this.state.filter
        filter.input = e.target.value
        this.setState({ filter }, this.onFilter)
    }

    render() {
        const columns = this.props.columns
        const { DetailForm, statuses = [] } = this.props

        const expandColumn = columns.find(col => !!col.openLink)
        if (expandColumn) {
            expandColumn.render = (col, row) =>
                row.status && row.status.toLowerCase() !== 'failed' ? (
                    <Link
                        to="/"
                        onClick={event => {
                            event.preventDefault()
                            this.add(row)
                        }}>
                        {col}
                    </Link>
                ) : (
                    col
                )
        }

        return (
            <Tabs
                onChange={this.onChange}
                activeKey={this.state.activeTab}
                type="editable-card"
                tabBarExtraContent={
                    <Button type="link" onClick={() => this.getBookings()} loading={this.state.bookingLoading}>
                        Refresh
                    </Button>
                }
                hideAdd={true}
                onEdit={this.onEdit}>
                <TabPane tab="Booking Filter" key="filter" closable={false}>
                    <Card>
                        <Row gutter={8}>
                            <Col md={2}>
                                <Popover placement="bottomLeft" content={this.datePicker()} trigger="click">
                                    <Button type="primary" style={{ width: '100%' }}>
                                        <CalendarOutlined /> Date
                                    </Button>
                                </Popover>
                            </Col>
                            {Array.isArray(statuses) && statuses.length ? (
                                <Col md={2}>
                                    {/* {this.bookingStatus(statuses)} */}
                                    <Popover
                                        placement="bottomLeft"
                                        content={this.bookingStatus(statuses)}
                                        trigger="click">
                                        <Button type="primary" style={{ width: '100%' }}>
                                            <SyncOutlined /> Status
                                        </Button>
                                    </Popover>
                                </Col>
                            ) : null}
                            <Col flex={1}>
                                <Row justify={'end'}>
                                    <Col md={8}>
                                        <Input placeholder="Search" onChange={this.onSearch} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                    <br />
                    <Card>
                        <Spin spinning={this.state.bookingLoading}>
                            <Table bordered rowKey="id" columns={columns} dataSource={this.state.filterBookings} />
                        </Spin>
                    </Card>
                </TabPane>
                {Object.entries(this.state.panes).map(([tab, pane]) => (
                    <TabPane tab={tab} key={tab}>
                        {DetailForm ? (
                            <Spin spinning={pane.loading}>
                                <DetailForm
                                    booking={pane.row}
                                    details={pane.details}
                                    toggleCancelBookingModal={this.onCancelBooking}
                                />
                            </Spin>
                        ) : null}
                    </TabPane>
                ))}
            </Tabs>
        )
    }
}

export default Booking
