import { createAction } from '@'
import Fetch from './Fetch'
import { $searchInfo, $roomMap } from '../stores'

const ENDPOINT = process.env.REACT_APP_OFFER_ENDPOINT

export default createAction('GetRoom', async values => {
    const roomMap = $roomMap.getState()
    if (roomMap && roomMap[values.goquo_id]) {
        return
    }
    const searchInfo = $searchInfo.getState()
    if (!searchInfo) {
        return
    }

    const { data } = await Fetch({
        url: `${ENDPOINT}/getrooms`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-key': searchInfo.email,
            'x-access-token': searchInfo.token
        },
        data: {
            request_id: searchInfo.requestId,
            hotels: [
                {
                    goquo_id: values.goquo_id
                }
            ]
        }
    })

    $roomMap.setState(s => ({ ...s, [values.goquo_id]: data }))
})
