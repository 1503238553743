import React from 'react'
import 'antd/dist/antd.css'
import InputForm from '../InputForm'
import ComparisonTable from '../ComparisonTable'
import container from './container'

const FlightPriceCompare = () => {
    return (
        <div>
            <InputForm />
            <br />
            <ComparisonTable />
        </div>
    )
}

export default container(FlightPriceCompare)
