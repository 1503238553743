import { createAction } from '@'
import { getAuth } from '@misc'
import axios from 'axios'
import { $alertSettings } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetAlerts', async () => {
    const auth = getAuth()
    let { data } = await axios({
        url: `${ENDPOINT}/alert-settings`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...auth }
    })

    data = data.map(function(d) {
        return {
            project: d.project,
            email: d.email,
            isActive: d.is_active,
            id: d.id,
            user_id: d.user_id
        }
    })
    $alertSettings.setState(() => data)
    return data
})
