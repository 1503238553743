import { compose, hoc, useStore } from '@'
import { useCallback } from 'react'
import GetAirportSuggestion from '../../actions/GetAirportSuggestion'
import { $airportSuggestions } from '../../stores'

const container = compose(
    hoc(props => {
        const [airportSuggestions] = useStore([$airportSuggestions])

        const handleSearchChange = useCallback(value => {
            GetAirportSuggestion(value).catch(console.error)
        }, [])

        const handleSearch = useCallback(value => {
            console.log(value)
        }, [])

        return {
            airportSuggestions,
            handleSearchChange,
            handleSearch,
            ...props
        }
    })
)

export default container
