import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import axios from 'axios'
import querystring from 'querystring'
import { ClockCircleOutlined, InboxOutlined, PhoneOutlined, TeamOutlined, FieldStringOutlined } from '@ant-design/icons'
import { Card, Button, Slider, Tag, Table, Descriptions, Rate, PageHeader, Typography, Row } from 'antd'
import { isBefore, parse, startOfDay, isAfter, differenceInHours, parseISO } from 'date-fns'

import Booking from '../../components/booking'

const dateFormat = 'yyyy-MM-dd HH:mm'

const ENDPOINT = process.env.REACT_APP_HOTELINFO_ENDPOINT

function statusCell(status) {
    switch (status) {
        case 'Confirmed':
        case 'Received':
            return <Tag color="green">{status}</Tag>
        case 'Pending':
            return <Tag color="warning">{status}</Tag>
        case 'Cancelled':
            return <Tag color="red">{status}</Tag>
        case 'Failed':
            return <Tag color="red">{status}</Tag>
        default:
            return <Tag color="gray">{status}</Tag>
    }
}

function amountCell(_cell, row) {
    return <span>{row.currency + ' ' + (row.amount || 0.0).toFixed(2)}</span>
}

const columns = [
    {
        key: 'reference',
        dataIndex: 'reference',
        title: 'Goquo ID',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.id - b.id,
        openLink: true
    },
    {
        key: 'client_ref',
        dataIndex: 'client_ref',
        title: 'Client Reference'
    },
    {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        render: statusCell,
        className: 'text-center'
    },
    { key: 'booking_id', dataIndex: 'booking_id', title: 'Booking Id' },
    { key: 'hotel_name', dataIndex: 'hotel_name', title: 'Hotel Name' },
    { key: 'supplier_code', dataIndex: 'supplier_code', title: 'Supplier Name' },
    { key: 'booking_date', dataIndex: 'booking_date', title: 'Booking Date' },
    { key: 'checkin', dataIndex: 'checkin', title: 'Checkin' },
    { key: 'checkout', dataIndex: 'checkout', title: 'Checkout' },
    {
        key: 'amount',
        dataIndex: 'amount',
        title: 'Amount',
        render: amountCell,
        className: 'text-right'
    }
]

class HotelBooking extends React.Component {
    render() {
        return (
            <Booking
                product="hotel"
                columns={columns}
                DetailForm={HotelForm}
                statuses={['Confirmed', 'Received', 'Pending', 'Cancelled', 'Failed', 'Created']}
                attTabId="client_ref"
            />
        )
    }
}

function HotelForm({ booking = {}, details = {}, toggleCancelBookingModal }) {
    const [hotelInfo, setHotelInfo] = useState([])

    const getHotelInfo = () => {
        const qs = {
            supplier_code: booking.supplier_code,
            hotel_id: booking.hotel_id
        }
        if (qs.supplier_code === 'GoQuo') {
            delete qs.supplier_code
        }

        axios
            .get(ENDPOINT + '/hotel?' + querystring.stringify(qs))

            .then(({ data }) => {
                setHotelInfo(data)
            })
            .catch(() => {
                // this.setState({ loading: false })
            })
    }

    useEffect(() => {
        getHotelInfo()
    }, {})

    const isCancellable =
        isBefore(new Date(), parseISO(booking.cancellation_deadline)) &&
        ['Confirmed', 'Received', 'Pending'].includes(booking.status)

    const roomCols = [
        {
            key: 'idx',
            dataIndex: 'idx',
            title: 'Room No'
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
            render: statusCell
        },
        {
            key: 'room_name',
            dataIndex: 'room_name',
            title: 'Room Name'
        },
        {
            key: 'board_basis',
            dataIndex: 'board_basis',
            title: 'Board Basis'
        },
        {
            key: 'price',
            dataIndex: 'price',
            title: 'Price',
            render: (cell, row) => `${booking.currency} ${row.price}`
        }
    ]

    const roomPaxCols = [
        {
            key: 'title',
            dataIndex: 'title',
            title: 'Title'
        },
        {
            key: 'first_name',
            dataIndex: 'first_name',
            title: 'First Name'
        },
        {
            key: 'last_name',
            dataIndex: 'last_name',
            title: 'Last Name'
        },
        {
            key: 'age',
            dataIndex: 'age',
            title: 'Age'
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: 'Type'
        },
        {
            key: 'nationality',
            dataIndex: 'nationality',
            title: 'Nationality'
        }
    ]

    if (!_.isEmpty(details)) {
        details.rooms.forEach(room => {
            delete room.children
        })
    }

    return (
        <>
            <PageHeader
                title={`${booking.hotel_name || hotelInfo.name}`}
                subTitle={<Rate allowHalf disabled value={hotelInfo.star_rating} />}
                tags={statusCell(booking.status)}
                extra={
                    <Button type="danger" onClick={() => toggleCancelBookingModal(booking)} disabled={!isCancellable}>
                        Cancel Booking
                    </Button>
                }>
                <Row type="flex">
                    <div className="main" style={{ flex: 1 }}>
                        <Typography.Paragraph>
                            <FieldStringOutlined /> {hotelInfo.address}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            <PhoneOutlined /> {hotelInfo.phone} | <InboxOutlined /> {hotelInfo.email}
                        </Typography.Paragraph>
                        <Descriptions>
                            <Descriptions.Item label="Booking ID">{booking.booking_id}</Descriptions.Item>
                            <Descriptions.Item label="Supplier Name">{booking.supplier_code}</Descriptions.Item>
                            <Descriptions.Item label="Credential">{booking.user_email}</Descriptions.Item>
                            <Descriptions.Item label="Booked Date">{booking.booking_date}</Descriptions.Item>
                            <Descriptions.Item label="Log ID">{booking.log_id}</Descriptions.Item>
                            <Descriptions.Item label="Checkin">{booking.checkin}</Descriptions.Item>
                            <Descriptions.Item label="Checkout">{booking.checkout}</Descriptions.Item>
                            <Descriptions.Item label="Total Amount">
                                <span style={{ color: 'red' }}>
                                    {booking.currency}
                                    {booking.amount}
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                    <div
                        className="extra"
                        style={{
                            marginLeft: 48,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                        <img
                            src={hotelInfo.images && hotelInfo.images[0].url}
                            alt={hotelInfo.images && hotelInfo.images[0].caption}
                            style={{ maxWidth: 250 }}
                        />
                    </div>
                </Row>
            </PageHeader>
            <br />
            <Card title="Room Details" size="small">
                <Table
                    rowKey="idx"
                    dataSource={details.rooms || []}
                    columns={roomCols}
                    pagination={false}
                    expandedRowRender={room => {
                        return (
                            <>
                                <TeamOutlined />
                                Passenger Details
                                <br />
                                <Table rowKey="id" dataSource={room.paxes} columns={roomPaxCols} pagination={false} />
                                <br />
                                <br />
                                <ClockCircleOutlined />
                                Cancellation Policies
                                <RoomCancellation room={room} booking={booking} />
                            </>
                        )
                    }}
                />
            </Card>
        </>
    )
}

function RoomCancellation({ room, booking }) {
    const bookedDate = startOfDay(parse(booking.booking_date, dateFormat, new Date()))
    const marks = {
        0: 'Booked'
    }

    const checkin = parse(booking.checkin, dateFormat, new Date())
    const max = differenceInHours(checkin, bookedDate) || 12 // checkin time default 12PM
    marks[max] = 'Checkin'

    const trackStyle = []
    const cancellations = _.sortBy(room.cancellations || [], c => parse(c.from, dateFormat, new Date()))

    let cancellationNow = ''
    let cancellationNowColor = ''

    if (cancellations.length) {
        cancellations.forEach(c => {
            const from = parse(c.from, dateFormat, new Date())
            const to = parse(c.to, dateFormat, new Date())

            if (isAfter(from, bookedDate)) {
                const diff = to.diff(bookedDate, 'hour')
                if (diff + 1 !== max && to.isBefore(checkin)) {
                    // tobe removed mark have to_date is last day before checkin
                    marks[diff] = to.format('DD/MM')
                }
            }

            if (isAfter(new Date(), from) && isBefore(new Date(), to)) {
                switch (c.amount) {
                    case 0:
                        trackStyle.push({ backgroundColor: 'green' })
                        cancellationNow = 'Free'
                        cancellationNowColor = 'text-success'
                        break
                    case room.price:
                        trackStyle.push({ backgroundColor: 'red' })
                        cancellationNow = 'Non-refundable'
                        cancellationNowColor = 'text-danger'
                        break
                    default:
                        trackStyle.push({ backgroundColor: 'orange' })
                        cancellationNow = 'Partially Refundable'
                        cancellationNowColor = 'text-warning'
                        break
                }
            }
        })
    } else {
        trackStyle.push({ backgroundColor: 'red' })
        cancellationNow = 'Non-refundable'
        cancellationNowColor = 'text-danger'
    }

    // const defaultValue = Object.keys(marks).map(Number)

    return (
        <div>
            Now, cancellation is <strong className={cancellationNowColor}>{cancellationNow}</strong>
            <Slider
                // defaultValue={defaultValue || []}
                pushable
                disabled={true}
                max={max}
                marks={marks}
                trackStyle={trackStyle}
                // handleStyle={[{ backgroundColor: 'yellow' }, { backgroundColor: 'gray' }]}
                // railStyle={{ backgroundColor: 'black' }}
            />
            <br />
            {room.cancellation_text ? (
                room.cancellation_text
            ) : (
                <ul>
                    {(room.cancellations || []).map((cancellation, idx) => (
                        <li key={idx}>
                            From: {cancellation.from} To: {cancellation.to} Amount: {cancellation.amount}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default HotelBooking
