import { createAction } from '@'
import { getAuth, loginErrorHandle } from '@misc'
import axios from 'axios'
import { $roles } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetRoles', async () => {
    try {
        const auth = getAuth()

        const res = await axios({
            url: `${ENDPOINT}/roles`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...auth }
        })

        const roles = (res && res.data) || []

        await Promise.all(
            roles.map(async role => {
                const { data } = await axios({
                    url: `${ENDPOINT}/roles/${role.id}/permissions`,
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', ...auth }
                })

                role.permissions = data
            })
        )

        $roles.setState(() => (res && res.data) || [])
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }
})
