import { createAction } from '@'
import axios from 'axios'
import { getAuth, loginErrorHandle } from '@misc'
import { $suppliers } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetSuppliers', async () => {
    $suppliers.setState(() => undefined)
    const auth = getAuth()
    try {
        const res = await axios({
            url: `${ENDPOINT}/hotel/suppliers`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...auth }
        })
        $suppliers.setState(() => res.data)
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }
})
