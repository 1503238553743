import { useEffect, useState } from 'react'
import { compose, hoc, useStore } from '@'
import JSZip from 'jszip'
import { $logs } from '../../stores'

const container = compose(
    hoc(props => {
        const [logs] = useStore([$logs])
        const [downloadLink, setDownloadLink] = useState(null)
        useEffect(() => {
            if (!logs || !logs.length) {
                setDownloadLink(null)
                return
            }
            const zip = new JSZip()
            logs.forEach((log, idx) => {
                zip.file(`${idx}-${log.src}-${log.name}.${log.ext}`, log.data)
            })
            zip.generateAsync({ type: 'blob' })
                .then(blob => {
                    const url = window.URL.createObjectURL(blob)
                    setDownloadLink(url)
                })
                .catch(console.error)
        }, [logs])
        return {
            logs,
            downloadLink,
            ...props
        }
    })
)

export default container
