import React from 'react'
import container from './container'
import LogsInputForm from '../LogsInputForm'
import LogViewer from '../LogViewer'

const GoogleCloudLogs = () => {
    return (
        <div>
            <LogsInputForm />
            <br />
            <LogViewer />
        </div>
    )
}

export default container(GoogleCloudLogs)
