import styled from 'styled-components'

export const Root = styled.div`
    .ant-form-item-control {
        width: 170px;
        margin-bottom: 10px;
    }

    .holder {
        .ant-col.ant-form-item-label {
            width: 100px;
        }
    }
`
