import { useCallback, useState } from 'react'
import { compose, hoc } from '@'

const container = compose(
    hoc(props => {
        const [byEmail, setByEmail] = useState(false)
        const handleUseEmail = useCallback(() => {
            setByEmail(true)
        }, [])
        return {
            byEmail,
            handleUseEmail,
            ...props
        }
    })
)

export default container
