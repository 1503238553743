/* eslint-disable no-unused-vars */
import { createAction } from '@'
import { getAuth, loginErrorHandle } from '@misc'
import axios from 'axios'
import { get } from 'lodash'
import moment from 'moment'
import prettyData from 'pretty-data'
import { $fetching, $logs } from '../stores'
const _ = require('lodash')

const ENDPOINT = process.env.REACT_APP_API_URL

const config_env = {
    dev: {
        flights: {
            cluster_name: 'airasia-staging'
        },
        hotel: {
            cluster_name: 'airasia-staging'
        },
        'tour-gateway': {
            cluster_name: 'airasia-staging'
        },
        project_id: 'airasia-goquo-dev',
        location: 'asia-southeast1',
        k8s_pod_release: 'dev',
        container_name: 'flight-checkbooking'
    },
    staging: {
        flights: {
            cluster_name: 'flights-k8s-tfstg-rf6y'
        },
        hotel: {
            cluster_name: 'k8s-tfstg-rf6y'
        },
        'tour-gateway': {
            cluster_name: 'k8s-tfstg-rf6y'
        },
        project_id: 'airasia-goquo-stg',
        location: 'asia-southeast1-a',
        k8s_pod_release: 'staging',
        container_name: 'flight-checkbooking'
    },
    production: {
        flights: {
            cluster_name: 'k8s-tfprd-7sta'
        },
        hotel: {
            cluster_name: 'k8s-tfprd-7sta'
        },
        'tour-gateway': {
            cluster_name: 'k8s-tfprd-7sta'
        },
        project_id: 'airasia-goquo-prd',
        location: 'asia-southeast1',
        k8s_pod_release: 'production',
        container_name: 'flight-checkbooking'
    }
}

const getAppLabel = (app, env) => {
    if (app === 'hotel') {
        return `(labels.k8s-pod/app="hotel-offer" OR labels.k8s-pod/app="hotel-booking") AND
        (labels.k8s-pod/release="hotel-svc-${env}" OR labels."k8s-pod/release"="hotel-booksvc-${env}")`
    }

    if (app === 'tour-gateway') {
        return `labels.k8s-pod/app="${app}-${env}" AND
    labels.k8s-pod/release="${app}-${env}"`
    }

    return `labels.k8s-pod/app="${app}" AND
    labels.k8s-pod/release="${app}-${config_env[env].k8s_pod_release}"`
}

const fetchLog = async values => {
    const auth = getAuth()
    const defaultNamespace = ['tour-gateway', 'transfer-gateway']
    const isDefaultNs = _.some(defaultNamespace, x => x === values.app)
    const data = []

    const releasePrefix = values.app === 'hotel' ? ['-svc-', '-booksvc-'] : ['-']
    const env = values.release
    // hoangnd: remove this condition: AND ${getAppLabel(values.app, values.release)}
    await Promise.all(
        releasePrefix.map(async prefix => {
            let filter = `
                (resource.type="k8s_container" AND
                resource.labels.project_id="${config_env[env].project_id}" AND
                resource.labels.location="${config_env[env].location}" AND
                (resource.labels.cluster_name="${config_env[env][values.app].cluster_name}" OR
                resource.labels.cluster_name="booking-${config_env[env][values.app].cluster_name}") AND
                resource.labels.namespace_name="${isDefaultNs ? 'default' : values.app.slice(0, -1)}"`
            const jsonPayload = []
            values.keys.forEach(name => {
                jsonPayload.push(`jsonPayload.${name}="${values[name]}"`)
                if (name === 'tracking_id') {
                    jsonPayload.push(`jsonPayload.action="callback"`)
                    filter += ` AND
                resource.labels.container_name="${config_env[env].container_name}"`
                }
            })

            filter += `) AND
                (${jsonPayload.join(' OR ')}) AND 
                ${
                    values.time === 1
                        ? `(timestamp >= "${values.range[0].format('YYYY-MM-DD')}T00:00:00Z" AND 
                timestamp <= "${values.range[1].format('YYYY-MM-DD')}T23:59:59Z")`
                        : ''
                }`

            const orderBy = 'timestamp'
            const options = {
                filter: filter,
                orderBy: orderBy,
                env
            }

            try {
                const res = await axios({
                    url: `${ENDPOINT}/api-logs`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        ...auth
                    },
                    data: options
                })

                if (res.data && Array.isArray(res.data)) {
                    Array.prototype.push.apply(data, res.data)
                }
            } catch (e) {
                loginErrorHandle(e)
                console.error(e)
            }
        })
    )

    const msgMap = {}
    const msgList = []

    data.forEach(r => {
        try {
            const fields = r.jsonPayload.fields
            const logs = []
            const name = get(fields, 'name.stringValue', get(fields, 'metric.stringValue', ''))
            const src = get(fields, 'src.stringValue', get(fields, 'supplier.stringValue', ''))
            const requestTxt = get(fields, 'request.stringValue', '')
            const responseTxt = get(fields, 'response.stringValue', '')
            const msg = get(fields, 'msg.stringValue', null)
            const msgCallback = get(fields, 'msg.structValue', null)
            if (requestTxt && requestTxt !== '') {
                logs.push({
                    key: r.insertId,
                    timestamp: r.timestamp.seconds,
                    name: `${name}_request`,
                    src,
                    msg: requestTxt
                })
            }
            if (responseTxt && responseTxt !== '') {
                logs.push({
                    key: r.insertId,
                    timestamp: r.timestamp.seconds,
                    name: `${name}_response`,
                    src,
                    msg: responseTxt
                })
            }
            if (msg && msg !== null) {
                logs.push({
                    key: r.insertId,
                    timestamp: r.timestamp.seconds,
                    name,
                    src,
                    msg
                })
            }
            if (msgCallback && msgCallback !== null) {
                logs.push({
                    key: r.insertId,
                    timestamp: r.timestamp.seconds,
                    name,
                    src,
                    msg: msgCallback
                })
            }
            logs.forEach(log => {
                if (!fields.__CID && !fields.__CIDX) {
                    msgList.push(log)
                } else {
                    msgMap[fields.__CID.numberValue] = msgMap[fields.__CID.numberValue] || []
                    msgMap[fields.__CID.numberValue][fields.__CIDX.numberValue] = log
                }
            })
        } catch (error) {
            console.log(error)
        }
    })

    Object.values(msgMap).forEach(msgMergeList => {
        const list = msgMergeList.filter(i => i)
        if (!list.length) {
            return
        }
        const log = list.shift()
        list.forEach(item => {
            log.msg += item.msg
        })
        msgList.push(log)
    })

    const res = msgList.map(item => {
        item.type = 'text'
        item.data = item.msg
        item.ext = 'txt'
        try {
            if (item.msg[0] === '{') {
                item.type = 'javascript'
                item.data = prettyData.pd.json(item.msg)
                item.jsonData = JSON.parse(item.msg)
                item.ext = 'js'
            }
            if (item.msg[0] === '<') {
                item.type = 'xml'
                item.data = prettyData.pd.xml(item.msg)

                item.ext = 'xml'
            }

            if (item.msg.fields) {
                const data = _.clone(item.msg.fields)
                item.type = 'javascript'
                item.data = JSON.stringify(data)
                item.jsonData = data
                item.ext = 'js'
            }
        } catch (e) {
            console.error(e)
        }
        return item
    })

    res.sort((a, b) => a.timestamp - b.timestamp)

    return res
}

export default createAction('GetGoogleCloudLogs', async values => {
    $logs.setState(() => undefined)

    const version = Math.random().toString()
    const endDate = values.time === 1 ? values.range[1] : moment()
    const startDate = values.time === 1 ? values.range[0] : null

    let date = endDate

    $fetching.setState(() => ({
        version,
        date
    }))

    const loop = async (array, iteratee) => {
        const res = await fetchLog({
            ...values,
            time: 1,
            range: [moment(date), moment(date)]
        })
        const fetching = $fetching.getState()
        if (!fetching || fetching.version !== version) {
            return
        }
        $logs.setState(function(s) {
            let concat = (s ? s.slice(0) : []).concat(res).map(s => {
                return {
                    ...s,
                    id: `${s.key}-${s.name}`
                }
            })
            let uniq = _.uniqBy(concat, 'id')
            return uniq
        })
        date = moment(date).subtract(1, 'd')
        if (startDate && startDate.isAfter(date, 'day')) {
            $fetching.setState(() => undefined)
            return
        }
        $fetching.setState(s => ({
            ...s,
            date
        }))
        setTimeout(() => {
            loop().catch(console.error)
        }, 10)
    }

    loop().catch(console.error)
})
