import { useCallback, useEffect, useMemo } from 'react'
import { compose, hoc, useStore } from '@'
import moment from 'moment'
import { useForm, useFormLoading, useFormValues, SetFormValues } from '@form'
import ComparePrice from '../../actions/ComparePrice'
import GetUsers from '../../actions/GetUsers'
import { $users } from '../../stores'

const defaultValue = {
    departure_airport_code: 'AUH',
    arrival_airport_code: 'LHR',
    currency: 'USD',
    is_round_trip: true,
    departure_date: moment().add(30, 'd'),
    return_date: moment().add(37, 'd'),
    adults: 1,
    children: 0,
    infants: 0,
    cabin_class: 'Economy',
    wait_time: 60000
}

const getInitValue = () => {
    let data
    try {
        data = JSON.parse(localStorage.getItem('FlightCompareInputFormValues'))
    } catch (e) {
        console.error(e)
    }
    if (!data) {
        data = defaultValue
        localStorage.setItem(
            'FlightCompareInputFormValues',
            JSON.stringify({
                ...data,
                departure_date: data.departure_date && data.departure_date.toDate(),
                return_date: data.return_date && data.return_date.toDate()
            })
        )
        return data
    }
    return {
        ...data,
        departure_date: data.departure_date ? moment(data.departure_date) : defaultValue.departure_date,
        return_date: data.return_date ? moment(data.return_date) : defaultValue.return_date
    }
}

let initValue = getInitValue()

const $fieldProps = ({ $values }) => ({
    endpoint: { rules: [{ required: true }] },
    userId: { rules: [{ required: true, message: 'Please select an user' }] },
    departure_airport_code: { rules: [{ required: true }] },
    arrival_airport_code: { rules: [{ required: true }] },
    is_round_trip: { valuePropName: 'checked' },
    departure_date: { rules: [{ required: true }] },
    return_date: {
        rules: [
            { required: true },
            {
                validator: (_, value, callback) => {
                    if ($values.departure_date && value && value.isBefore($values.departure_date)) {
                        callback('Return Date must be after Departure Date')
                    } else {
                        callback()
                    }
                }
            }
        ]
    },
    adults: { rules: [{ required: true }] },
    cabin_class: { rules: [{ required: true }] },
    currency: { rules: [{ required: true }] },
    wait_time: { rules: [{ required: true }] }
})

const cabinList = ['Economy', 'Premium Economy', 'Business', 'First']

const container = compose(
    hoc(props => {
        const [users] = useStore([$users])

        useEffect(() => {
            if (users) {
                return
            }
            GetUsers()
        }, [users])

        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                localStorage.setItem(
                    'FlightCompareInputFormValues',
                    JSON.stringify({
                        ...values,
                        departure_date: values.departure_date && values.departure_date.toDate(),
                        return_date: values.return_date && values.return_date.toDate()
                    })
                )
                await ComparePrice(values)
            }

            return {
                name: 'BlockForm',
                onSubmit
            }
        }, [])

        useEffect(() => {
            SetFormValues('BlockForm', initValue)
        }, [])

        const $loading = useFormLoading($form.name)

        const $values = useFormValues($form.name)

        const isRoundTrip = useMemo(() => {
            return !!($values && $values.is_round_trip)
        }, [$values])

        const handleReset = useCallback(() => {
            localStorage.removeItem('FlightCompareInputFormValues')
            SetFormValues('BlockForm', defaultValue)
        }, [])

        return {
            $props,
            $loading,
            isRoundTrip,
            $fieldProps: useMemo(() => $fieldProps({ $values }), [$values]),
            users,
            handleReset,
            cabinList,
            ...props
        }
    })
)

export default container
