import React, { useState, useEffect } from 'react'

import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    FileAddOutlined,
    HistoryOutlined,
    PauseCircleOutlined,
    UploadOutlined
} from '@ant-design/icons'

import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'

import { Button, Card, Input, Modal, Table, Row, Col, Switch, notification, Spin, Checkbox } from 'antd'
import axios from 'axios'
import CSVReader from 'react-csv-reader'
import _ from 'lodash'

import { filterTable } from '@/search'
import { Can, Say, check } from '../../components/can'
import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'
import EditTable from '../../components/EditTable'

const currencyRateUrl = `${appConfig.apiUrl}/currency-rates`
const RESOURCE = 'currency'

function Currency({ form }) {
    const [loading, setLoading] = useState(true)
    const [initialRates, setInitialRates] = useState([])
    const [rates, setRates] = useState([])
    const [rateClicked, setRateClicked] = useState(null)
    const [logs, setLogs] = useState([])
    const [addRateModal, setAddRateModal] = useState(false)
    const [showImportRate, setShowImportRate] = useState(false)
    const [showLog, setShowLog] = useState(false)
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    useEffect(() => {
        getRates()
    }, [])

    const getRates = () =>
        axios({ method: 'get', url: currencyRateUrl, headers })
            .then(({ data }) => {
                setRates(data)
                setInitialRates(data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            })

    const updateRate = row => {
        setLoading(true)
        axios({
            method: 'patch',
            url: `${currencyRateUrl}/${row.pair}`,
            headers,
            data: {
                rate: Number(row.rate)
            }
        })
            .then(() => {
                getRates()
            })
            .catch(error => {
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
                getRates()
            })
    }

    const getLogs = row => {
        axios({ method: 'get', url: `${currencyRateUrl}/${row.pair}/logs`, headers })
            .then(({ data = [] }) => {
                setLogs(data)
                setRateClicked(row)
                toggleShowLogs()
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    const toggleShowLogs = () => {
        setShowLog(!showLog)
    }

    const pairFormatter = cell => {
        const from = cell.substr(0, 3)
        const to = cell.substr(3, 3)
        return `${from} - ${to}`
    }

    const addRate = () => {
        setLoading(true)

        return new Promise(resolve => {
            form.validateFields((err, values) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    resolve()
                    return
                }

                axios({ method: 'post', url: currencyRateUrl, headers, data: values })
                    .then(() => {
                        getRates()
                        toggleAddRate()
                    })
                    .catch(error => {
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                        getRates()
                        toggleAddRate()
                    })
                    .then(resolve)
            })
        })
    }

    const toggleAddRate = () => {
        setAddRateModal(!addRateModal)
    }

    const toggleImportRate = () => {
        setShowImportRate(!showImportRate)
    }

    const logColumns = [
        {
            key: 'old_value',
            dataIndex: 'old_value',
            title: 'Old value',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id
        },
        {
            key: 'new_value',
            dataIndex: 'new_value',
            title: 'New value'
        },
        {
            key: 'updated_at',
            dataIndex: 'updated_at',
            title: 'Updated at'
        },
        {
            key: 'updated_by',
            dataIndex: 'updated_by',
            title: 'Updated by'
        }
    ]

    const onSearch = e => {
        const searchInput = e.target.value
        const rates = filterTable(searchInput, initialRates, columns)
        setRates(rates)
    }

    const columns = [
        {
            key: 'pair',
            dataIndex: 'pair',
            title: 'Pair'
        },
        {
            key: 'rate',
            dataIndex: 'rate',
            title: 'Rate',
            editable: true
        },
        {
            key: 'updated_at',
            dataIndex: 'updated_at',
            title: 'Updated at'
        },
        {
            key: 'updated_by',
            dataIndex: 'updated_by',
            title: 'Updated by'
        },
        {
            key: 'del_flag',
            dataIndex: 'del_flag',
            title: 'Status',
            className: 'text-center',
            render: cell => <Switch disabled defaultChecked={!cell} checked={!cell} />
        }
    ]

    if (check(RESOURCE, 'edit')) {
        const has = columns.find(c => c.key === 'action')
        !has &&
            columns.push({
                key: 'action',
                dataIndex: 'action',
                title: 'Action',
                className: 'text-center',
                render: (cell, row) => (
                    <div>
                        <Can resource={RESOURCE} oneOfPerformeds={['create', 'edit']}>
                            <Say yes>
                                <Button type="success" onClick={() => getLogs(row)}>
                                    <HistoryOutlined />
                                </Button>
                            </Say>
                        </Can>
                    </div>
                )
            })
    }

    const logTitle = `${rateClicked ? pairFormatter(rateClicked.pair || '') : ''} logs:`
    const { getFieldDecorator } = form
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
    }

    return (
        <div>
            <Card>
                <Row>
                    <Col md={16}>
                        <Can resource={RESOURCE} perform={'create'}>
                            <Say yes>
                                <Button type="primary" onClick={toggleAddRate}>
                                    <FileAddOutlined /> Create
                                </Button>
                                <Button className="ml-2" type="success" onClick={toggleImportRate}>
                                    <UploadOutlined /> Import CSV
                                </Button>
                            </Say>
                        </Can>
                    </Col>
                    <Col md={8}>
                        <Input placeholder="Search" onChange={onSearch} />
                    </Col>
                </Row>
                <br />
                <Spin spinning={loading}>
                    <EditTable rowKey="pair" bordered dataSource={rates} columns={columns} handleSave={updateRate} />
                </Spin>
            </Card>

            {/* logs */}
            <Modal
                style={{ minWidth: '840px' }}
                visible={showLog}
                title={logTitle}
                onCancel={toggleShowLogs}
                footer={null}>
                <Table rowKey="id" bordered dataSource={logs} columns={logColumns} />
            </Modal>

            {/* add currency modal */}
            <Modal
                visible={addRateModal}
                title="Create Currency Rate"
                onOk={addRate}
                okText={'Save'}
                onCancel={toggleAddRate}>
                <Form {...formItemLayout}>
                    <Row gutter={16}>
                        <Col md={12}>
                            <Form.Item label="Pair" hasFeedback>
                                {getFieldDecorator('pair', {
                                    rules: [
                                        {
                                            type: 'string'
                                        },
                                        { required: true, message: 'Please enter pair' }
                                    ]
                                })(<Input maxLength={6} />)}
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item label="Rate" hasFeedback>
                                {getFieldDecorator('rate', {
                                    rules: [{ required: true, message: 'Please enter rate' }]
                                })(<Input type="number" />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <ImportCSV currentRates={rates} isShow={showImportRate} toggle={toggleImportRate} onSuccess={getRates} />
        </div>
    )
}

function ImportCSV({ currentRates, onSuccess, isShow, toggle }) {
    const [csvHeaders, setCsvHeaders] = useState({})
    const [includeHeader, setIncludeHeader] = useState(true)
    const [csvData, setCsvData] = useState([])
    const [rowCorrect, setRowCorrect] = useState(0)
    const [rowDuplicated, setRowDuplicated] = useState(0)
    const [rowIncorrect, setRowIncorrect] = useState(0)
    const [rowUnchanged, setRowUnchanged] = useState(0)

    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const clearState = () => {
        setIncludeHeader(true)
        setCsvData([])
        setRowCorrect(0)
        setRowDuplicated(0)
        setRowIncorrect(0)
        setRowUnchanged(0)
    }

    const toggleIncludeHeaders = () => {
        const includeHeader = !includeHeader
        const data = [...data]

        if (!includeHeader) {
            data.unshift(csvHeaders)
        }

        setIncludeHeader(includeHeader)

        setTimeout(() => {
            checkData(data)
        }, 0)
    }

    const onFileLoaded = data => {
        const rates = data.map((row, idx) => ({
            line: idx + 1,
            from: (row[0] || '').toUpperCase(),
            to: (row[1] || '').toUpperCase(),
            rate: Number(row[2]) || -1
        }))

        checkData(rates)
    }

    const checkData = data => {
        const pairs = currentRates.map(rate => rate.pair)
        const newCurrentRates = _.keyBy(currentRates, 'pair')
        const correct = []
        let duplicated = 0
        let incorrect = 0
        let unchanged = 0
        let headers = includeHeader ? data.shift() : {}

        data.forEach(row => {
            const pair = `${row.from}${row.to}`
            let checked = pairs.includes(pair) ? 'update' : 'insert'

            if (correct.includes(pair)) {
                duplicated += 1
                checked = 'duplicated'
            } else if (row.from.length !== 3 || row.to.length !== 3 || row.rate < 0) {
                incorrect += 1
                checked = 'incorrect'
            } else if (checked === 'update' && newCurrentRates[pair].rate === row.rate) {
                ++unchanged
                checked = 'unchanged'
            } else {
                correct.push(row.pair)
            }

            row.checked = checked
        })

        setCsvHeaders(headers)
        setCsvData(data)
        setRowCorrect(correct.length)
        setRowIncorrect(incorrect)
        setRowDuplicated(duplicated)
        setRowUnchanged(unchanged)
    }

    const excuteImport = () => {
        const insertedRates = csvData
            .filter(({ checked }) => 'insert' === checked)
            .map(({ from, to, rate }) => ({
                pair: `${from}${to}`,
                rate
            }))
        const updatedRates = csvData
            .filter(({ checked }) => 'update' === checked)
            .map(({ from, to, rate }) => ({
                pair: `${from}${to}`,
                rate
            }))

        const exe = []
        if (insertedRates.length > 0) {
            exe.push(
                axios({
                    method: 'post',
                    url: currencyRateUrl,
                    headers,
                    data: insertedRates
                })
            )
        }

        if (updatedRates && updatedRates.length > 0) {
            exe.push(
                axios({
                    method: 'patch',
                    url: currencyRateUrl,
                    headers,
                    data: updatedRates
                })
            )
        }

        Promise.all(exe)
            .then(() => {
                onSuccess()
                toggle()
                clearState()
            })
            .catch(error => {
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
                onSuccess()
                toggle()
                clearState()
            })
    }

    const csvTableColumn = [
        {
            key: 'line',
            dataIndex: 'line',
            title: 'Line No.'
        },
        {
            key: 'from',
            dataIndex: 'from',
            title: 'From'
        },
        {
            key: 'to',
            dataIndex: 'to',
            title: 'To'
        },
        {
            key: 'rate',
            dataIndex: 'rate',
            title: 'Rate'
        },
        {
            key: 'checked',
            dataIndex: 'checked',
            title: 'Status'
        }
    ]

    return (
        <Modal
            visible={isShow}
            title="Import Currency Rates"
            onOk={excuteImport}
            onCancel={() => {
                clearState()
                toggle()
            }}
            style={{ maxWidth: '70%' }}>
            <div className="mb-2 row">
                <div className="col-md-9">
                    <CSVReader cssClass="react-csv-input" label="" onFileLoaded={onFileLoaded} />
                </div>
            </div>
            <Row>
                <Col sm={12}>
                    <Checkbox checked={includeHeader} onChange={toggleIncludeHeaders}>
                        First line contains field names
                    </Checkbox>
                </Col>
            </Row>
            <br />
            <Row>
                <Col sm={6}>
                    <CheckCircleOutlined /> Correct: {rowCorrect}
                </Col>
                <Col sm={6}>
                    <PauseCircleOutlined /> Unchanged: {rowUnchanged}
                </Col>
                <Col sm={6}>
                    <ExclamationCircleOutlined /> Duplicated: {rowDuplicated}
                </Col>
                <Col sm={6}>
                    <CloseCircleOutlined /> Incorrect: {rowIncorrect}
                </Col>
            </Row>
            <br />
            <Table rowKey="line" bordered dataSource={csvData} columns={csvTableColumn} />
        </Modal>
    )
}

export default Form.create({ name: 'currency' })(Currency)
