import { createAction } from '@'
import Fetch from './Fetch'
import { $users } from '../stores'

const ENDPOINT = process.env.REACT_APP_CARD_ENDPOINT

export default createAction('GetUsers', async () => {
    $users.setState(() => undefined)

    const { users } = await Fetch({
        url: `${ENDPOINT}/users`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })

    $users.setState(() => users)
})
