import { createAction } from '@'
import { notification } from 'antd'
import axios from 'axios'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('Login', async params => {
    const res = await axios({
        url: `${ENDPOINT}/login`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: params
    })

    const { data } = res

    if (data.success === false) {
        notification.error({
            message: 'Failed to login to Gateboard',
            description: data.message
        })
        return
    }

    localStorage.setItem(
        'headers',
        JSON.stringify({
            'x-key': data.data.email,
            'x-access-token': data.data.auth_token
        })
    )

    localStorage.setItem(
        'user',
        JSON.stringify({ name: data.data.name, avatar: data.data.avatar, external: data.data.external })
    )

    localStorage.setItem('permissions', JSON.stringify(data.data.permissions || []))

    window.location.href = '/'
})
