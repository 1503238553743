import axios from 'axios'
import { createAction } from '@'
import { getAuth } from '@misc'
import { $hotelsDuplicate } from '../stores'
import { $logsRemoveDuplicate } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('RemoveHotelsDuplicate', async params => {
    const auth = getAuth()

    $hotelsDuplicate.setState(() => [])

    const hotelsDuplicateLst = await axios({
        url: `${ENDPOINT}/hotel/mappings/delete-duplicate`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...auth },
        data: {
            data: params
        }
    })

    const output = hotelsDuplicateLst.data.map(x => {
        return {
            retained: x.hotelIdRetained,
            removed: x.hotelIdRemoved,
            noOfHotelsModified: x.noOfHotelsModified,
            oldMapping: x.oldMappings,
            newMappings: x.newMappings,
            result: x.resultAddNewMappings,
            message: `Removed hotel ID ${x.hotelIdRemoved} and keep hotel ID ${x.hotelIdRetained}. `
        }
    })

    $logsRemoveDuplicate.setState(() => output)

    return output
})
