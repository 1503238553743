import { useState, useCallback, useEffect, useMemo } from 'react'
import { compose, hoc, useStore } from '@'
import { SetFormValues } from '@form'
import _ from 'lodash'
import GetLocation from '../../actions/GetLocation'
import PullHotels from '../../actions/PullHotels'
import GetHotelInfo from '../../actions/GetHotelInfo'
import GetGoQuoId from '../../actions/GetGoQuoId'
import { $location, $hotels, $hotelMap, $step, $selectedHotel, $searchInfo } from '../../stores'

const container = compose(
    hoc(props => {
        const [location, hotels, hotelMap] = useStore([$location, $hotels, $hotelMap])
        const [goQuoId, setGoQuoId] = useState()

        const suggestions = useMemo(() => {
            return location ? location.filter(i => i.type === 'HOTEL') : []
        }, [location])

        const handleFilterByHotel = useCallback(value => {
            if (!value) {
                setGoQuoId(undefined)
                return
            }
            const [, hotelId] = value.split('_')
            GetGoQuoId(hotelId)
                .then(res => {
                    setGoQuoId((res && res[0]) || 'empty')
                })
                .catch(console.error)
        }, [])

        const hotelList = useMemo(() => {
            return (hotels || []).filter(i => (!goQuoId ? true : i.goquo_id === goQuoId))
        }, [goQuoId, hotels])

        const handleSuggestion = useCallback(value => {
            GetLocation(value, { onlyHotel: true }).catch(console.error)
        }, [])

        const getHotelInfo = useCallback(item => {
            GetHotelInfo(item)
        }, [])

        const handleSelectHotel = useCallback(hotel => {
            SetFormValues('HotelDetailsPage', {})
            $selectedHotel.setState(() => hotel)
            $step.setState(() => 1)
        }, [])

        useEffect(() => {
            $location.setState(() => undefined)
        }, [])

        useEffect(() => {
            let ended = false
            const loop = () => {
                if (ended) {
                    return
                }
                PullHotels()
                    .then(res => {
                        if (ended) {
                            return
                        }
                        const { requestId, availableHotels } = res
                        $searchInfo.setState(s => ({ ...s, requestId: requestId }))
                        if (availableHotels.length) {
                            $hotels.setState(s => {
                                const map = _.keyBy(s, i => `${i.source_id}:${i.hotel_id}`)
                                return [...s, ...availableHotels.filter(i => !map[`${i.source_id}:${i.hotel_id}`])]
                            })
                        }
                    })
                    .catch(console.error)
                    .finally(() => {
                        setTimeout(loop, 30000)
                    })
            }
            setTimeout(loop, 30000)
            return () => {
                ended = true
            }
        }, [])
        return {
            suggestions,
            hotelList,
            hotelMap,
            handleFilterByHotel,
            handleSuggestion,
            getHotelInfo,
            handleSelectHotel,
            ...props
        }
    })
)

export default container
