import { useEffect } from 'react'
import { compose, hoc, useStore } from '@'
import { $alertSettings } from '../../stores'
import GetAlerts from '../../actions/GetAlerts'

const container = compose(
    hoc(props => {
        const [alertSettings] = useStore([$alertSettings])
        useEffect(() => {
            GetAlerts()
        }, [])
        useEffect(() => {
            if (alertSettings && alertSettings.length) {
                // SetTableFilter('CardTable', {
                //     user: users[0],
                //     offset: 0,
                //     limit: 10
                // })
            }
        }, [alertSettings])
        return {
            alertSettings,
            ...props
        }
    })
    // withTable('AlertSettingList', () => $alertSettings)
)

export default container
