import { createAction } from '@'
import { SetFormValues } from '@form'
import { SetModalVisible } from '@modal'

export default createAction('OpenEditRole', async (item, onSuccess = () => {}) => {
    const permissions = {}
    ;(item.permissions || []).forEach(permission => {
        permissions[permission.resource_code] = permissions[permission.resource_code] || {}
        permissions[permission.resource_code][permission.permission_code] = true
    })

    SetFormValues('RoleForm', {
        onSuccess,
        isEdit: true,
        id: item.id,
        description: item.description,
        permissions
    })
    SetModalVisible('RoleForm', true)
})
