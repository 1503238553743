import { useEffect } from 'react'
import { compose, hoc } from '@'
import { SetFormValues, useForm, useFormLoading } from '@form'
import Login from '../../actions/Login'

const $fieldProps = {
    email: {
        rules: [{ required: true }]
    },
    password: {
        rules: [{ required: true }]
    }
}

const container = compose(
    hoc(props => {
        useEffect(() => {
            SetFormValues('LoginForm', {})
        }, [])

        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                await Login({ email: values.email, password: values.password })
            }

            return {
                name: 'LoginForm',
                onSubmit
            }
        }, [])

        const $loading = useFormLoading($form.name)

        return {
            $props,
            $loading,
            $fieldProps,
            ...props
        }
    })
)

export default container
