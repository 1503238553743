import { createAction } from '@'
import Fetch from './Fetch'
import { message } from 'antd'
import _ from 'lodash'
import GenToken from './GenToken'
import GetGoQuoId from './GetGoQuoId'
import { $users, $searchRequest, $searchInfo, $hotels, $roomMap, $step, $selectedHotel } from '../stores'

const HOTEL_NOT_AVAILABLE = 'Hotel not available. Please try again.'
const ENDPOINT = process.env.REACT_APP_OFFER_ENDPOINT

export default createAction('SearchHotels', async params => {
    $searchRequest.setState(() => undefined)
    $searchInfo.setState(() => undefined)
    $hotels.setState(() => undefined)
    $roomMap.setState(() => undefined)
    $step.setState(() => undefined)

    const users = $users.getState()

    const user = users.find(i => i.id === params.userId)

    const {
        data: { auth_token: token }
    } = await GenToken({ id: user.id, email: user.email })

    const req = {
        checkin: params.for[0].format('YYYY-MM-DD'),
        checkout: params.for[1].format('YYYY-MM-DD'),
        rooms: params.rooms.map((r, idx) => ({ idx: idx + 1, ...r })),
        package: params.package || false,
        language_code: 'en-US',
        ip: '127.0.0.1',
        wait_time: 15000,
        user_agent:
            (window.navigator && window.navigator.userAgent) ||
            'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/63.0.3239.132 Safari/537.36',
        session_id: 'alsdkfjsdowenb',
        nationality: params.nationality,
        country_residence: params.nationality
    }

    const [type, where, multicity] = params.where.split('_')
    let url
    let rid
    switch (type) {
        case 'CITY':
            req.region_id = where
            rid = where
            url = `${ENDPOINT}/search`
            break
        case 'AIRPORT':
            req.destination_code = where
            rid = multicity
            url = `${ENDPOINT}/search`
            break
        case 'HOTEL':
            req.ids = await GetGoQuoId(where)
            url = `${ENDPOINT}/search-by-id`
            break
        default:
            break
    }

    const { request_id, data: hotels } = await Fetch({
        url,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-key': user.email,
            'x-access-token': token
        },
        data: req
    })

    $searchRequest.setState(() => req)

    $searchInfo.setState(() => ({
        rid,
        url,
        requestId: request_id,
        token,
        email: user.email
    }))

    if (req.ids) {
        if (hotels.length) {
            const selectedHotel = hotels[0]
            $step.setState(() => 1)
            $roomMap.setState(s => ({ ...s, ..._.groupBy(hotels, 'goquo_id') }))
            $selectedHotel.setState(() => selectedHotel)
        } else {
            message.warn(HOTEL_NOT_AVAILABLE)
        }
    } else {
        const availableHotels = _(hotels)
            .filter(h => h.goquo_id)
            .groupBy('goquo_id')
            .map(gr => _.minBy(gr, h => _.sumBy(h.rooms, 'price_from')))
            .value()

        if (availableHotels.length) {
            $hotels.setState(() => availableHotels)
            $step.setState(() => 0)
        } else {
            message.warn(HOTEL_NOT_AVAILABLE)
        }
    }
})
