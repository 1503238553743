import { createAction } from '@'
import axios from 'axios'
import { fetchErrorHandler, getAuth, loginErrorHandle } from '@misc'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetCurrencyRates', async (currency = '') => {
    const auth = getAuth()

    try {
        const loginRes = await axios({
            url: `${ENDPOINT}/currency-rates${currency ? `/${currency}` : ''}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...auth }
        })

        const { quotes } = fetchErrorHandler(loginRes)
        return quotes
    } catch (e) {
        loginErrorHandle(e)
    }
})
