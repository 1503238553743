import { useMemo } from 'react'
import { useStore } from '@'
import { $formLoading } from '../stores'

export default function useFormLoading(name) {
    const [formLoading] = useStore([$formLoading])

    return useMemo(() => {
        return !!(formLoading && formLoading[name])
    }, [name, formLoading])
}
