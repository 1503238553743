import { compose, hoc, useStore } from '@'
import { useForm, useFormLoading, useFormValues } from '@form'
import { useEffect } from 'react'
import { $accounts, $projects } from '../../stores'
import GetProjects from '../../actions/GetProjects'
import GetAccounts from '../../actions/GetAccounts'
import UpdateFormValues from '../../../@form/actions/UpdateFormValues'
import CreateAlert from '../../actions/CreateAlert'
import GetAlerts from '../../actions/GetAlerts'

const $fieldProps = {
    account: { rules: [{ required: true }] },
    project: { rules: [{ required: true }] }
}
let selectedProject = undefined
const container = compose(
    hoc(props => {
        const [projects] = useStore([$projects])
        const [accounts] = useStore([$accounts])
        const [$props, $form] = useForm(() => {
            const onSubmit = async values => {
                console.log(values)
                await CreateAlert(values)
                await GetAlerts()
                // SetTableFilter('AlertSettingList', {}) // refresh table
                UpdateFormValues('AlertSettingSetup', {}) // reset form
            }
            return {
                name: 'AlertSettingSetup',
                onSubmit
            }
        }, [])
        const $formValues = useFormValues($form.name)
        selectedProject = $formValues && $formValues.project
        useEffect(() => {
            GetProjects()
        }, [])
        useEffect(() => {
            if ($formValues !== undefined) {
                GetAccounts(selectedProject)
                UpdateFormValues($form.name, { ...$formValues, account: undefined })
            }
        }, [selectedProject])

        const $loading = useFormLoading($form.name)

        return {
            projects,
            accounts,
            $props,
            $loading,
            $formValues,
            $fieldProps,
            ...props
        }
    })
)

export default container
