import styled from 'styled-components'

export const Root = styled.div`
    .ant-tag {
        margin-bottom: 8px;
    }

    .zibra1 {
        > *:hover {
            background: rgba(0, 0, 0, 0.1);
        }
    }

    .ant-form-explain {
        position: absolute;
        top: 10px;
        right: 10px;
    }
`
