import React, { useMemo } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Button, Card, Row, Col, Table } from 'antd'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import ReactJson from 'react-json-view'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/mode/xml/xml'
import 'codemirror/mode/javascript/javascript'
import { Root } from './styled'
import container from './container'

const LogViewer = props => {
    let { logs, downloadLink } = props
    logs =
        logs &&
        logs.map(log => {
            return { ...log, key: `${log.key}-${log.name}` }
        })
    const columns = useMemo(() => {
        return [
            {
                title: 'Time',
                key: 'time',
                render(log) {
                    return new Date(log.timestamp * 1000).toISOString()
                }
            },
            {
                title: 'Supplier',
                dataIndex: 'src',
                key: 'src'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            }
        ]
    }, [])

    return (
        <Root>
            <Card
                size={'small'}
                title={'Result'}
                extra={
                    <Row type={'flex'} gutter={16} align={'middle'}>
                        <Col>
                            {downloadLink ? (
                                <Button
                                    type={'primary'}
                                    icon={<DownloadOutlined />}
                                    href={downloadLink}
                                    target={'blank'}
                                    download={'log.zip'}>
                                    Download (.zip)
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                }>
                <Table
                    size={'small'}
                    bordered
                    columns={columns}
                    dataSource={logs || []}
                    expandedRowRender={log => {
                        return log.ext === 'js' ? (
                            <ReactJson
                                src={log.jsonData}
                                theme="monokai"
                                iconStyle="square"
                                name={false}
                                displayDataTypes={false}
                                collapsed={3}
                                collapseStringsAfterLength={30}
                            />
                        ) : (
                            <CodeMirror
                                value={log.data}
                                options={{
                                    mode: log.type,
                                    theme: 'material',
                                    lineNumbers: true
                                }}
                            />
                        )
                    }}
                    rowKey={'key'}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000']
                    }}
                />
            </Card>
        </Root>
    )
}

export default container(LogViewer)
