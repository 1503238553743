import React from 'react'
import axios from 'axios'
import { format, subMonths, getDaysInMonth, lastDayOfMonth } from 'date-fns'
import { Card, Badge, notification } from 'antd'
import { Line } from 'react-chartjs-2'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'

const headers = JSON.parse(localStorage.getItem('headers') || '{}')

const lineChartOpts = {
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    tooltips: {
        mode: 'index',
        intersect: false
    },
    hover: {
        mode: 'nearest',
        intersect: true
    },
    scales: {
        xAxes: [
            {
                gridLines: {
                    display: false
                },
                ticks: {
                    fontFamily: 'Product Sans'
                }
            }
        ],
        yAxes: [
            {
                gridLines: {
                    display: false
                },
                ticks: {
                    display: false,
                    beginAtZero: true
                }
            }
        ]
    }
}

class BookingChart extends React.Component {
    constructor(props) {
        super(props)
        const now = new Date()

        this.state = {
            headers: null,
            lineData: { datasets: [], labels: [] },
            thisMonth: {
                number: format(now, 'MM'),
                short: format(now, 'MMM'),
                long: format(now, 'MM/yyyy'),
                year: format(now, 'yyyy'),
                date: format(now, 'dd')
            },
            lastMonth: {
                number: format(subMonths(now, 1), 'MM'),
                short: format(subMonths(now, 1), 'MMM'),
                long: format(subMonths(now, 1), 'MM/yyyy')
            }
        }
    }

    componentDidMount() {
        this.getData()
    }

    UNSAFE_componentWillReceiveProps({ product }) {
        if (product !== this.props.product) {
            setTimeout(() => this.getData(), 0)
        }
    }

    getData() {
        axios({
            method: 'get',
            url: `${appConfig.apiUrl}/${this.props.product}/bookings/compare/month`,
            headers
        })
            .then(({ data }) => {
                const lastMonth = data[Number(this.state.lastMonth.number)]
                const thisMonth = data[Number(this.state.thisMonth.number)]

                this.setState({
                    lineData: {
                        labels: Array(getDaysInMonth(new Date()))
                            .fill()
                            .map((v, i) => i + 1),
                        datasets: [
                            {
                                label: this.state.thisMonth.short,
                                borderWidth: 1,
                                backgroundColor: 'rgba(0,123,255,.1)',
                                borderColor: 'rgb(0,123,255)',
                                pointBorderColor: 'rgb(0,123,255)',
                                pointBackgroundColor: 'rgb(0,123,255)',
                                data: this.fillChartData(thisMonth)
                            },
                            {
                                label: this.state.lastMonth.short,
                                borderWidth: 1,
                                backgroundColor: 'rgba(173,181,189,.1)',
                                borderColor: 'rgb(173,181,189)',
                                pointBorderColor: 'rgb(173,181,189)',
                                pointBackgroundColor: 'rgb(173,181,189)',
                                data: this.fillChartData(lastMonth)
                            }
                        ]
                    }
                })
            })
            .catch(error => {
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
                this.setState({
                    lineData: { datasets: [], labels: [] }
                })
            })
    }

    fillChartData = (data = {}) => {
        const result = []

        if (Object.keys(data).length === 0) {
            return result
        }

        const month = Object.keys(data)[0].substr(0, 7)

        const { year, number: thisMonth, date: thisDate } = this.state.thisMonth
        const endDate = lastDayOfMonth(new Date([Number(year), Number(thisMonth), 1])).getDate()

        for (let i = 1; i <= endDate; i += 1) {
            if (month === `${year}-${thisMonth}` && i > Number(thisDate)) {
                break
            }

            let date = `0${i}`
            date = date.substr(date.length - 2)
            result.push(data[`${month}-${date}`] || 0)
        }

        return result
    }

    render() {
        return (
            <Card
                title="Booking Chart"
                size="small"
                extra={
                    <>
                        <Badge status="processing" text={this.state.thisMonth.long} />{' '}
                        <Badge status="default" text={this.state.lastMonth.long} />
                    </>
                }>
                <div style={{ width: '100%', height: 250, margin: '0 auto' }}>
                    <Line data={this.state.lineData} options={lineChartOpts} />
                </div>
            </Card>
        )
    }
}

export default BookingChart
