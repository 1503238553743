import React from 'react'
import container from './container'
import AlertSettingSetup from '../AlertSettingSetup'
import AlertSettingList from '../AlertSettingList'

const AlertSettingPage = () => {
    return (
        <div>
            <AlertSettingSetup />
            <br />
            <AlertSettingList />
        </div>
    )
}

export default container(AlertSettingPage)
