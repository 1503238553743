import React from 'react'
import container from './container'
import { SearchOutlined } from '@ant-design/icons'
import { Form, Spin, Card, Input, Button, Select, Row, Col } from 'antd'

const types = [
    {
        key: 'destination_code',
        label: 'Destination'
    },
    {
        key: 'region_id',
        label: 'Region'
    }
]

const RegionMappingForm = props => {
    const { $props, $loading, $fieldProps } = props
    return (
        <Spin spinning={$loading}>
            <Form colon={false} {...$props}>
                <Card
                    title="Region Mapping"
                    size="small"
                    extra={
                        <Button htmlType={'submit'} type="primary">
                            <SearchOutlined /> Find
                        </Button>
                    }>
                    <div className="d-md-flex align-items-center">
                        <Card.Meta description="Checking region/destination mapping from GoQuo to suppliers." />
                    </div>
                    <br />
                    <Row gutter={16}>
                        <Col xs={24} md={4}>
                            <Form.Item name={'type'} wrapperCol={{ flex: 1 }} {...$fieldProps.type}>
                                <Select showSearch style={{ width: '100%' }} placeholder={'Please select Type'}>
                                    {types.map(type => (
                                        <Select.Option value={type.key} key={type.key}>
                                            {type.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={20}>
                            <Form.Item name={'value'} wrapperCol={{ flex: 1 }} {...$fieldProps.value}>
                                <Input placeholder="Enter single destination_code (BKK, CADI) or region_id (604)" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </Spin>
    )
}

export default container(RegionMappingForm)
