import { createAction } from '@'
import axios from 'axios'
import { getAuth } from '@misc'
const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('CreateUser', async params => {
    try {
        const auth = getAuth()

        await axios({
            url: `${ENDPOINT}/signup`,
            method: 'POST',
            data: {
                email: params.email,
                password: params.password,
                name: params.name,
                role_id: params.role
            },
            headers: { 'Content-Type': 'application/json', ...auth }
        })
    } catch (e) {
        console.error(e)
    }
    if (params.onSuccess) {
        await params.onSuccess()
    }
})
