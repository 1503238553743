import React, { useState, useEffect, useRef } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Card, Input, Switch, notification } from 'antd'
import axios from 'axios'
import _ from 'lodash'

import appConfig from '../../config'
import apiErrorHandler from '../../api-error-handler'

import FormDialog from '../../components/FormDialog'
import UserList from '../../components/UserList'
import ManageUser from '../../components/management/flight-user'

const PRODUCT = 'flight'

const columns = [
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name'
    },
    {
        key: 'email',
        dataIndex: 'email',
        title: 'Email'
    }
]

function FlightUser({ form, location, history }) {
    const apiUrl = `${appConfig.apiUrl}/${PRODUCT}`

    const [users, setUsers] = useState([])
    const [currentUser, setCurrentUser] = useState({})
    const userForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const getUsers = () => {
        return axios({
            method: 'get',
            url: `${apiUrl}/users`,
            headers
        })
            .then(({ data }) => {
                setUsers(data)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    useEffect(() => {
        getUsers()
    }, [])

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, vals) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }
                const values = _.cloneDeep(vals)
                if (
                    vals['use_custom_config'] &&
                    vals['holder_phone'] &&
                    vals['holder_phone'].length > 0 &&
                    vals['holder_email'] &&
                    vals['holder_email'].length > 0
                ) {
                    const customConfig = {
                        use_custom_config: vals['use_custom_config'],
                        holder: {
                            mobile: vals['holder_phone'],
                            email: vals['holder_email']
                        }
                    }
                    values['custom_config'] = JSON.stringify(customConfig)
                }
                delete values.holder_email
                delete values.holder_phone
                delete values.use_custom_config

                const options = {
                    url: `${apiUrl}/users`,
                    headers,
                    data: values
                }

                switch (userForm.current.formState) {
                    case 'CREATE':
                        options.method = 'post'
                        break
                    case 'UPDATE': {
                        const changedFields = {}

                        Object.keys(values).forEach(prop => {
                            if (
                                prop !== 'password' &&
                                values[prop] !== undefined &&
                                values[prop] !== currentUser[prop]
                            ) {
                                changedFields[prop] = values[prop]
                            }

                            if (prop === 'password' && values.password !== currentUser.password_hash) {
                                changedFields.password = values.password
                            }
                        })

                        if (!Object.keys(changedFields).length) {
                            notification.warn({
                                message: 'Warning!!!',
                                description: "User infos don't change!!!"
                            })
                            return
                        }

                        options.method = 'patch'
                        options.url += `/${currentUser.id}`
                        options.data = changedFields

                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return Promise.resolve('Unknow action')
                }

                if (Object.prototype.hasOwnProperty.call(options.data, 'del_flag')) {
                    options.data.del_flag = Number(!options.data.del_flag)
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description:
                                (userForm.current.formState === 'CREATE' ? 'Created' : 'Updated') +
                                ' user: ' +
                                values.email
                        })
                        getUsers()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
    }

    const { getFieldDecorator } = form

    const setFormState = (state, values = {}) => {
        const formFields = [
            'name',
            'email',
            'password',
            'del_flag',
            'use_custom_config',
            'holder_email',
            'holder_phone'
        ]
        const { custom_config } = values
        if (custom_config) {
            const { holder, use_custom_config } = custom_config
            const fields = _.pick(values, formFields)
            if (typeof use_custom_config === 'boolean') {
                fields.use_custom_config = use_custom_config
            }
            if (holder) {
                fields.holder_email = holder.email || ''
                fields.holder_phone = holder.mobile || ''
            }
            form.setFieldsValue(fields)
        }

        if (['CREATE', 'HIDE'].includes(state)) {
            setCurrentUser({
                del_flag: false
            })
            form.resetFields()
        } else {
            values.password = values.password_hash
            const fields = _.pick(values, formFields)
            fields.del_flag = !fields.del_flag

            form.setFieldsValue(fields)
        }

        userForm.current.setFormState(state)
        setCurrentUser(values)
    }

    return (
        <div>
            <Card>
                <UserList
                    users={users}
                    product={PRODUCT}
                    columns={columns}
                    location={location}
                    history={history}
                    manageUser={ManageUser}
                    setFormState={setFormState}
                />
                <FormDialog
                    style={{ minWidth: '540px' }}
                    closeHandler={() => setFormState('HIDE')}
                    saveHandler={onSave}
                    ref={userForm}>
                    <Form {...formItemLayout}>
                        <Form.Item label="Name" hasFeedback>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please enter your name!'
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Email" hasFeedback>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The Email is not valid!'
                                    },
                                    {
                                        required: true,
                                        message: 'Please enter your email/username!'
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Password" hasFeedback>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please enter your password!' }]
                            })(<Input.Password />)}
                        </Form.Item>
                        <Form.Item label="Active">
                            {getFieldDecorator('del_flag', {
                                initialValue: !currentUser.del_flag,
                                valuePropName: 'checked'
                            })(<Switch />)}
                        </Form.Item>
                        <Form.Item label="Use Custom Config">
                            {getFieldDecorator('use_custom_config', {
                                initialValue: currentUser.use_custom_config,
                                valuePropName: 'checkedConfig'
                            })(<Switch />)}
                        </Form.Item>
                        <Form.Item label="Holder Email" hasFeedback disable={!currentUser.use_custom_config}>
                            {getFieldDecorator('holder_email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'The Email is not valid!'
                                    },
                                    {
                                        required: currentUser.use_custom_config,
                                        message: 'Please enter the Holder Email!'
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                        <Form.Item label="Holder Phone" hasFeedback>
                            {getFieldDecorator('holder_phone', {
                                rules: [
                                    {
                                        required: currentUser.use_custom_config,
                                        message: 'Please enter the Holder Phone!'
                                    }
                                ]
                            })(<Input />)}
                        </Form.Item>
                    </Form>
                </FormDialog>
            </Card>
        </div>
    )
}

export default Form.create({ name: 'flight-user' })(FlightUser)
