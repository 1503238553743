import React from 'react'
import CardForm from '../CardForm'
import CardTable from '../CardTable'
import container from './container'

const CardPage = () => {
    return (
        <div>
            <CardForm />
            <br />
            <CardTable />
        </div>
    )
}

export default container(CardPage)
