import React, { useState, useEffect, useRef } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Card, Input, Col, Row, notification } from 'antd'
import axios from 'axios'
import _ from 'lodash'

import FormDialog from '../../components/FormDialog'
import SupplierList from '../../components/SupplierList'
import apiErrorHandler from '../../api-error-handler'
import appConfig from '../../config'

const PRODUCT = 'tour'

const columns = [
    {
        key: 'code',
        dataIndex: 'code',
        title: 'Code',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.code.localeCompare(b.code)
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name'
    }
]

function TourSupplier({ form }) {
    const apiUrl = `${appConfig.apiUrl}/${PRODUCT}`
    const [suppliers, setSuppliers] = useState([])
    const [currentSupplier, setCurrentSupplier] = useState({})
    const [formState, _setFormState] = useState('HIDE')
    const supplierForm = useRef()
    const headers = JSON.parse(localStorage.getItem('headers') || '{}')

    const getSuppliers = () => {
        return axios({
            method: 'get',
            url: `${apiUrl}/suppliers`,
            headers
        })
            .then(({ data }) => {
                setSuppliers(data)
            })
            .catch(error =>
                notification.error({
                    message: 'Error!!!',
                    description: apiErrorHandler(error)
                })
            )
    }

    useEffect(() => {
        getSuppliers()
    }, [])

    const onSave = () => {
        return new Promise(resolve => {
            form.validateFields((err, values) => {
                if (err) {
                    notification.error({
                        message: 'Error!!!',
                        description: err
                    })
                    return
                }

                let options = {
                    url: `${apiUrl}/suppliers`,
                    headers,
                    data: values
                }

                switch (supplierForm.current.formState) {
                    case 'CREATE':
                        options.method = 'post'
                        options.data = values
                        break
                    case 'UPDATE': {
                        const changedFields = {}

                        Object.keys(values).forEach(prop => {
                            if (values[prop] !== undefined && values[prop] !== currentSupplier[prop]) {
                                changedFields[prop] = values[prop]
                            }
                        })

                        if (!Object.keys(changedFields).length) {
                            notification.warn({
                                message: 'Warning!!!',
                                description: "Supplier infos don't change!!!"
                            })
                            return
                        }

                        options.method = 'put'
                        options.url += `/${currentSupplier.id}`
                        options.data = changedFields

                        break
                    }
                    default:
                        notification.warning({
                            message: 'Warning!!!',
                            description: 'Unknow action'
                        })
                        return
                }

                return axios(options)
                    .then(() => {
                        setFormState('HIDE')
                        notification.success({
                            message: 'Successful!!!',
                            description: (formState === 'CREATE' ? 'Created' : 'Updated') + ' supplier: ' + values.code
                        })
                        getSuppliers()
                    })
                    .catch(error =>
                        notification.error({
                            message: 'Error!!!',
                            description: apiErrorHandler(error)
                        })
                    )
                    .then(() => {
                        resolve()
                    })
            })
        })
    }

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
    }

    const { getFieldDecorator } = form

    const setFormState = (state, values = {}) => {
        const formFields = [
            'name',
            'code',
            'username',
            'password',
            'email',
            'client_id',
            'api_key',
            'shared_secret',
            'url'
        ]

        if (['CREATE', 'HIDE'].includes(state)) {
            form.resetFields()
        } else {
            const fields = _.pick(values, formFields)
            form.setFieldsValue(fields)
        }

        supplierForm.current.setFormState(state)
        _setFormState(state)
        setCurrentSupplier(values)
    }

    return (
        <div>
            <Card>
                <SupplierList
                    suppliers={suppliers}
                    product={PRODUCT}
                    columns={columns}
                    getSuppliers={getSuppliers}
                    setFormState={setFormState}
                />
                <FormDialog closeHandler={() => setFormState('HIDE')} saveHandler={onSave} ref={supplierForm}>
                    <Form {...formItemLayout}>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Name" hasFeedback>
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Name is required!'
                                            },
                                            { required: true, message: 'Please enter Name!' }
                                        ]
                                    })(<Input/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Code" hasFeedback>
                                    {getFieldDecorator('code', {
                                        rules: [
                                            {
                                                type: 'string',
                                                message: 'Code is required!'
                                            },
                                            { required: true, message: 'Please enter supplier code!' }
                                        ]
                                    })(<Input disabled={formState === 'UPDATE'} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Username" hasFeedback>
                                    {getFieldDecorator('username', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            { required: true, message: 'Password is required' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Password" hasFeedback>
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            { required: true, message: 'Password is required' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Email" hasFeedback>
                                    {getFieldDecorator('email', {
                                        rules: [
                                            {
                                                type: 'email'
                                            },
                                            { required: true, message: 'Email is required' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="API Key" hasFeedback>
                                    {getFieldDecorator('api_key', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            { required: false, message: 'api key is required!' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item label="Shared Secret" hasFeedback>
                                    {getFieldDecorator('shared_secret', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            { required: false, message: 'Shared Secret is required!' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12}>
                                <Form.Item label="Url" hasFeedback>
                                    {getFieldDecorator('url', {
                                        rules: [
                                            {
                                                type: 'string'
                                            },
                                            { required: true, message: 'URL is required!' }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </FormDialog>
            </Card>
        </div>
    )
}

export default Form.create({ name: 'tour-supplier' })(TourSupplier)