const permissions = JSON.parse(localStorage.getItem('permissions'))

const check = (resource, permission) => {
    if (permission === 'all') {
        return true
    }

    return (
        Array.isArray(permissions) &&
        !!permissions.find(p => p.resource_code === resource && p.permission_code === permission)
    )
}

const Can = ({ resource, perform, oneOfPerformeds, children }) => {
    const options = Array.isArray(children) ? children : [children]

    if (perform) {
        const result = check(resource, perform)
            ? options.find(child => child && !!child.props.yes)
            : options.find(child => child && !child.props.yes)
        return result || null
    } else if (oneOfPerformeds && Array.isArray(oneOfPerformeds)) {
        for (let i = 0; i < oneOfPerformeds.length; i += 1) {
            const perform = oneOfPerformeds[i]

            if (check(resource, perform)) {
                return options.find(child => !!child.props.yes) || null
            }
        }

        return options.find(child => !child.props.yes) || null
    }
    console.log(oneOfPerformeds)

    return null
}

// eslint-disable-next-line no-unused-vars
const Say = ({ yes, children }) => children || null

export { Can, Say, check }
