import React, { useMemo } from 'react'
import { Input, Checkbox, Table, Form, Spin } from 'antd'
import container from './container'

const RoleForm = props => {
    const { resources, permissions, $form, $props, $loading, $fieldProps } = props

    const columns = useMemo(() => {
        return [
            {
                title: 'Resource',
                key: 'resource_code',
                render: item => {
                    return item.resource_name
                }
            },
            ...(permissions || []).map(permission => ({
                title: permission.name,
                key: permission.code,
                width: 100,
                align: 'center',
                render: item => {
                    if (item[permission.code] === undefined) {
                        return null
                    }
                    return (
                        <Form.Item
                            name={['permissions', item.resource_code, permission.code]}
                            noStyle
                            {...$fieldProps.permission}>
                            <Checkbox />
                        </Form.Item>
                    )
                }
            }))
        ]
    }, [permissions, $fieldProps])

    return (
        <Spin spinning={$loading}>
            <Form {...$props}>
                {$form.Item({ name: 'isEdit' })}
                {$form.Item({ name: 'onSuccess' })}
                <Form.Item name={'id'} label={'Id'} {...$fieldProps.id}>
                    <Input placeholder={'Role id'} />
                </Form.Item>
                <Form.Item name={'description'} label={'Description'} {...$fieldProps.description}>
                    <Input placeholder={'Role description'} />
                </Form.Item>
                {resources && resources.length ? (
                    <Table
                        size={'small'}
                        columns={columns}
                        rowKey={'resource_code'}
                        dataSource={resources}
                        pagination={false}
                        scroll={{ y: 280 }}
                        bordered
                        defaultExpandAllRows
                    />
                ) : null}
            </Form>
        </Spin>
    )
}

export default container(RoleForm)
