import { useEffect } from 'react'
import { compose, hoc } from '@'
import GetHotelInfo from '../../actions/GetHotelInfo'

const container = compose(
    hoc(props => {
        const { mappings } = props
        useEffect(() => {
            GetHotelInfo(mappings).catch(console.error)
        }, [mappings])
        return {
            ...props
        }
    })
)

export default container
