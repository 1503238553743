import { createAction } from '@'
import Fetch from './Fetch'
import { $book, $bookRequest, $searchInfo } from '../stores'

const ENDPOINT = process.env.REACT_APP_BOOK_ENDPOINT

export default createAction('Book', async values => {
    $book.setState(() => undefined)
    $bookRequest.setState(() => undefined)
    const searchInfo = $searchInfo.getState()
    if (!searchInfo) {
        return
    }

    const res = await Fetch(
        {
            url: `${ENDPOINT}/book`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-key': searchInfo.email,
                'x-access-token': searchInfo.token
            },
            data: values
        },
        { skipError: true }
    )

    $bookRequest.setState(() => values)
    $book.setState(() => res)
})
