import axios from 'axios'
import { createAction } from '@'
import { getAuth } from '@misc'
import { $amenitiesUnmap, $amenitiesType, $amenitiesProvider } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetUnmapAmenities', async params => {
    $amenitiesType.setState(() => undefined)
    $amenitiesProvider.setState(() => undefined)
    $amenitiesUnmap.setState(() => undefined)

    const auth = getAuth()

    const getAmenitiesData = await axios({
        url: `${ENDPOINT}/hotel/mappings/amenities/unmap`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...auth },
        data: {
            type: params.type,
            provider: params.provider
        }
    })

    $amenitiesType.setState(() => params.type)
    $amenitiesProvider.setState(() => params.provider)

    let amenitiesArr = Object.keys(getAmenitiesData.data).map(key => {
        return {
            code: String(key),
            name: getAmenitiesData.data[key]
        }
    })

    $amenitiesUnmap.setState(() => amenitiesArr)
})
