import { createAction } from '@'
import axios from 'axios'
import _ from 'lodash'
import { $hotelMappings, $hotelMappingFrom } from '../stores'
import { getAuth, loginErrorHandle } from '@misc'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetHotelMapping', async params => {
    $hotelMappings.setState(() => undefined)
    $hotelMappingFrom.setState(() => undefined)
    try {
        const auth = getAuth()
        const res = await axios({
            url: `${ENDPOINT}/hotel/mappings/find`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...auth },
            data: {
                from: params.from,
                ids: params.ids
            }
        })

        const mappings = _(res.data)
            .groupBy('hotel_id')
            .map((grp, hotel_id) => {
                const goquoIds = grp.find(h => h.supplier_code === 'GoQuo')
                return {
                    hotel_id,
                    goquo_ids: (goquoIds && goquoIds.supplier_hotel_ids) || [],
                    mappings: grp.reduce((out, cur) => {
                        cur.supplier_hotel_ids.forEach(hotelId => {
                            out.push({
                                supplier_code: cur.supplier_code,
                                hotel_id: hotelId
                            })
                        })
                        return out
                    }, [])
                }
            })
            .value()

        $hotelMappings.setState(() => mappings)
        $hotelMappingFrom.setState(() => params.from)
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }
})
