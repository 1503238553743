import { createAction } from '@'
import { message } from 'antd'
import axios from 'axios'
import { fetchErrorHandler } from '@misc'
import GenToken from './GenToken'
import GetCurrencyRates from './GetCurrencyRates'
import { $users, $tableData } from '../stores'

const ENDPOINT = process.env.REACT_APP_FLIGHT_ENDPOINT

export default createAction('ComparePrice', async params => {
    $tableData.setState(() => [])

    const users = $users.getState()

    const user = users.find(i => i.id === params.userId)

    const {
        data: { auth_token: token }
    } = await GenToken({ id: user.id, email: user.email })

    const searchReq = {
        departure_airport_code: params.departure_airport_code,
        arrival_airport_code: params.arrival_airport_code,
        currency: params.currency,
        is_round_trip: params.is_round_trip,
        departure_date: params.departure_date.format('YYYY-MM-DD'),
        return_date: params.is_round_trip ? params.return_date.format('YYYY-MM-DD') : undefined,
        adults: params.adults,
        children: params.children ? params.children : undefined,
        infants: params.infants ? params.infants : undefined,
        cabin_class: params.cabin_class,
        wait_time: params.wait_time
    }

    const searchRes = await axios({
        url: `${ENDPOINT}/get/flights`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-key': user.email,
            'x-access-token': token
        },
        data: searchReq
    })

    const { data: flights } = fetchErrorHandler(searchRes)

    if (!flights || !flights.length) {
        message.warn('No flights available. Please try again!')
        return
    }

    const currencyRates = await GetCurrencyRates(params.currency)

    const genKey = segments => {
        const keys = []
        segments.forEach(segment => {
            let flightNo = (segment.flight_no || '').toUpperCase()
            let bookingClass = (segment.booking_class || '').toUpperCase()
            if (!flightNo || !bookingClass) {
                return
            }
            keys.push(`${flightNo}-${bookingClass}`)
        })
        keys.sort()
        return keys.join(', ')
    }

    const map = {}

    flights.forEach(flight => {
        const inKey = genKey(flight.inbound || [])
        const outKey = genKey(flight.outbound || [])
        const key = [inKey, outKey].join('$')
        map[key] = map[key] || []
        map[key].push(flight)
    })

    // Object.entries(map).forEach(([key, item]) => {
    //     if (item.length < 2) {
    //         delete map[key]
    //     }
    // })

    const result = []

    Object.entries(map).forEach(([key, flights]) => {
        result.push({
            key: key,
            name: flights.map(f => f.supplier_code).join('-'),
            children: flights.map(f => {
                const child = {
                    key: f.flight_id,
                    name: f.supplier_code,
                    cabin_class: f.fares[0].cabin_class,
                    booking_class: f.fares[0].booking_class,
                    currency: f.fares[0].currency,
                    base_fare: f.fares[0].base_fare,
                    tax_fare:
                        (
                            (f.fares[0].fare_breakdowns &&
                                f.fares[0].fare_breakdowns[0] &&
                                f.fares[0].fare_breakdowns[0].taxes) ||
                            []
                        ).reduce((totalTax, tax) => totalTax + tax.amount, 0) ||
                        f.fares[0].total_fare - f.fares[0].base_fare,
                    total_fare: f.fares[0].total_fare,
                    inbound: genKey(f.inbound || []),
                    outbound: genKey(f.outbound || [])
                }

                if (
                    child.currency &&
                    child.currency !== params.currency &&
                    currencyRates[`${child.currency}${params.currency}`]
                ) {
                    const currencyRate = currencyRates[`${child.currency}${params.currency}`]
                    child.currency = params.currency
                    child.base_fare = currencyRate * child.base_fare
                    child.tax_fare = currencyRate * child.tax_fare
                    child.total_fare = currencyRate * child.total_fare
                }

                child.base_fare = (child.base_fare || 0).toFixed(2)
                child.tax_fare = (child.tax_fare || 0).toFixed(2)
                child.total_fare = (child.total_fare || 0).toFixed(2)

                return child
            })
        })
    })

    $tableData.setState(() => result)
})
