import React from 'react'
import { Modal } from 'antd'

export default function withModal(callback = () => ({})) {
    return Com => {
        return props => {
            const { $props, ...restProps } = callback(props)

            return (
                <Modal {...$props}>
                    <Com {...restProps} />
                </Modal>
            )
        }
    }
}
