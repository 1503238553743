import { useEffect } from 'react'
import { compose, hoc, useStore } from '@'
import { withTable, SetTableFilter } from '@table'
import GetCards from '../../actions/GetCards'
import { $users } from '../../stores'

const container = compose(
    hoc(props => {
        const [users] = useStore([$users])

        useEffect(() => {
            if (users && users.length) {
                SetTableFilter('CardTable', {
                    user: users[0],
                    offset: 0,
                    limit: 10
                })
            }
        }, [users])
        return {
            ...props
        }
    }),
    withTable('CardTable', () => ({
        fetch: GetCards
    }))
)

export default container
