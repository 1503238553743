import React from 'react'
import { Form, Input, Select, Card, Button, Descriptions, Divider, Row, Col, Spin } from 'antd'
import { Root } from './styled'
import countries from '../../countries'
import container from './container'

const BookingPage = props => {
    const { $props, $form, $fieldProps, $values, $loading, prebook } = props

    const paxMap = {
        adult: {
            name: 'Adult',
            ageList: [...Array(100).keys()].map(i => i + 13)
        },
        child: {
            name: 'Child',
            ageList: [...Array(11).keys()].map(i => i + 2)
        },
        infant: {
            name: 'Infant',
            ageList: [...Array(2).keys()]
        }
    }

    return (
        <Root>
            <Spin spinning={$loading}>
                <Form colon={false} layout={'inline'} {...$props}>
                    {$form.Item({ name: 'request_id', ...$fieldProps.request_id })}
                    <Card
                        size={'small'}
                        title={
                            <Form.Item name={'client_ref'} label={'Client Ref'} {...$fieldProps.client_ref}>
                                <Input />
                            </Form.Item>
                        }
                        extra={
                            <Button type={'primary'} htmlType={'submit'}>
                                Book
                            </Button>
                        }>
                        <Row type={'flex'} gutter={16}>
                            <Col style={{ flex: 1 }}>
                                {(($values && $values.rooms) || []).map((room, idx) => {
                                    const roomPath = ['rooms', idx, 'paxes']
                                    return (
                                        <Card
                                            headStyle={{ background: '#ccc' }}
                                            bodyStyle={{ background: '#eee' }}
                                            size={'small'}
                                            key={idx}
                                            style={{ marginBottom: 20 }}
                                            title={<strong>{`Room ${idx + 1}`}</strong>}>
                                            {$form.Item({ name: ['rooms', idx, 'idx'] })}
                                            <Row type={'flex'} gutter={8} justify={'space-around'}>
                                                {(room.paxes || []).map((pax, paxIdx) => (
                                                    <Card
                                                        key={paxIdx}
                                                        className={'holder'}
                                                        size={'small'}
                                                        style={{ margin: 10, width: 320 }}
                                                        title={
                                                            <strong>
                                                                {paxMap[pax.type].name} {paxIdx + 1}
                                                            </strong>
                                                        }>
                                                        <div>
                                                            <Form.Item
                                                                name={[...roomPath, paxIdx, 'title']}
                                                                label={'Title'}
                                                                {...$fieldProps.title}>
                                                                <Select style={{ width: 170 }}>
                                                                    <Select.Option value={'Mr'}>Mr</Select.Option>
                                                                    <Select.Option value={'Ms'}>Ms</Select.Option>
                                                                    <Select.Option value={'Mrs'}>Mrs</Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[...roomPath, paxIdx, 'first_name']}
                                                                label={'First name'}
                                                                {...$fieldProps.first_name}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[...roomPath, paxIdx, 'last_name']}
                                                                label={'Last name'}
                                                                {...$fieldProps.last_name}>
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[...roomPath, paxIdx, 'age']}
                                                                label={'Age'}
                                                                {...$fieldProps.age}>
                                                                <Select showSearch style={{ width: 170 }}>
                                                                    {paxMap[pax.type].ageList.map(age => (
                                                                        <Select.Option key={age} value={age}>
                                                                            {age}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                            {$form.Item({
                                                                name: [...roomPath, paxIdx, 'type'],
                                                                ...$fieldProps.type
                                                            })}
                                                            <Form.Item
                                                                name={[...roomPath, paxIdx, 'nationality']}
                                                                label={'Nationality'}
                                                                {...$fieldProps.nationality}>
                                                                <Select
                                                                    style={{ width: 170 }}
                                                                    showSearch
                                                                    showArrow={false}
                                                                    filterOption={(input, option) =>
                                                                        option.props.children
                                                                            .toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }>
                                                                    {countries.map(item => (
                                                                        <Select.Option
                                                                            key={item.code}
                                                                            value={item.code}>
                                                                            {`${item.name} (${item.code})`}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </Card>
                                                ))}
                                                <div style={{ width: 320 }} />
                                                <div style={{ width: 320 }} />
                                                <div style={{ width: 320 }} />
                                                <div style={{ width: 320 }} />
                                                <div style={{ width: 320 }} />
                                                <div style={{ width: 320 }} />
                                                <div style={{ width: 320 }} />
                                            </Row>
                                        </Card>
                                    )
                                })}
                                <Card
                                    headStyle={{ background: '#ccc' }}
                                    bodyStyle={{ background: '#eee' }}
                                    size={'small'}
                                    className={'holder'}
                                    title={<strong>Holder</strong>}
                                    style={{ width: 320 }}>
                                    <Form.Item name={['holder', 'title']} label={'Title'} {...$fieldProps.title}>
                                        <Select style={{ width: 170 }}>
                                            <Select.Option value={'Mr'}>Mr</Select.Option>
                                            <Select.Option value={'Ms'}>Ms</Select.Option>
                                            <Select.Option value={'Mrs'}>Mrs</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name={['holder', 'first_name']}
                                        label={'First name'}
                                        {...$fieldProps.first_name}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={['holder', 'last_name']}
                                        label={'Last name'}
                                        {...$fieldProps.last_name}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name={['holder', 'email']} label={'Email'} {...$fieldProps.email}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name={['holder', 'mobile']} label={'Mobile'} {...$fieldProps.mobile}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name={['holder', 'city']} label={'City'} {...$fieldProps.city}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name={['holder', 'address']} label={'Address'} {...$fieldProps.address}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={['holder', 'postal_code']}
                                        label={'Postal Code'}
                                        {...$fieldProps.postal_code}>
                                        <Input />
                                    </Form.Item>
                                </Card>
                            </Col>
                            <Col style={{ width: 350 }}>
                                <Card
                                    headStyle={{ background: '#ccc' }}
                                    bodyStyle={{ background: '#eee' }}
                                    size={'small'}
                                    title={<strong>Price & Cancellation</strong>}>
                                    {prebook &&
                                        prebook.data.rooms.map((room, idx) => (
                                            <Card
                                                key={idx}
                                                title={<strong>Room {room.idx}</strong>}
                                                size={'small'}
                                                style={{ marginBottom: 16 }}>
                                                <Descriptions column={1}>
                                                    <Descriptions.Item label={'Name'}>
                                                        <strong>{room.room_name}</strong>
                                                    </Descriptions.Item>

                                                    <Descriptions.Item label={'Board basic'}>
                                                        <strong>{room.board_basis}</strong>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item label={'Price'}>
                                                        <strong>{room.price}</strong> {room.currency}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider>Cancellation</Divider>
                                                {!(room.cancellations || []).length ? (
                                                    <h2>No cancellation policies</h2>
                                                ) : null}
                                                {room.cancellation_text ? (
                                                    room.cancellation_text
                                                ) : (
                                                    <div>
                                                        {(room.cancellations || []).map((cancel, cidx) => (
                                                            <Descriptions key={cidx} column={3} layout="vertical">
                                                                <Descriptions.Item label={'From'}>
                                                                    <strong>{cancel.from}</strong>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item label={'To'}>
                                                                    <strong>{cancel.to}</strong>
                                                                </Descriptions.Item>
                                                                <Descriptions.Item label={'Amount'}>
                                                                    <strong>{cancel.amount}</strong>
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        ))}
                                                    </div>
                                                )}
                                            </Card>
                                        ))}
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </Spin>
        </Root>
    )
}

export default container(BookingPage)
