import { createAction } from '@'
import { getAuth } from '@misc'
import GenToken from './GenToken'
import Fetch from './Fetch'
import { $users } from '../stores'

const ENDPOINT = process.env.REACT_APP_CARD_ENDPOINT

export default createAction('CreateCard', async values => {
    const users = $users.getState()

    const authInfo = users.find(user => `${user.id}` === `${values.user}`)

    const auth = getAuth()

    const {
        data: { auth_token: token }
    } = await GenToken(authInfo)

    const { data } = await Fetch({
        url: `${ENDPOINT}/create-card`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-key': authInfo.email,
            'x-access-token': token
        },
        data: { reference: values.reference, amount: parseInt(values.amount, 10), creator: auth['x-key'] }
    })

    return data
})
