import { createAction } from '@'
import { getAuth, loginErrorHandle } from '@misc'
import axios from 'axios'

const APIURL = process.env.REACT_APP_API_URL

export default createAction('GetGoQuoId', async id => {
    const auth = getAuth()
    try {
        const { data } = await axios({
            method: 'POST',
            url: `${APIURL}/hotel/mappings/find`,
            headers: { 'Content-Type': 'application/json', ...auth },
            data: { from: 'EPS', ids: [id] }
        })

        const mapping = data.find(m => m.supplier_code === 'GoQuo')
        if (!mapping) return []

        return mapping.supplier_hotel_ids
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }
})
