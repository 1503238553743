import React, { useEffect } from 'react'
import Icon from '@ant-design/icons'
import { Card, Spin, Button, List, Skeleton, Rate, Select } from 'antd'
import container from './container'
import { IconAirport, IconCity, IconHotel } from '../../../@icons'

const GetRoomHandle = props => {
    const { getHotelInfo, hotel } = props
    useEffect(() => {
        getHotelInfo(hotel)
    }, [getHotelInfo, hotel])
    return null
}

const AvailabilityPage = props => {
    const {
        suggestions,
        hotelList,
        hotelMap,
        handleFilterByHotel,
        handleSuggestion,
        getHotelInfo,
        handleSelectHotel
    } = props

    return (
        <div>
            <br />
            <div>
                <Select
                    size={'large'}
                    showArrow={false}
                    // notFoundContent={'Type any places'}
                    allowClear
                    showSearch
                    onChange={handleFilterByHotel}
                    onSearch={handleSuggestion}
                    placeholder={'Search by hotel name'}
                    style={{ width: 500 }}
                    filterOption={() => true}>
                    {suggestions.map(item => (
                        <Select.Option key={item.id} value={`${item.type}_${item.id}`}>
                            <div>
                                <div style={{ display: 'inline-block', marginRight: 5 }}>
                                    {item.type === 'HOTEL' ? <Icon component={IconHotel} /> : null}
                                    {item.type === 'AIRPORT' ? <Icon component={IconAirport} /> : null}
                                    {item.type === 'CITY' ? <Icon component={IconCity} /> : null}
                                </div>
                                <div
                                    style={{ display: 'inline-block' }}
                                    dangerouslySetInnerHTML={{ __html: item.name }}
                                />
                            </div>
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <br />
            <List
                grid={{ gutter: 16, md: 3 }}
                dataSource={hotelList}
                pagination={{
                    defaultPageSize: 6,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ['6', '9', '12', '15', '18']
                }}
                renderItem={item => {
                    const hotel = hotelMap && hotelMap[item.goquo_id]
                    const cover = hotel && hotel.images && hotel.images[0]

                    return (
                        <List.Item>
                            <GetRoomHandle getHotelInfo={getHotelInfo} hotel={item} />
                            <Card
                                style={{ height: 437 }}
                                actions={[
                                    <Button key={'book'} type={'link'}>
                                        {item.rooms
                                            .reduce((a, b) => {
                                                return a + b.price_from
                                            }, 0)
                                            .toFixed(2)}{' '}
                                        {item.currency}
                                    </Button>,
                                    <Button
                                        key={'book'}
                                        type={'primary'}
                                        onClick={() => {
                                            handleSelectHotel(item)
                                        }}>
                                        View Rooms
                                    </Button>
                                ]}
                                size={'small'}
                                cover={
                                    <Spin spinning={!cover}>
                                        <div style={{ width: '100%', height: 200 }}>
                                            {cover ? (
                                                <img
                                                    style={{ objectFit: 'cover' }}
                                                    width={'100%'}
                                                    height={'100%'}
                                                    src={cover.url}
                                                    alt="cover"
                                                />
                                            ) : null}
                                        </div>
                                    </Spin>
                                }
                                bodyStyle={{ height: 178 }}>
                                <Card.Meta
                                    title={
                                        <div style={{ height: 54, overflow: 'hidden' }}>
                                            <Rate value={(hotel && hotel.star_rating) || 0} disabled />
                                            <div>{hotel && hotel.name}</div>
                                        </div>
                                    }
                                    description={
                                        <Skeleton loading={!hotel} paragraph={{ rows: 3 }} title={false} active>
                                            <div
                                                style={{ height: 100, overflow: 'hidden' }}
                                                dangerouslySetInnerHTML={{ __html: hotel && hotel.description }}
                                            />
                                        </Skeleton>
                                    }
                                />
                            </Card>
                        </List.Item>
                    )
                }}
            />
        </div>
    )
}

export default container(AvailabilityPage)
