import React from 'react'
import { Card, Select } from 'antd'

import TotalBooking from '../../components/charts/total-booking'

class Chart extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectOpt: 'user'
        }
    }

    onChange = selectOpt => {
        this.setState({ selectOpt })
    }

    render() {
        const { selectOpt } = this.state
        return (
            <Card
                title="Booking Chart"
                size="small"
                extra={
                    <Select defaultValue={this.state.selectOpt} onChange={this.onChange} style={{ width: 100 }}>
                        <Select.Option value="user">User</Select.Option>
                        <Select.Option value="supplier">Supplier</Select.Option>
                    </Select>
                }>
                <Card.Meta description="Overview of Last Month" />
                <br />
                <TotalBooking by={selectOpt} />
            </Card>
        )
    }
}

export default Chart
