import { createAction } from '@'
import axios from 'axios'
import { $location, $searchInfo } from '../stores'

export default createAction('GetLocation', async (value, { onlyHotel = false } = {}) => {
    $location.setState(() => undefined)
    const searchInfo = $searchInfo.getState()
    if (!value) {
        return
    }
    const res = await axios({
        // url: `https://www.expedia.com/api/v4/typeahead/${value}?client=Homepage&siteid=1&guid=2f9dd8f87edd49fe8d825b2a21db8bcc&lob=HOTELS&locale=en_US&expuserid=-1&regiontype=2047&ab=&dest=true&maxresults=8&features=contextual_ta%7Cpostal_code%7Cuta_client%7Cgoogle%7Cmultiregion_parent%7Cta_hierarchy&format=jsonp&device=Desktop&browser=Chrome&personalize=true`,
        url: `https://www.expedia.com/api/v4/typeahead/${value}?client=Hotels.Search&dest=true&expuserid=-1&format=json&lob=HOTELS&locale=en_US&maxresults=20&personalize=false&regiontype=${
            onlyHotel ? `128&rid=${searchInfo.rid}` : '2047'
        }`,
        method: 'GET'
    })

    const data = eval(res.data)

    $location.setState(() =>
        (data.sr || [])
            .map(item => {
                let id
                switch (item.type) {
                    case 'HOTEL':
                        id = item.hotelId
                        break
                    case 'AIRPORT':
                        id = [item.hierarchyInfo.airport.airportCode, item.hierarchyInfo.airport.multicity].join('_')
                        break
                    // case 'MULTICITY': // Offer supporting type city only. Open this once Offer support that
                    case 'CITY':
                        id = item.gaiaId
                        break
                    default:
                        id = ''
                        break
                }
                return {
                    id,
                    score: item.score,
                    type: item.type,
                    name: item.regionNames.displayName
                }
            })
            .filter(item => item.id)
    )
})
