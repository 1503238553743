import React, { useMemo } from 'react'
import { Card, Table, Pagination, Row, Col } from 'antd'
import moment from 'moment'
import { Root } from './styled'
import container from './container'

const CardTable = props => {
    const {
        $table: { table, pagination }
    } = props

    const columns = useMemo(() => {
        return [
            {
                title: 'Reference',
                dataIndex: 'reference',
                key: 'reference'
            },
            {
                title: 'Card Type',
                dataIndex: 'card_type',
                key: 'card_type'
            },
            {
                title: 'Card Number',
                dataIndex: 'card_number',
                key: 'card_number'
            },
            {
                title: 'Cvv',
                dataIndex: 'cvv',
                key: 'cvv'
            },
            {
                title: 'Expire Date',
                dataIndex: 'expire_date',
                key: 'expire_date'
            },
            {
                title: 'Card Holder Name',
                dataIndex: 'card_holder_name',
                key: 'card_holder_name'
            },
            {
                title: 'Amount',
                // dataIndex: 'amount',
                key: 'amount',
                render(item) {
                    return (
                        <Row type={'flex'} justify={'space-between'} gutter={8} style={{ flexWrap: 'nowrap' }}>
                            <Col>{item.currency_code}</Col>
                            <Col>{item.amount}</Col>
                        </Row>
                    )
                }
            },
            {
                title: 'Create Date',
                // dataIndex: 'create_date',
                key: 'create_date',
                render(item) {
                    return <div>{moment(item.create_date).format('MM/DD/YYYY HH:mm:ss')}</div>
                }
            },
            {
                title: 'Creator',
                dataIndex: 'creator',
                key: 'creator'
            }
        ]
    }, [])

    return (
        <Root>
            <Card size={'small'} title={'Card List'}>
                <Table {...table} bordered size="small" rowKey="id" columns={columns} pagination={false} />
                <br />
                <Row type="flex" justify="end">
                    <Col>
                        <Pagination
                            {...pagination}
                            pageSizeOptions={['5', '10', '25', '50', '100']}
                            showSizeChanger
                            showQuickJumper
                        />
                    </Col>
                </Row>
            </Card>
        </Root>
    )
}

export default container(CardTable)
