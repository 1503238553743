import React, { useMemo } from 'react'
import { EditOutlined, SafetyCertificateOutlined } from '@ant-design/icons'
import { Table, Card, Button, Row, Col } from 'antd'
import container from './container'

const RoleTable = props => {
    const { powerMap, roles, handleOpenCreateRole, handleOpenEditRole } = props

    const columns = useMemo(() => {
        return [
            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id'
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description'
            },
            powerMap && powerMap['edit']
                ? {
                      title: 'Action',
                      key: 'action',
                      render: item => {
                          return (
                              <Button
                                  type={'danger'}
                                  ghost
                                  icon={<EditOutlined />}
                                  onClick={() => {
                                      handleOpenEditRole(item)
                                  }}
                              />
                          )
                      }
                  }
                : null
        ].filter(i => i)
    }, [roles])

    return (
        <Card size={'small'}>
            <Row>
                <Col>
                    {powerMap && powerMap['create'] ? (
                        <Button type="primary" icon={<SafetyCertificateOutlined />} onClick={handleOpenCreateRole}>
                            Create
                        </Button>
                    ) : null}
                </Col>
            </Row>
            <br />
            {powerMap && powerMap['view'] ? (
                <Table
                    loading={!roles}
                    size={'small'}
                    bordered
                    columns={columns}
                    dataSource={roles || []}
                    rowKey={'id'}
                    pagination={{
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000']
                    }}
                />
            ) : null}
        </Card>
    )
}

export default container(RoleTable)
