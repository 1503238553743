import { createAction } from '@'
import GenToken from './GenToken'
import Fetch from './Fetch'
const ENDPOINT = process.env.REACT_APP_CARD_ENDPOINT

export default createAction('GetCards', async filter => {
    const authInfo = filter.user
    if (!authInfo) {
        return
    }
    const {
        data: { auth_token: token }
    } = await GenToken(authInfo)

    const { data } = await Fetch({
        url: `${ENDPOINT}/get-cards`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-key': authInfo.email,
            'x-access-token': token
        },
        params: { offset: filter.offset, limit: filter.limit }
    })

    return data
})
