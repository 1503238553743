import { createAction } from '@'
import axios from 'axios'
import { getAuth, loginErrorHandle, getCurrentUser } from '@misc'
import { $users } from '../stores'

const ENDPOINT = process.env.REACT_APP_API_URL

export default createAction('GetUsers', async () => {
    $users.setState(() => undefined)
    const auth = getAuth()
    const currentUser = getCurrentUser()

    try {
        const res = await axios({
            url: `${ENDPOINT}/flight/users`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...auth }
        })
        $users.setState(() =>
            currentUser.external ? res.data.filter(u => u.email === 'tripbundle@goquo.com') : res.data
        )
    } catch (e) {
        loginErrorHandle(e)
        console.error(e)
    }
})
